import React from 'react';
import {
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Form,
    FormInstance,
    InputNumber,
    message,
    Popover,
    Row,
    Select,
    Space,
    Tooltip
} from 'antd';
import App from '../../../App';
import styles from './ServiceUserV2.module.css';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import {CheckboxType, DefaultRefreshFile, DetailClass, EyeDiseasesConfig} from '../util/ServiceUserDetailTypes';
import UserInfo from "../detail/UserInfo";
import ScreeningInfo from "../detail/ScreeningInfo";
import AccountInfo from "../detail/AccountInfo";
import FormItem from 'antd/lib/form/FormItem';
import FileUploader from "../../common/uploader";
import TextArea from 'antd/lib/input/TextArea';
import {buildAierData, buildIllness, defaultData, refreshFile} from "../util/AierCommon";
import {QuestionCircleOutlined, UploadOutlined} from '@ant-design/icons';
import IllnessInfo from "../detail/IllnessInfo";
import {decryptData} from "../../common/crypto";
import Decimal from 'decimal.js';
import WarVeteranInfo from "../detail/WarVeteranInfo";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {RangePickerProps} from "antd/es/date-picker";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import dayjs from "dayjs";
import {CATARACT, EyeDiseasesUtil, FUNDUS, GILLIUM} from "../util/EyeDiseasesUtil";
import AierServiceUserIllnessLog from "../detail/AierServiceUserIllnessLog";
import MediaCodes from "../../common/uploader/MediaCodes";
import ServiceUserDetailParentClass from "../detail/ServiceUserDetailParent";

type ScreeningAudit = {
    eyeDiseasesConfig: EyeDiseasesConfig | undefined
}


class ServiceUserHospitalScreeningAuditClass extends ServiceUserDetailParentClass<DetailClass & ScreeningAudit> {

    formRef = React.createRef<FormInstance>();
    refd = React.createRef<FileUploader>();
    state: DetailClass & ScreeningAudit = {
        id: decryptData(common.getQuery('id')),
        snap: defaultData.snap,
        data: defaultData.data,
        hospitalSelect: [],
        eyeDiseasesConfig: undefined,
        startTime: dayjs().valueOf(),
        refreshData: DefaultRefreshFile(),
        refreshCount: 0,
        spinning: true,
    };

    componentDidMount() {
        this.getData()
    };

    getData() {
        const {id} = this.state;
        Fetch.get(`${API.cooperateServiceUsers.get_patient_fee_details}?id=${id}`).then((data) => {
            buildAierData(data);
            const refresh = refreshFile(data.files);
            let eyeDiseasesConfig = EyeDiseasesUtil(data.quota, this.formRef);
            if (data.fee) {
                const illnessForm = buildIllness(data);
                if (illnessForm){
                    if (illnessForm.cataractEyeType) {
                        eyeDiseasesConfig.cataractEyeType = false;
                    }
                    if (illnessForm.fundusEyeType) {
                        eyeDiseasesConfig.fundusEyeType = false;
                    }
                    if (illnessForm.gilliumEyeType) {
                        eyeDiseasesConfig.gilliumEyeType = false;
                    }
                }
                this.setState({
                    data: data,
                    eyeDiseasesConfig: eyeDiseasesConfig,
                    refreshData: refresh
                });
                this.formRef.current?.setFieldsValue({
                    ...data.fee,
                    ...illnessForm,
                    timeOfAdmission: dayjs(data.fee.timeOfAdmission),
                    timeOfDischarge: data.fee.timeOfDischarge ? dayjs(data.fee.timeOfDischarge) : undefined,
                })
            } else {
                this.setState({
                    data: data,
                    eyeDiseasesConfig: eyeDiseasesConfig,
                    refreshData: refresh
                });
                this.formRef.current?.setFieldsValue({
                    eyeType: data.secondEyeType
                })
            }
        });
    }


    cancel = () => {
        window.history.go(-1);
    }
    submit = (values: any) => {
        const {data} = this.state;
        this.buildFile(values);
        this.buildTime(values);
        this.cleanField(values);
        values.serviceId = this.state.id;
        if (data.fee != null && data.fee.id > 0) {
            values.id = data.fee.id;
            this.update(values);
        } else {
            this.create(values);
        }
    }

    update = (values: any) => {

        Fetch.postJSON(API.aierHospitalFee.do_update_fee, values).then(() => {
            message.success('提交成功');
            window.location.href = '/#/hospitalAuditListV2/auditList';
        });
    };

    create = (values: any) => {

        Fetch.postJSON(API.aierHospitalFee.do_save_fee, values).then(() => {
            message.success('提交成功');
            window.location.href = '/#/hospitalAuditListV2/auditList';
        });
    };

    cleanField = (values: any) => {
        delete values.diagnosticReports;
        delete values.consentForms;
        delete values.hospitalizationRecords;
        delete values.invoices;
        delete values.identityPromise;
        delete values.proofProve;
        delete values.preferentialTreatmentCard;
    }
    buildTime = (values: any) => {
        values.timeOfAdmission = values.timeOfAdmission.format('YYYY-MM-DD');
        values.timeOfDischarge = values.timeOfDischarge ? values.timeOfDischarge.format('YYYY-MM-DD') : null;
    }

    buildFile = (values: any) => {

        const files: any[] = [];
        this.addFiles(files, values.diagnosticReports, 1);
        this.addFiles(files, values.consentForms, 2);
        this.addFiles(files, values.hospitalizationRecords, 3);
        if (values.invoices) {
            this.addFiles(files, values.invoices, 4);
        }
        if (values.identityPromise) {
            this.addFiles(files, values.identityPromise, 12);
        }
        if (values.proofProve){
            this.addFiles(files, values.proofProve, 13);
        }
        if (values.preferentialTreatmentCard){
            this.addFiles(files, values.preferentialTreatmentCard, 14);
        }
        values.files = files;
    }

    addFiles = (files: any[], data: any, type: number) => {
        data.forEach((item: { id: any; }) => {
            files.push({
                type: type,
                fileId: item.id
            })
        })
    }

    computeSelfPayPrice = () => {
        if (this.formRef.current) {
            const totalPrice = this.formRef.current.getFieldValue("totalPrice")
            const medicareReimbursement = this.formRef.current.getFieldValue("medicareReimbursement");
            const otherGrants = this.formRef.current.getFieldValue("otherGrants");
            if (this.isNnumber(totalPrice) && this.isNnumber(medicareReimbursement) && this.isNnumber(otherGrants)) {
                this.formRef.current.setFieldsValue({
                    selfPayPrice: new Decimal(totalPrice).minus(new Decimal(medicareReimbursement)).minus(new Decimal(otherGrants))
                });
            } else {
                this.formRef.current.setFieldsValue({
                    selfPayPrice: null
                });
            }
        }
        // const fieldsValue = form.getFieldValue("date1");
    }

    isNnumber = (value: number) => {

        return !isNaN(value) && value !== null && value !== undefined && isFinite(value);
    }

    timeOfAdmissionDisabledDate: RangePickerProps['disabledDate'] = (current) => {

        return current && (current > dayjs())
    };

    timeOfDischargeDisabledDate: RangePickerProps['disabledDate'] = (current) => {


        const timeOfAdmission = this.formRef.current?.getFieldValue("timeOfAdmission");
        if (timeOfAdmission) {
            return current && (current < timeOfAdmission || current >= dayjs(timeOfAdmission).add(10, 'days'))
        } else {
            return current === current;
        }
    };

    eyeDiseasesChange = (e: any) => {

        //异状胬肉 无法与其他眼别一同申请，不一起可以单独统计怒容的信息
        if (e.length > 1 && e.includes(GILLIUM)) {
            e = e.slice(0, -1);
            this.formRef.current?.setFieldValue("eyeDiseases", e)
            message.error("异状胬肉无法与其他眼病一起申请")
        }
        let {eyeDiseasesConfig} = this.state;
        if (eyeDiseasesConfig) {
            eyeDiseasesConfig.cataractEyeType = true;
            eyeDiseasesConfig.fundusEyeType = true;
            eyeDiseasesConfig.gilliumEyeType = true;
            if (e.length > 0) {
                e.forEach((item: string) => {
                    if (item === CATARACT) {
                        if (eyeDiseasesConfig) {
                            eyeDiseasesConfig.cataractEyeType = false;
                        }
                    } else if (item === FUNDUS) {
                        if (eyeDiseasesConfig) {
                            eyeDiseasesConfig.fundusEyeType = false;
                        }
                    } else if (item === GILLIUM) {
                        if (eyeDiseasesConfig) {
                            eyeDiseasesConfig.gilliumEyeType = false;
                        }
                    }
                })
            }
            this.setState({
                eyeDiseasesConfig: eyeDiseasesConfig
            })
        }

    }

    eyeDiseasesItemChange = (e: any) => {

        if (e.target.checked === false) {
            const value = e.target.value;
            if (value === CATARACT) {
                this.formRef.current?.setFieldValue("cataractEyeType", undefined);
            } else if (value === FUNDUS) {
                this.formRef.current?.setFieldValue("fundusEyeType", undefined);
            } else if (value === GILLIUM) {
                this.formRef.current?.setFieldValue("gilliumEyeType", undefined);
            }
        }
    }


    render() {
        const {data, id, snap, eyeDiseasesConfig} = this.state;
        return (
            <div>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                        <UserInfo data={data}/>
                        <WarVeteranInfo data={data}/>
                        <IllnessInfo data={data}/>
                        <ScreeningInfo data={data}/>
                        <AccountInfo data={data}/>
                        <Card size="small" className={styles.mycard}
                              title={`费用明细`}>
                            <Form
                                onFinish={this.submit}
                                ref={this.formRef}
                            >
                                <Row gutter={20}>
                                    <Col span={10}>

                                        <FormItem
                                            className={styles.mylabel}
                                            name="eyeDiseases"
                                            label={
                                                <>眼病 &nbsp;
                                                    <Popover placement="left" title={data.user.name + "的眼病记录"}
                                                             content={<AierServiceUserIllnessLog quota={data.quota}/>}>
                                                        <QuestionCircleOutlined
                                                            style={{fontSize: '18px', color: '#1677ff'}}/>
                                                    </Popover>
                                                </>
                                            }
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <Checkbox.Group
                                                onChange={this.eyeDiseasesChange}
                                            >
                                                {eyeDiseasesConfig?.checkBox.map((item: CheckboxType) =>
                                                    <Checkbox value={item.value}
                                                              disabled={item.disabled}
                                                              onChange={this.eyeDiseasesItemChange}
                                                              key={item.label}
                                                    >
                                                        {item.label}
                                                    </Checkbox>
                                                )}
                                            </Checkbox.Group>
                                        </FormItem>


                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={10}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="timeOfAdmission"
                                            label="入院时间"
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <DatePicker
                                                disabledDate={this.timeOfAdmissionDisabledDate}
                                                style={{width: '100%'}}
                                                placeholder="入院时间"/>
                                        </FormItem>
                                    </Col>
                                    <Col span={10}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="timeOfDischarge"
                                            label="出院时间"
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <DatePicker
                                                disabledDate={this.timeOfDischargeDisabledDate}
                                                style={{width: '100%'}}
                                                placeholder="出院时间"/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={10}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="totalPrice"
                                            label="总金额"
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <InputNumber
                                                // precision={2}
                                                style={{width: '100%'}}
                                                max={99999}
                                                min={0}
                                                onChange={this.computeSelfPayPrice}
                                                placeholder="总金额"
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={10}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="medicareReimbursement"
                                            label="医保报销"
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <InputNumber
                                                style={{width: '100%'}}
                                                max={99999}
                                                min={0}
                                                onChange={this.computeSelfPayPrice}
                                                placeholder="医保报销"
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={10}>
                                        <Tooltip
                                            title={"此处填写的是除医保报销外医院的其他优惠费用，请不要填写情暖老兵守护光明项目补贴1500元。"}
                                            placement="topLeft"
                                        >
                                            <FormItem
                                                className={styles.mylabel}
                                                name="otherGrants"
                                                label="其他补助"
                                                labelCol={{span: 4}}
                                                wrapperCol={{span: 15}}
                                                rules={[{required: true, message: '必填'}]}
                                            >

                                                <InputNumber
                                                    // precision={2}
                                                    style={{width: '100%'}}
                                                    max={99999}
                                                    min={0}
                                                    onChange={this.computeSelfPayPrice}
                                                    placeholder="其他补助"
                                                />
                                            </FormItem>
                                        </Tooltip>
                                    </Col>
                                    <Col span={10}>
                                        <Tooltip
                                            title={"此处为患者总费用减去医保报销减去其他补助之后的费用，包含情暖老兵守护光明项目补贴金额。"}
                                            placement="topLeft"
                                        >
                                            <FormItem
                                                className={styles.mylabel}
                                                name="selfPayPrice"
                                                label="自付费用"
                                                labelCol={{span: 4}}
                                                wrapperCol={{span: 15}}
                                                rules={[{required: true, message: '必填'}]}
                                            >
                                                <InputNumber
                                                    // precision={2}
                                                    style={{width: '100%'}}
                                                    max={99999}
                                                    min={0}
                                                    placeholder="自付费用"
                                                    disabled
                                                />
                                            </FormItem>
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={10}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="cataractEyeType"
                                            label="白内障眼别"
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: !eyeDiseasesConfig?.cataractEyeType, message: '必填'}]}
                                        >
                                            <Select
                                                style={{width: '100%'}}
                                                placeholder="白内障眼别"
                                                optionFilterProp="children"
                                                filterOption={(input, option: any) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={eyeDiseasesConfig?.cataractSelect}
                                                disabled={eyeDiseasesConfig?.cataractEyeType === true}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={10}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="fundusEyeType"
                                            label="眼底病眼别"
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: !eyeDiseasesConfig?.fundusEyeType, message: '必填'}]}
                                        >
                                            <Select
                                                style={{width: '100%'}}
                                                placeholder="眼底病眼别"
                                                optionFilterProp="children"
                                                filterOption={(input, option: any) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={eyeDiseasesConfig?.fundusSelect}
                                                disabled={eyeDiseasesConfig?.fundusEyeType}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={10}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="gilliumEyeType"
                                            label="翼状胬肉眼别"
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: !eyeDiseasesConfig?.gilliumEyeType, message: '必填'}]}
                                        >
                                            <Select
                                                style={{width: '100%'}}
                                                placeholder="翼状胬肉眼别"
                                                optionFilterProp="children"
                                                filterOption={(input, option: any) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={eyeDiseasesConfig?.gilliumSelect}
                                                disabled={eyeDiseasesConfig?.gilliumEyeType}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={10}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="remark"
                                            label="备注"
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <TextArea rows={4} placeholder="备注" maxLength={500}/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                {/*align="start"*/}
                                <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                    <Card style={{width: '100%'}}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="diagnosticReports"
                                            valuePropName="fileList"
                                            label="诊断报告"
                                            labelCol={{span: 2}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            {/*已刷新*/}
                                            <FileUploader
                                                multiple
                                                listType="picture"
                                                className="upload-list-inline"
                                                accept='*'
                                                checkSize={false}
                                                noChekckSizeMal={true}
                                                icon={<UploadOutlined/>}
                                                typeCode={MediaCodes.aier_file.code}
                                                onRefresh={this.refreshFileOne}
                                            />
                                        </FormItem>
                                    </Card>
                                    <Card style={{width: '100%'}}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="consentForms"
                                            valuePropName="fileList"
                                            label="手术/救治同意书"
                                            labelCol={{span: 2}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            {/*已刷新*/}
                                            <FileUploader
                                                multiple
                                                listType="picture"
                                                className="upload-list-inline"
                                                accept='*'
                                                checkSize={false}
                                                noChekckSizeMal={true}
                                                icon={<UploadOutlined/>}
                                                typeCode={MediaCodes.aier_file.code}
                                                onRefresh={this.refreshFileOne}
                                            />
                                        </FormItem>
                                    </Card>
                                    <Card style={{width: '100%'}}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="hospitalizationRecords"
                                            valuePropName="fileList"
                                            label="住院记录"
                                            labelCol={{span: 2}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            {/*已刷新*/}
                                            <FileUploader
                                                multiple
                                                listType="picture"
                                                className="upload-list-inline"
                                                accept='*'
                                                checkSize={false}
                                                noChekckSizeMal={true}
                                                icon={<UploadOutlined/>}
                                                typeCode={MediaCodes.aier_file.code}
                                                onRefresh={this.refreshFileOne}
                                            />
                                        </FormItem>
                                    </Card>
                                    <Card style={{width: '100%'}}>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="invoices"
                                            valuePropName="fileList"
                                            label="发票"
                                            labelCol={{span: 2}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            {/*已刷新*/}
                                            <FileUploader
                                                multiple
                                                listType="picture"
                                                className="upload-list-inline"
                                                accept='*'
                                                checkSize={false}
                                                noChekckSizeMal={true}
                                                icon={<UploadOutlined/>}
                                                rules={[{required: true, message: '必填'}]}
                                                typeCode={MediaCodes.aier_file.code}
                                                onRefresh={this.refreshFileOne}
                                            />
                                        </FormItem>
                                    </Card>
                                    {data.warVeteran.myself === '2' && (

                                        <Card style={{width: '100%'}}>
                                                    <FormItem
                                                        className={styles.mylabel}
                                                        name="identityPromise"
                                                        valuePropName="fileList"
                                                        label="身份承诺书"
                                                        labelCol={{span: 2}}
                                                        wrapperCol={{span: 15}}
                                                        rules={[{required: true, message: '必填'}]}
                                                    >
                                                        {/*已刷新*/}
                                                        <FileUploader
                                                            multiple
                                                            listType="picture"
                                                            className="upload-list-inline"
                                                            accept='*'
                                                            checkSize={false}
                                                            noChekckSizeMal={true}
                                                            icon={<UploadOutlined/>}
                                                            rules={[{required: true, message: '必填'}]}
                                                            typeCode={MediaCodes.aier_file.code}
                                                            onRefresh={this.refreshFileOne}
                                                        />
                                                    </FormItem>
                                                </Card>
                                    )}
                                    {data.user.identityPassesType === 3 && (

                                        <Card style={{width: '100%'}}>
                                            <FormItem
                                                className={styles.mylabel}
                                                name="preferentialTreatmentCard"
                                                valuePropName="fileList"
                                                label="退役军人优待证"
                                                labelCol={{span: 2}}
                                                wrapperCol={{span: 15}}
                                                rules={[{required: true, message: '必填'}]}
                                            >
                                                {/*已刷新*/}
                                                <FileUploader
                                                    multiple
                                                    listType="picture"
                                                    className="upload-list-inline"
                                                    accept='*'
                                                    checkSize={false}
                                                    noChekckSizeMal={true}
                                                    icon={<UploadOutlined/>}
                                                    rules={[{required: true, message: '必填'}]}
                                                    typeCode={MediaCodes.aier_file.code}
                                                    onRefresh={this.refreshFileOne}
                                                />
                                            </FormItem>
                                        </Card>
                                    )}
                                    {/*{(eyeDiseasesConfig?.gilliumEyeType === false ||*/}
                                    {/*    eyeDiseasesConfig?.fundusEyeType === false) && (*/}

                                    {/*    <Card style={{width: '100%'}}>*/}
                                    {/*        <FormItem*/}
                                    {/*            className={styles.mylabel}*/}
                                    {/*            name="proofProve"*/}
                                    {/*            valuePropName="fileList"*/}
                                    {/*            label="困难退役军人证明"*/}
                                    {/*            labelCol={{span: 2}}*/}
                                    {/*            wrapperCol={{span: 15}}*/}
                                    {/*            rules={[{required: true, message: '必填'}]}*/}
                                    {/*        >*/}
                                    {/*            <FileUploader*/}
                                    {/*                multiple*/}
                                    {/*                listType="picture"*/}
                                    {/*                className="upload-list-inline"*/}
                                    {/*                accept='*'*/}
                                    {/*                checkSize={false}*/}
                                    {/*                noChekckSizeMal={true}*/}
                                    {/*                icon={<UploadOutlined/>}*/}
                                    {/*                rules={[{required: true, message: '必填'}]}*/}
                                    {/*            />*/}
                                    {/*        </FormItem>*/}
                                    {/*    </Card>*/}
                                    {/*)}*/}
                                </Space>
                                <Space>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                        className={styles.postSave}
                                    >
                                        保存
                                    </Button>
                                    <Button
                                        size='large'
                                        className={styles.backBtn}
                                        style={{marginRight: '15px'}}
                                        onClick={this.cancel}
                                    >
                                        取消
                                    </Button>
                                </Space>
                            </Form>
                        </Card>
                    </Space>

                </div>
            </div>
        )
            ;
    }
}

export default function ServiceUserHospitalScreeningAudit() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <ServiceUserHospitalScreeningAuditClass/>
        </App>
    );
}
