import React, {Component} from 'react';
import {Breadcrumb, Button, Input, Select, Space, Table} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import {ColumnsType} from 'antd/es/table';
import {Link} from 'react-router-dom';
import 'dayjs/locale/zh-cn';
import App from '../../App';
// import ExpressFrom from './expressFrom';
import {TheState} from './types';

type User = {};

// const FormModal = createModal(ExpressFrom);
class InvoiceList1 extends Component<{}, TheState> {
    state: TheState = {
        page: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        content: [],
        roleId: 0,
        orderId: 0,
        loading: true,
        statusArr: {
            1: '待开票',
            2: '已开票',
            3: '作废中',
            4: '重开中',
            5: '已上传',
        },
        status: 0,
        search: '',
        type: '',
    };
    private modal: any = null;

    componentDidMount() {
        this.loadData();
    }

    changeInpt(id: number) {
        this.setState(
            {
                orderId: id,
            },
            () => {
                this.modal.show();
            }
        );
    }

    changeCondition = (condition: any, load = false) => {
        this.setState(
            {
                ...condition,
            },
            () => {
                if (load) {
                    this.loadData();
                }
            }
        );
    };

    loadData = () => {
        const {page, pageSize, status, search, type} = this.state;
        Fetch.get(
            `${API.invoice.list}?page=${page}&size=${pageSize}&status=${status}&type=${type}&search=${search}`
        ).then((data) => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                loading: false,
            });
        });
    };

    reset = () => {
        this.setState(
            {
                status: 0,
                search: '',
                type: '',
                page: 0,
            },
            this.loadData
        );
    };

    handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            this.loadData();
        }
    };

    render() {
        let that = this;
        const {page, pageSize, totalElements, content, statusArr} = this.state;
        const columns: ColumnsType<User> = [
            {
                title: '抬头',
                dataIndex: 'title',
                align: `center`,
                render(value: any, record: any, index: any) {
                    return <span style={{wordBreak: 'break-all'}}>{value}</span>;
                },
            },
            {
                title: '抬头类型',
                dataIndex: 'type',
                align: `center`,
                width: 120,
                render(value: any, record: any, index: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>
              {value == 1 ? '个人' : '单位'}
            </span>
                    );
                },
            },
            {
                title: '金额',
                dataIndex: 'totalMoney',
                align: `center`,
            },

            {
                title: '内容',
                dataIndex: 'content',
                align: `center`,
                width: 120,
                render(value: any) {
                    return <span style={{wordBreak: 'break-all'}}>{value}</span>;
                },
            },
            {
                title: '申请时间',
                dataIndex: 'createdDate',
                align: `center`,
            },
            {
                title: '开票状态',
                dataIndex: 'status',
                align: `center`,
                render(value: any, record: any) {
                    return statusArr[value];
                },
            },
            {
                title: '开票时间',
                dataIndex: 'billingDate',
                align: `center`,
                render(value: any, record: any) {
                    return value ? value : '--';
                },
            },
            {
                title: '关联订单数',
                dataIndex: 'countContent',
                align: `center`,
            },
            {
                title: '操作人',
                dataIndex: 'operator',
                align: `center`,
                render(value: any, record: any) {
                    return value ? value : '--';
                },
            },
            {
                title: '系统编号',
                dataIndex: 'code',
                align: `center`,
                width: 120,
                render(value: any) {
                    return <span style={{wordBreak: 'break-all'}}>{value}</span>;
                },
            },

            {
                title: '操作',
                align: `center`,
                dataIndex: 'status',
                width: 65,
                render: (text: any, record: any) => {
                    return (
                        <Space>
                            {/* <Button type="text">开票</Button> */}
                            {/* statusArr: {
      1: '待开票',
      2: '已开票',
      3: '作废中',
      4: '重开中', */}

                            <Link to={`/invoicedetail?id=${record.id}`}>
                                {text == 1 || text == 4 || text == 5 ? '开票' : '详情'}
                            </Link>
                        </Space>
                    );
                },
            },
        ];

        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>发票管理</Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    {/* status: '', search: '', type: '', */}
                    <Space onKeyDown={this.handleKeyDown}>
                        <Input
                            placeholder="请输入搜索内容"
                            value={this.state.search}
                            onChange={(e) =>
                                this.changeCondition({page: 0, search: e.target.value})
                            }
                        />
                        发票状态：
                        <Select
                            value={this.state.status}
                            onChange={(e) => this.changeCondition({status: e, page: 0})}
                            style={{width: '100px'}}
                        >
                            <Select.Option value={0}>全部</Select.Option>
                            <Select.Option value={1}>待开票</Select.Option>
                            <Select.Option value={2}>已开票</Select.Option>
                            <Select.Option value={3}>作废中</Select.Option>
                            <Select.Option value={4}>重开中</Select.Option>
                            <Select.Option value={5}>已上传</Select.Option>
                        </Select>
                        抬头类型：
                        <Select
                            value={this.state.type}
                            onChange={(e) => this.changeCondition({type: e, page: 0})}
                            style={{width: '100px'}}
                        >
                            <Select.Option value="">全部</Select.Option>
                            <Select.Option value={1}>个人</Select.Option>
                            <Select.Option value={0}>单位</Select.Option>
                        </Select>
                        <Button onClick={this.loadData} type="primary">
                            搜索
                        </Button>
                        <Button onClick={this.reset}>重置</Button>
                    </Space>
                    <Table
                        loading={this.state.loading}
                        className="mgTop15"
                        bordered
                        rowKey="id"
                        columns={columns}
                        dataSource={content}
                        pagination={{
                            current: page + 1,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) =>
                            this.changeCondition(
                                {
                                    page: pagination.current - 1,
                                    pageSize: pagination.pageSize,
                                },
                                true
                            )
                        }
                    />
                    {/* <FormModal
            ref={(c) => (this.modal = c)}
            id={orderId}
            loadData={this.loadData}
          /> */}
                </div>
            </>
        );
    }
}

export default function InvoiceList() {
    return (
        <App selectedKeys={['invoicelist']}>
            <InvoiceList1/>
        </App>
    );
}
