import {Component} from 'react';
import Fetch from '../../../common/FetchIt';
import API from '../../../common/api';
import common from '../../../common/common';
import {decryptData} from "../../../common/crypto";
import dayjs from "dayjs";
import {FileTypeTypes} from "../../../common/modal/ViewFileListTypes";
import {BillDetailRefreshFileType, DefaultRefreshFile, HospitalBillDetail} from "./types/HospitalBillType";
import {aireBillRefreshData, billDetailDefault} from "./types/HospitalDetailCommon";

class ServiceUserDetailParentClass<S = {}> extends Component {

    state: HospitalBillDetail = {

        billId: decryptData(common.getQuery('billId')),
        data: billDetailDefault.data,
        startTime: dayjs().valueOf(),
        refreshData: DefaultRefreshFile(),
        refreshCount: 0,
        spinning: true
    };

    componentDidMount() {

    };

    refreshFile = (type: string) => {

        return new Promise((resolve, reject) => {
            this.refreshData().then((data: BillDetailRefreshFileType) => {
                resolve(data[type])
            })
        })
    }

    refreshFileOne = (type: string, mediaId: number) => {

        return new Promise((resolve, reject) => {
            this.refreshData().then((data: BillDetailRefreshFileType) => {
                const datum = data[type];
                datum.forEach((item: FileTypeTypes) => {
                    if (item.mediaId === mediaId) {
                        resolve(item.filePath);
                    }
                })
            })
        })
    }

    private refreshData = () => {

        const that = this;
        return new Promise<BillDetailRefreshFileType>((resolve, reject) => {
            const {billId, startTime, refreshData, refreshCount} = that.state;
            //开启时间
            const turnOnTime = (dayjs().valueOf() - startTime) / 1000;
            //刷新覆盖时间
            const refresh = refreshCount * API.fileMaxTime;
            if (turnOnTime - refresh > API.fileMaxTime) {
                let url = `${API.service_user_bill.refresh_file}?billId=${billId}`;
                that.setState({spinning: true}, () => {
                    Fetch.get(url).then((data) => {
                        console.log("刷新！！！！")
                        const refreshFiles = aireBillRefreshData(data);
                        const count = Math.floor(turnOnTime / API.fileMaxTime); // 输出 31
                        this.setState({
                            refreshData: refreshFiles,
                            refreshCount: count,
                            spinning: false
                        }, () => {
                            resolve(refreshFiles);
                        })
                    });
                })
            } else {
                resolve(refreshData);
            }
        })
    }
}

export default ServiceUserDetailParentClass;
