import React, {Component} from 'react';
import {Button, Form, FormInstance, Input, message} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import styles from './ad.module.css';
import {Ad} from './types';
import FileUploader from '../common/uploader';
import Editor from '../common/editor/Editor';
import MediaCodes from "../common/uploader/MediaCodes";
import MediaUtils from "../common/uploader/MediaPath";

const FormItem = Form.Item;

type FormProps = {
    ad: Ad;
    hide: () => void;
    reload: () => void;
};

type FormState = {};

class AdForm extends Component<FormProps, FormState> {
    formRef = React.createRef<FormInstance>();

    state: FormState = {};

    submit = (values: any) => {
        values.mediaId = values.mediaId[0].id;

        values.text = values.text.toHTML ? values.text.toHTML() : values.text;

        if (values.text == '<p></p>') {
            // values.text=''
            message.error('内容不能为空！');
            return;
        }
        if (values.id) {
            // 更改
            this.update(values);
        } else {
            // 增加
            let datas = {...values};
            delete datas.id;
            this.create(datas);
        }
    };

    create = (values: Ad) => {
        Fetch.postJSON(`${API.ad.zsgc}`, values).then(() => {
            message.success('提交成功');
            this.props.hide();
            this.props.reload();
        });
    };

    update = (values: Ad) => {
        Fetch.putJSON(API.ad.update, values).then(() => {
            message.success('保存成功');
            this.props.hide();
            this.props.reload();
        });
    };

    render() {
        return (
            <div className={styles.container}>
                <Form
                    initialValues={this.props.ad}
                    onFinish={this.submit}
                    ref={this.formRef}
                >
                    <FormItem hidden noStyle name="id">
                        <Input/>
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="name"
                        label="标题"
                        labelCol={{span: 4}}
                        wrapperCol={{span: 20}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <Input placeholder="标题"/>
                    </FormItem>

                    <FormItem
                        className={styles.mylabel}
                        name="mediaId"
                        valuePropName="fileList"
                        label="上传图片"
                        labelCol={{span: 4}}
                        wrapperCol={{span: 20}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        {/*无需刷新*/}
                        <FileUploader
                            checkSize={false}
                            desc="支持扩展名：.jpg、.png; 图片大小不能超过2M; 建议尺寸 670 * 320"
                            typeCode={MediaCodes.ad_pub.code}
                            onRefresh={MediaUtils.upLoadRefresh}
                        />
                    </FormItem>

                    {/* 编辑器 */}
                    <FormItem
                        className={styles.mylabel}
                        name="text"
                        label="内容"
                        labelCol={{span: 4}}
                        wrapperCol={{span: 20}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <Editor url={API.upload}/>
                    </FormItem>

                    <FormItem wrapperCol={{span: 12, offset: 6}} className="center">
                        <Button type="primary" htmlType="submit" className={styles.mybtn}>
                            提交
                        </Button>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

export default AdForm;
