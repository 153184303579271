import React from 'react';
import {Button, Divider, Input, message, Modal, Popover, Select, Space, Spin, Table} from 'antd';
import API from '../../common/api';
import {ColumnsType} from 'antd/es/table';
import {Link} from 'react-router-dom';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import encryptData from '../../common/crypto';
import BaseListPage from "../../common/listpage/BaseListPage";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {ApplicationList, ApplicationListType, BaseDhPageDate} from "../common/DhPageCommon";
import {DhSelectStatus, dhStatusColorObj, dhStatusObj} from "../common/DhDictData";
import styles from "../../cooperate/add/ServiceUser.module.css";
import Fetch from "../../common/FetchIt";
import common from "../../common/common";
import createModal from "../../common/createModal";
import CompleteModalImport from "./DhServiceUserReimbursed";
import FailedModalImport from "./DhServiceUserReimbursedFailed";
import {addParams, NUMBER_STR, STRING_STR} from "../../cooperate/bill/page/ListCommon";
import DhRecordOperationsDetail from "../patientDetail/DhRecordOperationsDetail";
import {DatabaseOutlined, InfoCircleOutlined} from "@ant-design/icons";
import MediaPath from "../../common/uploader/MediaPath";

type User = {
    accountRefine: boolean,
    name: string,
    id: number
};
const CompleteModal = createModal(CompleteModalImport);
const FailedModal = createModal(FailedModalImport);

const {confirm} = Modal;

class DhFinanceAuditsListClass extends BaseListPage {

    state: ApplicationListType = {
        ...ApplicationList,
        btnQ: {}
    };
    private completeModalResult: any = null;
    private failedModalResult: any = null;

    componentDidMount() {

        super.componentDidMount();
        DhSelectStatus([110, 112, 115, 116, 117, 118, 114], this);
        this.loadHospital();
    }

    loadData = () => {
        const {pageSize, searchData} = this.state;
        let url = `${API.dentistry.service_user.find_finance_page}?page=${searchData.page}&size=${pageSize}`;
        url = url + addParams("name", searchData.name, STRING_STR);
        url = url + addParams("phone", searchData.phone, STRING_STR);
        url = url + addParams("idCard", searchData.idCard, STRING_STR);
        url = url + addParams("status", searchData.status, NUMBER_STR);
        url = url + addParams("hospitalId", searchData.hospitalId, NUMBER_STR);
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                importLoading: false
            });
        })
    };

    loadHospital() {

        // this.state.searchData.hospitalId = undefined
        Fetch.get(`${API.dhHospital.find_select_data}`).then((data) => {
            this.setState({
                hospitalSelect: data
            });
        });
    };

    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
    };

    reset = () => {
        super.reset(BaseDhPageDate.searchData);
    };

    handleKeyDown = (e: any) => {
        super.handleKeyDown(e);
    }

    closeModal = () => {
        this.setState({
                importLoading: false,
            }, () => {
                this.loadData();
            }
        );
    }

    dhReimbursementExport = () => {

        let that = this;
        confirm({
            title: `是否确认导出？`,
            onOk() {
                that.setState({
                    importLoading: true,
                    tip: "数据导出中"
                });
                Fetch.postJSON(API.dentistry.dh_reimbursed_batch.batch_create).then((data) => {

                    let fileName = data.code + ".xls"
                    that.downloadTotal(fileName, data.path);
                    message.success('导出成功');
                }).catch(() => {
                    that.setState({
                        importLoading: false,
                    });
                });
            },
        });
    }

    downloadTotal = (fileName: string, path: string) => {

        common.downloadFile(fileName, MediaPath.getFileUrl(path), (callback: any) => {
            if (callback === true) {
                message.success('处理完成');
            } else {
                message.error('下载失败，请联系管理员');
            }
            this.setState({
                importLoading: false,
            }, () => {
                this.loadData()
            });
        });
    };

    goReimbursedBatchPage = () => {
        window.location.href = '/#/dh/reimbursed_batch/list';
    }

    dhReimbursement = (id: number) => {

        this.setState({
            selectId: id,
        }, () => {
            this.completeModalResult.show()
        })
    }

    dhReimbursementFail = (id: number) => {
        this.setState({
            selectId: id,
        }, () => {
            this.failedModalResult.show()
        })
    }


    render() {
        const {
            pageSize, totalElements,
            btnQ, searchData, content,
            hospitalSelect, statusListArr
        } = this.state;
        const columns: ColumnsType<User> = [

            {
                title: '姓名',
                dataIndex: 'name',
                align: `center`,
                render: (text: any) => {
                    return (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <span style={{wordBreak: 'break-all'}}>{text}</span>
                        </div>
                    );
                },
            },
            {
                title: '身份证号',
                dataIndex: 'idNumber',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '收款方式',
                dataIndex: 'paymentMethod',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '收款人',
                dataIndex: 'payee',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '银行卡号',
                dataIndex: 'backCard',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '关联医院',
                dataIndex: 'hospitalName',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '状态',
                dataIndex: 'dhStatus',
                align: `center`,
                render(value: any, record: any) {
                    return <>
                        <div className={styles.statusDiv}>
                            <div
                                style={{
                                    wordBreak: 'break-all',
                                    color: dhStatusColorObj[value]
                                }}>{dhStatusObj[value]}</div>
                            <Popover placement="left" title={record.name + "的操作记录"}
                                     content={<DhRecordOperationsDetail serviceId={record.id}/>}>
                                {value === 113 || value === 116 ? (
                                    <InfoCircleOutlined className={styles.warnIcon}/>
                                ) : (
                                    <DatabaseOutlined className={styles.recordIcon}/>
                                )}
                            </Popover>
                        </div>
                    </>;
                },
            },
            {
                title: '操作',
                align: `center`,
                width: 280,
                render: (text: any, record: any) => {
                    return (
                        <Space split={<Divider type="vertical"/>}>
                            {(record.dhStatus === 115) && (
                                <>
                                    {(btnQ.ROLE_146001) && (
                                        <Button className={styles.antBtnPadding} type="link" size={"small"}
                                                onClick={() => this.dhReimbursement(record.id)}>
                                            完成报销
                                        </Button>
                                    )}
                                    {(btnQ.ROLE_146002) && (
                                        <Button className={styles.antBtnPadding} type="link" size={"small"}
                                                onClick={() => this.dhReimbursementFail(record.id)}>
                                            报销失败
                                        </Button>
                                    )}
                                </>
                            )}
                            {((record.dhStatus === 110 &&
                                (btnQ.ROLE_146003 || btnQ.ROLE_146004))) && (
                                <Link to={`/dh/finance/audit?id=${encryptData(record.id + "")}`}>
                                    审核
                                </Link>
                            )}
                            {btnQ.ROLE_146005 && (
                                <Link to={`/dh/patient_application/detail?id=${encryptData(record.id + "")}`}>
                                    详情
                                </Link>
                            )}
                        </Space>
                    );
                },
            },
        ];

        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space direction="vertical" style={{width: '100%'}}>
                            <Space onKeyDown={this.handleKeyDown}>
                                <Input
                                    placeholder="姓名"
                                    value={searchData.name}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, name: e.target.value})
                                    }
                                    }
                                />
                                <Input
                                    placeholder="手机号"
                                    value={searchData.phone}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, phone: e.target.value})
                                    }
                                    }
                                />
                                <Input
                                    placeholder="身份证号"
                                    value={searchData.idCard}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, idCard: e.target.value})
                                    }
                                    }
                                />
                                <Select
                                    style={{width: 220}}
                                    placeholder="状态"
                                    optionFilterProp="children"
                                    options={statusListArr}
                                    value={searchData.status}
                                    allowClear={true}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, status: e})
                                    }}
                                />
                                <Select
                                    showSearch
                                    placeholder="选择执行机构名称"
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, hospitalId: e})
                                    }}
                                    value={searchData.hospitalId}
                                    filterOption={(input: any, option: any) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    style={{width: 200}}
                                    options={hospitalSelect}
                                />

                                <Button onClick={this.loadData}>
                                    搜索
                                </Button>
                                <Button onClick={() => this.reset()}>重置</Button>
                                {btnQ.ROLE_146006 && (
                                    <Button onClick={this.dhReimbursementExport}>
                                        导出待报销名单
                                    </Button>
                                )}
                                {btnQ.ROLE_146007 && (
                                    <Button onClick={this.goReimbursedBatchPage}>
                                        查看导出记录
                                    </Button>
                                )}
                            </Space>
                        </Space>
                        <Table
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
                <CompleteModal
                    width={1200}
                    title={'报销'}
                    importResult={this.state.selectId}
                    onCancel={this.closeModal}
                    ref={(c) => (this.completeModalResult = c)}
                />
                <FailedModal
                    width={500}
                    title={'报销失败'}
                    importResult={this.state.selectId}
                    onCancel={this.closeModal}
                    ref={(c) => (this.failedModalResult = c)}
                />
            </>
        );
    }
}

export default function DhFinanceAuditsList() {

    return (

        <App selectedKeys={
            ['DH_FINANCE_AUDITS']
        }
             openKeys="ORAL_GUARD">
            <DhFinanceAuditsListClass/>
        </App>
    );
}
