import React, {Component} from 'react';
import {
    Button,
    Card,
    Checkbox,
    Col,
    Form,
    FormInstance,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Select,
    Space
} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import FileUploader from '../../common/uploader/FileUploader';
import FormItem from 'antd/lib/form/FormItem';
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import {decryptData} from "../../common/crypto";
import {Options, QuesTypeSelect} from "../ExamCommonData";
import {CloseOutlined, PlusOutlined} from "@ant-design/icons";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {v4 as uuidV4} from 'uuid';

const confirm = Modal.confirm;

const {TextArea} = Input;

type ExamQuesAddType = {
    paperCode: string,
    answers: string[],
    token: string,
    quesId: string
}

const initFormData = {
    quesTitle: null,
    quesType: null,
    quesScore: 20,
    parse: null,
    answers: null,
    optionsSaveVos: [{optionName: 'A', checkbox: false}, {optionName: 'B', checkbox: false}]
}

class ExamQuesAddClass extends Component<{}, ExamQuesAddType> {

    state: ExamQuesAddType = {
        paperCode: decryptData(common.getQuery('paperCode')),
        answers: [],
        token: uuidV4(),
        quesId: decryptData(common.getQuery('quesId'))
    };
    formRef = React.createRef<FormInstance>();
    refd = React.createRef<FileUploader>();

    componentDidMount() {
        this.init()
    };

    init = () => {
        const {quesId} = this.state;
        if (quesId) {
            this.getData();
        } else {
            this.setState({
                token: uuidV4(),
                answers: []
            }, () => {
                this.formRef.current?.setFieldsValue(initFormData)
            })
        }
    }

    getData() {
        const {quesId} = this.state;
        Fetch.get(`${API.exam.exam_ques.get_detail}?quesId=${quesId}`).then((data) => {
            this.buildData(data);
            this.formRef.current?.setFieldsValue({
                ...data,
            })
        });
    }

    buildData = (data: any) => {
        let answers: string[] = [];
        data.optionsSaveVos = data.optionsVos
        data.optionsSaveVos.forEach((item: any) => {
            if (data.answers.includes(item.optionCode)) {
                item.checkbox = true;
                answers.push(item.optionName);
            }
        })
        this.setState({
            answers: answers
        }, () => {
            data.answers = answers.join(", ")
        })
    }

    submit = (values: any) => {

        const {quesId} = this.state;
        if (quesId) {
            this.update(values);
        } else {
            this.create(values);
        }
    }

    update = (values: any) => {

        const {answers, quesId} = this.state;
        values.id = quesId;
        values.answers = answers;
        Fetch.postJSON(API.exam.exam_ques.update_question, values).then(() => {
            message.success('提交成功');
            window.history.go(-1);
        });
    };

    create = (values: any) => {

        const {paperCode, answers, token} = this.state;
        values.paperCode = paperCode;
        values.answers = answers;
        values.token = token;
        const that = this;
        Fetch.postJSON(API.exam.exam_ques.save_question, values).then(() => {
            message.success('提交成功');
            confirm({
                title: `是否继续添加？`,
                onOk() {
                    that.init();
                },
                onCancel() {
                    window.history.go(-1);
                }
            });
        });
    };

    cancel = (values: any) => {
        window.history.go(-1);
    }

    checkFormList = (length: number) => {

        if (length > 4) {
            message.error("最多添加5个选项")
            return false;
        }
        return true;
    }

    optionChange = (e: CheckboxChangeEvent) => {

        this.editAnswers(e.target.value, e.target.checked);
    }

    editAnswers = (value: string, addOrRemove: boolean) => {

        let {answers} = this.state;
        if (addOrRemove) {
            answers.push(value);
        } else {
            answers = answers.filter(item => item !== value);
        }
        this.setState({
            answers: answers
        }, () => {
            this.formRef.current?.setFieldsValue({
                answers: answers.join(", ")
            })
        })
    }

    removeFormList = (value: string) => {

        this.editAnswers(value, false);
    }

    render() {
        return (
            <div>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Space direction="vertical" size="middle" style={{display: 'flex'}}>

                        <Card
                            title={`添加试题`} size={"small"}>
                            <Form
                                onFinish={this.submit}
                                ref={this.formRef}
                            >
                                <Row gutter={20}>
                                    <Col span={10}>
                                        <FormItem

                                            name="quesTitle"
                                            label="试题"
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <Input style={{width: '100%'}}
                                                   placeholder="试题"/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={10}>
                                        <FormItem
                                            name="quesType"
                                            label="试题类型"
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <Select
                                                style={{width: '100%'}}
                                                placeholder="试题类型"
                                                optionFilterProp="children"
                                                filterOption={(input, option: any) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={QuesTypeSelect}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={10}>
                                        <FormItem
                                            name="quesScore"
                                            label="分数"
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <InputNumber style={{width: '100%'}}
                                                         min={0}
                                                         max={50}
                                                         placeholder="分数"/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={10}>
                                        <Form.List
                                            name="optionsSaveVos"
                                            rules={[
                                                {
                                                    validator: async (_, names) => {
                                                        if (!names || names.length < 2) {
                                                            return Promise.reject(new Error('需要添加选项'));
                                                        }
                                                    },
                                                },
                                            ]}
                                        >
                                            {(subFields, subOpt, {errors}) => (
                                                <div style={{display: 'flex', flexDirection: 'column', rowGap: 16}}>
                                                    {subFields.map((subField, index) => (
                                                        <div style={{display: 'flex'}} key={index}>
                                                            <Form.Item noStyle name={[subField.name, 'checkbox']}
                                                                       valuePropName="checked">
                                                                <Checkbox value={Options[index]}
                                                                          onChange={this.optionChange}></Checkbox>
                                                            </Form.Item>
                                                            <Form.Item noStyle name={[subField.name, 'optionName']}>
                                                                <Input style={{width: '8%', border: 'none'}} readOnly/>
                                                            </Form.Item>
                                                            <Form.Item
                                                                validateTrigger={['onChange', 'onBlur']}
                                                                noStyle name={[subField.name, 'optionTitle']} rules={[
                                                                {
                                                                    required: true,
                                                                    whitespace: true,
                                                                    message: "请填写选项内容",
                                                                },
                                                            ]}>
                                                                <Input placeholder="请填写选项内容"
                                                                       style={{width: '80%'}}/>
                                                            </Form.Item>
                                                            <CloseOutlined style={{paddingLeft: '10px'}}
                                                                           onClick={() => {
                                                                               this.removeFormList(Options[index]);
                                                                               subOpt.remove(subField.name);
                                                                           }}
                                                            />

                                                        </div>
                                                    ))}
                                                    <Button type="dashed"
                                                            onClick={() => {
                                                                const result = this.checkFormList(subFields.length)
                                                                if (result) {
                                                                    subOpt.add({optionName: Options[subFields.length]})
                                                                }
                                                            }}
                                                            block
                                                            icon={<PlusOutlined/>}>
                                                        添加选项
                                                    </Button>
                                                    <Form.Item
                                                        labelCol={{span: 4}}
                                                        wrapperCol={{span: 15}}
                                                    >
                                                        <Form.ErrorList errors={errors}/>
                                                    </Form.Item>
                                                </div>
                                            )}
                                        </Form.List>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={10}>
                                        <FormItem
                                            name="answers"
                                            label="答案"
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <Input placeholder="请选择答案" readOnly/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={10}>
                                        <FormItem
                                            name="parse"
                                            label="解析"
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <TextArea rows={4} placeholder="解析" maxLength={1000}/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Space>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                    >
                                        保存
                                    </Button>
                                    <Button
                                        size="large"
                                        style={{marginRight: '15px'}}
                                        onClick={this.cancel}
                                    >
                                        取 消
                                    </Button>
                                </Space>
                            </Form>
                        </Card>
                    </Space>

                </div>
            </div>
        );
    }
}

export default function ExamQuesAdd() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <ExamQuesAddClass/>
        </App>
    );
}
