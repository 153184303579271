import React from 'react';
import type {TableColumnsType} from 'antd';
import {Button, message, Modal, Select, Space, Spin, Table} from 'antd';
import API from '../../common/api';
import {ColumnsType} from 'antd/es/table';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import {AppletsConfigPage} from '../util/types';
import {appletsConfigListDefaultData, listDefaultData} from "../util/AierCommon";
import BaseListPage from "../../common/listpage/BaseListPage";
import styles from "../hospitalauditv2/ServiceUserV2.module.css";
import Fetch from "../../common/FetchIt";
import createModal from "../../common/createModal";
import AddHospitalModal from "./AddHospitalModal";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";

type User = {};
const {confirm} = Modal;

interface ExpandedDataType {
    key: React.Key;
    cityId: number;
    cityName: string;
    hospitalId: number;
    hospitalName: string;
    provinceName: string;
}

const ImportResult = createModal(AddHospitalModal);

class HospitalAppletsConfigClass extends BaseListPage {
    state: AppletsConfigPage = {
        ...appletsConfigListDefaultData,
    };
    private importResult: any = null;

    componentDidMount() {
        super.componentDidMount();
        this.loadSelectData();
    }

    loadData = () => {

        const {pageSize, searchData} = this.state;
        const provinceId = searchData.provinceId ? searchData.provinceId : 0;
        let url = `${API.aier_hospital_applets_config.list}?page=${searchData.page}&size=${pageSize}`;
        url = url + `&provinceId=${provinceId}`
        super.doLoadData(url, data => {

            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
            });
        })
    };

    updateParticipate = (hospitalId: number) => {
        const that = this;
        confirm({
            title: `是否修改医院参与状态？`,
            okText: `提交`,
            onOk() {
                Fetch.postJSON(API.aier_hospital_role.update_participate, {hospitalId: hospitalId}).then(() => {
                    message.success('提交成功');
                    that.loadData();
                });
            }
        });
    }

    closeModal = () => {
        this.setState({
                importLoading: false,
            }, () => {
                this.loadData();
            }
        );
    }

    addHospital = (provinceId: number, cityName: string, cityId: number) => {
        this.setState({
            selectId: provinceId,
            selectCityName: cityName,
            selectCityId: cityId
        }, () => {
            this.importResult.show()
        })
    }

    reset = () => {
        super.reset(listDefaultData.searchData);
    };

    loadSelectData = () => {
        let url = `${API.zone.find_zone_by_pid}?parentId=0&r=1`;
        Fetch.get(url).then((data) => {

            let resultList = [];
            for (let dataKey in data) {
                resultList.push({
                    value: data[dataKey].id,
                    label: data[dataKey].name
                })
            }
            this.setState({
                zoneSelect: resultList
            });
        });
    };


    render() {
        const {
            searchData, pageSize, totalElements,
            content, btnQ
        } = this.state;
        const expandedRowRender = (record: any, index: number, indent: any, expanded: any) => {
            const columns: TableColumnsType<ExpandedDataType> = [
                {title: '省', width: 80, dataIndex: 'provinceName', key: 'provinceName'},
                {title: '市', width: 80, dataIndex: 'cityName', key: 'cityName'},
                {title: '医院名称', width: 80, dataIndex: 'hospitalName', key: 'hospitalName'},
                {
                    title: '操作',
                    dataIndex: 'operation',
                    key: 'operation',
                    width: 80,
                    render: (v: any, c: any) => (
                        <Space size="middle">
                            <Button className={styles.antBtnPadding} type="link" danger size={"small"}
                                    onClick={() => this.updateParticipate(c.hospitalId)}>
                                不参与
                            </Button>
                        </Space>
                    ),
                },
            ];
            return <Table columns={columns} dataSource={record.cityAier} pagination={false}/>;
        };

        //

        const columns: ColumnsType<User> = [
            {
                title: '省',
                width: 80,
                dataIndex: 'provinceName',
                key: 'provinceName',
                align: `center`,
                render: (text: any) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '市',
                width: 80,
                dataIndex: 'cityName',
                key: 'cityName',
                align: `center`,
                render: (text: any) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '医院数量',
                width: 100,
                dataIndex: 'hospitalCount',
                key: 'hospitalCount',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '操作',
                key: 'operation',
                width: 100,
                render: (text: any, record: any) => {
                    return (
                        <Space>
                            <>
                                <Button className={styles.antBtnPadding} type="link" size={"small"}
                                        onClick={() => this.addHospital(record.provinceId, record.cityName, record.cityId)}>
                                    修改该市显示医院
                                </Button>
                            </>
                        </Space>
                    );
                },
            },
        ];
        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>

                    {/*<Breadcrumb style={{margin: '16px 0'}}>*/}
                    {/*    <Breadcrumb.Item>*/}
                    {/*        <Link to="/aier_hospital/list">*/}
                    {/*            医院管理*/}
                    {/*        </Link>*/}
                    {/*    </Breadcrumb.Item>*/}
                    {/*</Breadcrumb>*/}
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space className={styles.areaHospitalSelect}>
                            <Select
                                showSearch
                                placeholder="选择省份"
                                optionFilterProp="children"
                                onChange={(e) => {
                                    this.changeCondition({page: 0, provinceId: e})
                                }}
                                value={searchData.provinceId}
                                filterOption={(input: any, option: any) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                style={{width: 120}}
                                options={this.state.zoneSelect}
                            />
                            <Button onClick={this.loadData}>
                                搜索
                            </Button>
                            <Button onClick={() => this.reset()}>重置</Button>
                        </Space>
                        {content &&
                            <Table
                                className="mgTop15"
                                bordered
                                rowKey="cityId"
                                columns={columns}
                                dataSource={content}
                                scroll={{x: 1500}}
                                expandable={{expandedRowRender, defaultExpandedRowKeys: ['0']}}
                                pagination={{
                                    current: searchData.page + 1,
                                    pageSize,
                                    total: totalElements,
                                    showTotal: (total) => `共${total}条`,
                                    showSizeChanger: false,
                                }}
                                onChange={(pagination: any) =>
                                    this.pageChange(
                                        {
                                            page: pagination.current - 1,
                                            pageSize: pagination.pageSize,
                                        }
                                    )
                                }
                            />}

                    </div>
                </Spin>
                <ImportResult
                    width={1500}
                    title={'添加医院'}
                    importResult={this.state.selectId}
                    cityName={this.state.selectCityName}
                    cityId={this.state.selectCityId}
                    onCancel={this.closeModal}
                    ref={(c) => (this.importResult = c)}
                />
            </>
        );
    }

}

export default function HospitalAppletsConfigList() {
    return (

        <App selectedKeys={
            ['HospitalAppletsConfig']
        }
             openKeys="aierOphthalmology">
            <HospitalAppletsConfigClass/>
        </App>
    );
}
