import React, {useEffect, useState} from 'react';
import {Card, Col, Input, Radio, Row, Select} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import styles from "../../cooperate/hospitalauditv2/ServiceUserV2.module.css";
import {getBackCardRole, getIdNumberRole, PHONE_NUMBER} from "../../common/VerifyRegex";
import FileUploader from "../../common/uploader";
import {UploadOutlined} from "@ant-design/icons";
import {DhDetailDataType, EditType} from "../common/DhDetailType";
import {EditBaseType} from "../common/edit/DhEditAndDetailPageConfig";
import {buildEditAccount} from "../common/edit/DhBackfillDataConfig";
import MediaCodes from "../../common/uploader/MediaCodes";
import MediaUtils from "../../common/uploader/MediaPath";


type EditWarVeteranType = EditBaseType & {
    fileEdit: boolean,
    initDate?: DhDetailDataType["data"],
    taskDetailId?: number | undefined,
    required: boolean | undefined,
};

export default function EditAccount(props: EditWarVeteranType) {

    const [paymentMethodId, setPaymentMethodId] = useState<string>('1');
    const [advanceMoney, setAdvanceMoney] = useState<boolean>(false);
    const [fileNotNull, setFileNotNull] = useState<boolean>(false);
    const [isBackCardChange, setBackCardChange] = useState<boolean>(false);
    const [isIdNumberChange, setIdNumberChange] = useState<boolean>(false);


    useEffect(() => {
        if (props.initDate && props.initDate.account) {
            setPaymentMethodId(props.initDate.account.paymentMethod)
            const fileNull = props.initDate.account.accountApplicant != null &&
                props.initDate.account.accountApplicant.length > 0;
            setFileNotNull(fileNull)
        } else {
            setPaymentMethodId('1')
            props.form.current?.setFieldsValue({
                paymentMethod: '1',
            });
        }
    }, [])

    useEffect(() => {

        if (props.taskDetailId) {
            let name = props.form.current?.getFieldValue("name");
            props.form.current?.setFieldsValue({
                accountName: name,
            });
        }
    }, [props.taskDetailId])

    const paymentMethodChange = (e: any) => {

        const paymentMethodInit = props.initDate?.account?.paymentMethod;
        setPaymentMethodId(e.target.value)
        if (e.target.value === paymentMethodInit) {
            let formValue: EditType = {};
            if (props.initDate) {
                buildEditAccount(formValue, props.initDate)
                props.form.current?.setFieldsValue({
                    ...formValue
                });
            }
        } else {
            if (e.target.value === '1') {
                let name = props.form.current?.getFieldValue("name");
                if (!name) {
                    name = props.initDate?.userDetail?.name;
                }
                props.form.current?.setFieldsValue({
                    accountName: name,
                    accountBank: '',
                    accountBackCard: ''
                });
            } else {
                props.form.current?.setFieldsValue({
                    accountName: '',
                    accountBank: '',
                    accountBackCard: '',
                    accountIdNumber: '',
                    accountPhone: ''
                });
            }
        }
    };

    const handleBackCardFocus = (e: any) => {

        const fieldName = e.target.id;
        if (!props.initDate?.account) {
            return
        }
        const value = e.target.value;
        if (value.includes('*')) {
            // 清空带有星号的内容
            props.form.current?.setFieldsValue({
                [fieldName]: undefined,
            });
        }
    }

    const handleBackCardBlur = (e: any) => {

        const fieldName = e.target.id;
        if (!props.initDate?.account) {
            return
        }
        const value = e.target.value;
        if (!value) {
            //重置修改状态
            setBackCardChange(false)
            // 如果失去焦点时为空，则回填原来的数据
            props.form.current?.setFieldsValue({
                [fieldName]: props.initDate?.account.backCard,
            })
        }
    }

    const backCardChange = () => {
        setBackCardChange(true)
    }

    const handleIdNumberFocus = (e: any) => {

        const fieldName = e.target.id;
        if (!props.initDate?.account) {
            return
        }
        const value = e.target.value;
        if (value.includes('*')) {
            // 清空带有星号的内容
            props.form.current?.setFieldsValue({
                [fieldName]: undefined,
            });
        }
    }

    const handleIdNumberBlur = (e: any) => {

        const fieldName = e.target.id;
        if (!props.initDate?.account) {
            return
        }
        const value = e.target.value;
        if (!value) {
            //重置修改状态
            setIdNumberChange(false)
            // 如果失去焦点时为空，则回填原来的数据
            props.form.current?.setFieldsValue({
                [fieldName]: props.initDate?.account.idNumber,
            })
        }
    }

    const idNumberChange = () => {
        setIdNumberChange(true)
    }

    return (
        <>
            <Card size="small"
                  title={`账户信息`}
            >
                <Row gutter={40}>
                    <Col span={8}>
                        <FormItem
                            className={styles.mylabel}
                            name="paymentMethod"
                            label="收款方式"
                            labelCol={{span: 6}}
                            wrapperCol={{span: 12}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <Radio.Group onChange={paymentMethodChange}
                                         defaultValue={paymentMethodId}
                            >
                                <Radio key={'1'} value={'1'}>
                                    患者账户
                                </Radio>
                                {/*{props.dictInfo.aier_payment.map(item => (*/}
                                {/*    <>*/}
                                {/*        {((item.code === '3' && advanceMoney) || item.code !== '3') && (*/}
                                {/*            <Radio key={item.code} value={item.code}>*/}
                                {/*                {item.name}*/}
                                {/*            </Radio>*/}
                                {/*        )}*/}
                                {/*    </>*/}
                                {/*))}*/}
                            </Radio.Group>
                        </FormItem>
                    </Col>
                </Row>
                {paymentMethodId == '1' ? (
                    <Row gutter={40}>
                        <Col span={8}>
                            <FormItem
                                className={styles.mylabel}
                                name="accountName"
                                label="收款人姓名"
                                labelCol={{span: 6}}
                                wrapperCol={{span: 12}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Input readOnly variant="borderless"
                                       placeholder="收款人姓名"/>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem
                                className={styles.mylabel}
                                name="accountBank"
                                label="开户行名称"
                                labelCol={{span: 6}}
                                wrapperCol={{span: 12}}
                                rules={[{required: props.required, message: '必填'}]}
                            >
                                <Input placeholder="开户行名称"/>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem
                                className={styles.mylabel}
                                name="accountBackCard"
                                label="银行卡号"
                                labelCol={{span: 6}}
                                wrapperCol={{span: 12}}
                                rules={getBackCardRole(isBackCardChange, props.required)}
                            >
                                <Input
                                    onFocus={handleBackCardFocus}
                                    onBlur={handleBackCardBlur}
                                    onChange={backCardChange}
                                    placeholder="银行卡号"/>
                            </FormItem>
                        </Col>
                    </Row>
                ) : ('')}
                {paymentMethodId == '2' ? (
                    <>
                        <Row gutter={40}>
                            <Col span={8}>
                                <FormItem
                                    className={styles.mylabel}
                                    name="accountName"
                                    label="收款人姓名"
                                    labelCol={{span: 6}}
                                    wrapperCol={{span: 12}}
                                    rules={[{required: true, message: '必填'}]}
                                >
                                    <Input placeholder="收款人姓名"/>
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    className={styles.mylabel}
                                    name="accountIdNumber"
                                    label="身份证号"
                                    labelCol={{span: 6}}
                                    wrapperCol={{span: 12}}
                                    rules={getIdNumberRole(isIdNumberChange)}
                                >
                                    <Input
                                        onFocus={handleIdNumberFocus}
                                        onBlur={handleIdNumberBlur}
                                        onChange={idNumberChange}
                                        placeholder="身份证号"/>
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    className={styles.mylabel}
                                    name="accountPhone"
                                    label="手机号"
                                    labelCol={{span: 6}}
                                    wrapperCol={{span: 12}}
                                    rules={[{
                                        required: true,
                                        message: '必填'
                                    }, PHONE_NUMBER]}
                                >
                                    <Input placeholder="手机号"/>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={40}>
                            <Col span={8}>
                                <FormItem
                                    className={styles.mylabel}
                                    name="accountBank"
                                    label="开户行名称"
                                    labelCol={{span: 6}}
                                    wrapperCol={{span: 12}}
                                    rules={[{required: true, message: '必填'}]}
                                >
                                    <Input placeholder="开户行"/>
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    className={styles.mylabel}
                                    name="accountBackCard"
                                    label="银行卡号"
                                    labelCol={{span: 6}}
                                    wrapperCol={{span: 12}}
                                    rules={getBackCardRole(isBackCardChange, props.required)}
                                >
                                    <Input
                                        onFocus={handleBackCardFocus}
                                        onBlur={handleBackCardBlur}
                                        onChange={backCardChange}
                                        placeholder="银行卡号"/>
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    className={styles.mylabel}
                                    name="accountRelationshipId"
                                    label="收款人与申请人的关系"
                                    labelCol={{span: 6}}
                                    wrapperCol={{span: 12}}
                                    rules={[{required: true, message: '必填'}]}
                                    valuePropName="value"
                                >
                                    <Select
                                        style={{width: 120}}
                                        options={props.dictInfo.aier_relational}
                                    >
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row>
                        {(props.fileEdit || fileNotNull) ? (
                            <Row gutter={40}>
                                <Col span={8}>
                                    <FormItem
                                        className={styles.mylabel}
                                        name="accountApplicant"
                                        valuePropName="fileList"
                                        label="申请人户口本个人登记页"
                                        labelCol={{span: 6}}
                                        wrapperCol={{span: 12}}
                                        rules={[{required: true, message: '必填'}]}
                                    >
                                        {/*牙科不支持家属账户*/}
                                        <FileUploader
                                            multiple
                                            listType="picture"
                                            className="upload-list-inline"
                                            accept='*'
                                            checkSize={false}
                                            noChekckSizeMal={true}
                                            icon={<UploadOutlined/>}
                                            typeCode={MediaCodes.dental_file.code}
                                            onRefresh={MediaUtils.upLoadRefresh}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <FormItem
                                        className={styles.mylabel}
                                        name="accountPayee"
                                        valuePropName="fileList"
                                        label="收款人户口本个人登记页"
                                        labelCol={{span: 6}}
                                        wrapperCol={{span: 12}}
                                        rules={[{required: true, message: '必填'}]}
                                    >
                                        {/*牙科不支持家属账户*/}
                                        <FileUploader
                                            multiple
                                            listType="picture"
                                            className="upload-list-inline"
                                            accept='*'
                                            checkSize={false}
                                            noChekckSizeMal={true}
                                            icon={<UploadOutlined/>}
                                            typeCode={MediaCodes.dental_file.code}
                                            onRefresh={MediaUtils.upLoadRefresh}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <FormItem
                                        className={styles.mylabel}
                                        name="accountSignature"
                                        valuePropName="fileList"
                                        label="申请人签字"
                                        labelCol={{span: 6}}
                                        wrapperCol={{span: 12}}
                                        rules={[{required: true, message: '必填'}]}
                                    >
                                        {/*牙科不支持家属账户*/}
                                        <FileUploader
                                            multiple
                                            listType="picture"
                                            className="upload-list-inline"
                                            accept='*'
                                            checkSize={false}
                                            noChekckSizeMal={true}
                                            icon={<UploadOutlined/>}
                                            typeCode={MediaCodes.dental_file.code}
                                            onRefresh={MediaUtils.upLoadRefresh}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                        ) : (
                            <Row gutter={40}>
                                <Col span={8}>
                                    前往义诊时请携带《申请人户口本个人登记页》和《收款人户口本个人登记页》
                                </Col>
                            </Row>
                        )}
                    </>
                ) : ('')}
            </Card>
        </>
    );
}
