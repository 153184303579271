import {Steps} from 'antd';
import React, {useEffect, useState} from 'react';


type StepsInfoType = {
    dhStatus: number
}
const {Step} = Steps;


const step101 = {code: 101, title: '待分配'};
const step102 = {code: 102, title: '待审核'};
const step103 = {code: 103, title: '审核退回'};
const step104 = {code: 104, title: '待签到'};
const step105 = {code: 105, title: '已签到'};
const step106 = {code: 106, title: '不需要进行手术', status: 'finish'};
const step107 = {code: 107, title: '义诊已完成'};
const step108 = {code: 108, title: '爽约'};
const step109 = {code: 109, title: '待提交审核'};
const step110 = {code: 110, title: '等待审核'};
const step111 = {code: 111, title: '已撤消'};
const step112 = {code: 112, title: '报销审核通过'};
const step113 = {code: 113, title: '报销审核退回', status: 'error'};
const step114 = {code: 114, title: '完成', status: 'finish'};
const step115 = {code: 115, title: '财务报销中'};
const step116 = {code: 116, title: '报销失败', status: 'error'};
const step117 = {code: 117, title: '等待医院申请报销'};
const step118 = {code: 118, title: '报销审核通过'};

const StatusMapping = {
    111: 109,
    117: 109,
    118: 112,

}

const StepsInfo: React.FC<StepsInfoType> = (props) => {

    const [stepsIndex, setStepsIndex] = useState<number>(0);
    const [steps, setSteps] = useState<any[]>([]);
    useEffect(() => {
        // setStepsIndex(StatusObj[props.dhStatus])
        let dhStatus = props.dhStatus;
        let step: any[] = [];
        //状态回滚
        dhStatus = getMapping(dhStatus)
        if (dhStatus === 103) {
            add102(step);
            step.push(step103);
        } else if (dhStatus === 106) {
            add104(step);
            step.push(step105);
            step.push(step106);
        } else if (dhStatus === 108) {
            add104(step);
            step.push(step108);
        } else if (dhStatus === 113) {
            add104(step);
            add105_10(step);
            step.push(step113);
        } else if (dhStatus === 116) {
            add104(step);
            add105_10(step);
            step.push(step112);
            step.push(step115);
            step.push(step116);
        } else {
            add104(step);
            add105_10(step);
            step.push(step112);
            step.push(step115);
            step.push(step114);
        }
        setSteps(step);
        const index = step.findIndex(function (element) {
            return element.code === dhStatus;
        });
        setStepsIndex(index)
    }, [props.dhStatus])

    const add102 = (step: any[]) => {
        step.push(step101);
        step.push(step102);
    }
    const add104 = (step: any[]) => {
        step.push(step101);
        step.push(step102);
        step.push(step104);
    }
    const add105_10 = (step: any[]) => {
        step.push(step105);
        step.push(step107);
        step.push(step109);
        step.push(step110);
    }

    const getMapping = (dhStatus: number) => {
        const mapping = StatusMapping[dhStatus];
        if (mapping) {
            return mapping;
        } else {
            return dhStatus;
        }
    }
    return (
        <>
            {props && (
                <>
                    <Steps current={stepsIndex} size={"default"} labelPlacement={"vertical"}>
                        {steps.map((child: any, index: number) => {
                            return (
                                <Step key={index} title={child.title} status={child.status}/>
                            );
                        })}
                    </Steps>
                </>
            )}
        </>
    )
        ;
};

export default StepsInfo;
