import {message} from 'antd';
import common from './common';

function encodeParams(data = '') {
    let params = '';
    if (typeof data === 'object') {
        for (const key in data) {
            if (data[key] !== undefined) {
                if (Array.isArray(data[key])) {
                    params += `${key}=${data[key].join(',')}&`;
                } else {
                    params += `${key}=${encodeURIComponent(data[key])}&`;
                }
            }
        }
    } else {
        params = data;
    }
    if (params.length > 0 && params.charAt(params.length - 1) === '&') {
        params = params.substring(0, params.length - 1);
    }
    return params;
}

export default class Fetch {
    static remote(url, config, success, failed) {
        const defaultConfig = {
            method: 'GET',
        };
        const newConfig = Object.assign({}, defaultConfig, config);

        fetch(url, newConfig, failed)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(response.status);
            })
            .then((result) => {
                if (result.success) {
                    if (success) {
                        success(result.data);
                    }
                } else {
                    if (result.code == 'U0002') {
                        if (window.location.hash !== '#/') window.location.href = '/';
                    }
                    failed(result);
                    // /api/admin/admin_user/find_user_menu?userOrganizationId=1
                    if (result.code != 'U0001') {
                        throw new Error(result.msg);
                    }
                }
            })
            .catch((error) => {
                if (error.message == 401) {
                    if (window.location.hash !== '#/') window.location.href = '/';
                }
                message.error(error.message);
            });
    }

    static doGetFile(url, config, success, failed) {
        const defaultConfig = {
            method: 'GET',
        };
        const newConfig = Object.assign({}, defaultConfig, config);

        fetch(url, newConfig, failed)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(response.status);
            })
            .then((result) => {
                success(result)
            })
            .catch((error) => {
                message.error(error.message);
            });
    }

    static get(url, config) {
        return new Promise((resolve, reject) => {
            const newConfig = Object.assign(
                {},
                {
                    method: 'GET',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        Authorization: `Bearer ${common.getAccessToken()}`,
                        'SOURCE-REQUEST': 'ADMIN',
                    },
                },
                config
            );
            Fetch.remote(url, newConfig, resolve, reject);
        });
    }

    static getFile(url, config) {
        return new Promise((resolve, reject) => {
            const newConfig = Object.assign(
                {},
                {
                    method: 'GET',
                },
                config
            );
            Fetch.doGetFile(url, newConfig, resolve, reject);
        });
    }

    static post(url, data) {
        let params = encodeParams(data);

        return new Promise((resolve, reject) => {
            const newConfig = {
                method: 'POST',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${common.getAccessToken()}`,
                    'SOURCE-REQUEST': 'ADMIN',
                },
                body: params,
            };
            Fetch.remote(url, newConfig, resolve, reject);
        });
    }

    static put(url, data) {
        let params = encodeParams(data);

        return new Promise((resolve, reject) => {
            const newConfig = Object.assign(
                {},
                {
                    method: 'PUT',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Authorization: `Bearer ${common.getAccessToken()}`,
                        'SOURCE-REQUEST': 'ADMIN',
                    },
                    body: params,
                }
            );
            Fetch.remote(url, newConfig, resolve, reject);
        });
    }

    static del(url, data) {
        let params = encodeParams(data);
        return new Promise((resolve, reject) => {
            const newConfig = {
                method: 'DELETE',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    Authorization: `Bearer ${common.getAccessToken()}`,
                    'SOURCE-REQUEST': 'ADMIN',
                },
            };
            let newUrl = url;
            if (params.length > 0) {
                newUrl = url + '?' + params;
            }
            Fetch.remote(newUrl, newConfig, resolve, reject);
        });
    }

    static postJSON(url, data) {
        return new Promise((resolve, reject) => {
            const newConfig = {
                method: 'POST',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${common.getAccessToken()}`,
                    'SOURCE-REQUEST': 'ADMIN',
                },
                body: JSON.stringify(data),
            };
            Fetch.remote(url, newConfig, resolve, reject);
        });
    }

    static putJSON(url, data) {
        return new Promise((resolve, reject) => {
            const newConfig = {
                method: 'PUT',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${common.getAccessToken()}`,
                    'SOURCE-REQUEST': 'ADMIN',
                },
                body: JSON.stringify(data),
            };
            Fetch.remote(url, newConfig, resolve, reject);
        });
    }

    static postFile(url, data) {
        return new Promise((resolve, reject) => {
            const newConfig = {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${common.getAccessToken()}`,
                    'SOURCE-REQUEST': 'ADMIN',
                },
                body: data,
            };
            Fetch.remote(url, newConfig, resolve, reject);
        });
    }
}
