import React, {Component, useEffect, useState} from 'react';
import 'dayjs/locale/zh-cn';
import {Breadcrumb, Button, Card, Col, Row, Space, Table, Tabs} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import {useParams} from 'react-router-dom';
import App from '../../App';
import {ColumnsType} from 'antd/es/table';
import {DonationUser, User} from './types';
import createModal from '../common/createModal';
import SignLog from './SignLog';
import UserDonation from './UserDonation';
import UserTogetherDonation from './UserTogetherDonation';

const {TabPane} = Tabs;

export function parseRole(roleId: number) {
    switch (roleId) {
        case 5:
            return '爱心网友';
        case 1:
            return '志愿者';
        case 3:
            return '乐龄志愿服务组织';
        case 4:
            return '省级老基会';
        case 2:
            return '国家老基会';
    }
}

export function parseGender(gender: number) {
    switch (gender) {
        case 0:
            return '未知';
        case 1:
            return '男';
        case 2:
            return '女';
    }
}

function UserDetail() {
    const [user, setUser] = useState<any>({});

    const {id} = useParams<{ id: string }>();

    useEffect(
        function () {
            if (!id) {
                return;
            }
            Fetch.get(`${API.user.show}?userId=${id}`).then((data) => {
                setUser(data);
            });
        },
        [id]
    );

    return (
        <>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>
                    <a href="/#/user">用户管理</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>用户详情</Breadcrumb.Item>
            </Breadcrumb>
            <div
                className="site-layout-background"
                style={{padding: 24, minHeight: 720}}
            >
                <h3>昵称：{user.wxNickname}</h3>
                <Card style={{marginBottom: '15px'}}>
                    <Row>
                        <Col span={4}>性别: {parseGender(user.gender)}</Col>
                        <Col span={8}>最近登录时间: {user.loginDate}</Col>
                        <Col span={6}>用户角色: {parseRole(user.roleId)}</Col>
                        <Col span={6}>积分: {user.credits}</Col>
                    </Row>
                </Card>
                <Card>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="参与捐款" key="1">
                            <DonationActivityList userId={Number(id)}/>
                        </TabPane>
                        <TabPane tab="参与活动" key="2">
                            <GongyiActivityList userId={Number(id)}/>
                        </TabPane>
                    </Tabs>
                </Card>
            </div>
        </>
    );
}

const DonationModal = createModal(UserDonation);
const DonationTogetherModal = createModal(UserTogetherDonation);

class DonationActivityList extends Component<{ userId: number }, any> {
    state: any = {
        page: 0,
        pageSize: 10,
        activityId: '',
        totalElements: 0,
        totalPages: 0,
        content: [],
    };
    // 募捐
    private modal: any = null;
    private togetherModal: any = null;

    componentDidMount() {
        this.loadData();
    }

    changeCondition = (condition: any, load = false) => {
        this.setState(
            {
                ...condition,
            },
            () => {
                if (load) {
                    this.loadData();
                }
            }
        );
    };

    loadData = () => {
        const {page, pageSize} = this.state;
        const {userId} = this.props;
        Fetch.get(
            `${API.volunteer.DonationTogether}/${userId}?page=${page}&size=${pageSize}`
        ).then((data) => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                loading: false,
            });
        });
        // Fetch.get(
        //   `${API.donation.activities}?pageNumber=${page}&pageSize=${pageSize}&userId=${userId}`
        // ).then((data) => {
        //   this.setState({
        //     content: data.content,
        //     totalElements: data.totalElements,
        //     totalPages: data.totalPages,
        //   });
        // });
    };

    showModal = (record: any) => {
        this.setState(
            {
                donation: record,
            },
            () => {
                if (record.togetherId) {
                    this.togetherModal.show();
                } else {
                    this.modal.show();
                }
            }
        );
    };

    render() {
        const {page, pageSize, totalElements, content} = this.state;
        const columns: ColumnsType<DonationUser> = [
            {
                title: '捐款活动名称',
                dataIndex: 'title',
                align: `center`,
            },
            {
                title: '活动状态',
                dataIndex: 'activeStatus',
                align: `center`,
            },
            {
                title: '捐款性质',
                dataIndex: 'togetherId',
                align: `center`,
                render: (text: any) => {
                    return text ? '一起捐' : '单次捐款';
                },
            },
            {
                title: '捐款金额',
                dataIndex: 'amount',
                align: `center`,
                render: (text: any, record: any) => {
                    return record.togetherId ? '-' : text;
                },
            },
            {
                title: '发起方',
                dataIndex: 'name',
                align: `center`,
            },
            {
                title: '捐款时间',
                dataIndex: 'createdDate',
                align: `center`,
            },
            {
                title: '获得积分',
                dataIndex: 'credit',
                align: `center`,
            },
            // {
            //   title: '操作',
            //   align: `center`,
            //   render: (text: any, record: any) => {
            //     return (
            //       <Space>
            //         <Button onClick={() => this.showModal(record)}>查看</Button>
            //       </Space>
            //     );
            //   },
            // },
        ];

        return (
            <>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Table
                        bordered
                        rowKey="id"
                        columns={columns}
                        dataSource={content}
                        pagination={{
                            current: page,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination) =>
                            this.changeCondition(
                                {
                                    page: pagination.current,
                                    pageSize: pagination.pageSize,
                                },
                                true
                            )
                        }
                    />
                </div>
                <DonationModal
                    title={'捐款详情'}
                    width={600}
                    donation={this.state.donation}
                    ref={(c) => (this.modal = c)}
                />
                <DonationTogetherModal
                    title={'一起捐详情'}
                    width={600}
                    donation={this.state.donation}
                    ref={(c) => (this.togetherModal = c)}
                />
            </>
        );
    }
}

const FormModal = createModal(SignLog);

class GongyiActivityList extends Component<{ userId: number }, any> {
    state: any = {
        page: 0,
        pageSize: 10,
        activityId: '',
        totalElements: 0,
        totalPages: 0,
        content: [],
    };
    private modal: any = null;

    componentDidMount() {
        this.loadData();
    }

    changeCondition = (condition: any, load = false) => {
        this.setState(
            {
                ...condition,
            },
            () => {
                if (load) {
                    this.loadData();
                }
            }
        );
    };

    loadData = () => {
        const {page, pageSize} = this.state;
        const {userId} = this.props;
        Fetch.get(
            `${API.volunteer.volunteerActive}/${userId}?page=${page}&size=${pageSize}`
        ).then((data) => {
            //  setData(data);
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
            });
        });
        // Fetch.get(
        //   `${API.activityUser.gongyiActivities}?pageNumber=${page}&pageSize=${pageSize}&userId=${userId}`
        // ).then((data) => {
        //   this.setState({
        //     content: data.content,
        //     totalElements: data.totalElements,
        //     totalPages: data.totalPages,
        //   });
        // });
    };

    showSignLog = (activityId: number) => {
        this.setState(
            {
                activityId,
            },
            this.modal.show
        );
    };

    render() {
        const {page, pageSize, totalElements, content} = this.state;
        const columns: ColumnsType<User> = [
            {
                title: '活动名称',
                dataIndex: 'title',
                align: `center`,
            },
            {
                title: '活动状态',
                dataIndex: 'currentStatus',
                align: `center`,
            },
            {
                title: '报名时间',
                dataIndex: 'createdDate',
                align: `center`,
            },
            {
                title: '获得积分',
                dataIndex: 'totalCredits',
                align: `center`,
            },
            {
                title: '操作',
                dataIndex: 'createdDate',
                align: `center`,
                render: (text: any, record: any) => {
                    return (
                        <Space>
                            <Button onClick={() => this.showSignLog(record.activityId)}>
                                签到记录
                            </Button>
                        </Space>
                    );
                },
            },
        ];

        return (
            <>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Table
                        bordered
                        rowKey="id"
                        columns={columns}
                        dataSource={content}
                        pagination={{
                            current: page,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination) =>
                            this.changeCondition(
                                {
                                    page: pagination.current,
                                    pageSize: pagination.pageSize,
                                },
                                true
                            )
                        }
                    />
                </div>
                <FormModal
                    title={'签到记录'}
                    activityId={this.state.activityId}
                    userId={this.props.userId}
                    ref={(c) => (this.modal = c)}
                />
            </>
        );
    }
}

export default function UserDetailIndex() {
    return (
        <App selectedKeys={['user']}>
            <UserDetail/>
        </App>
    );
}
