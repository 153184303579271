import React, {Component} from 'react';
import {Button, Card, Col, Input, message, Row, Space, Spin} from "antd";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";

import App from '../../../App';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import createModal from "../../common/createModal";
import MergeUnitGroupConfirm from "./MergeUnitGroupConfirm";
import FormItem from "antd/lib/form/FormItem";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import stringSimilarity from "string-similarity";

const MergeUnitGroupConfirmResult = createModal(MergeUnitGroupConfirm);

type User = { paperCode: string };
type StateType = {
    tip: "加载中...",
    importLoading: true,
    unitData: [],
    selectUnits: string[],
    units: string[],
    unitName: string
}
type unit = {
    unit: string
    count: number
}

class MergeUnitGroupPageClass extends Component<{}> {

    state: StateType = {
        tip: "加载中...",
        importLoading: true,
        unitData: [],
        selectUnits: [],
        units: [],
        unitName: '',
    }
    private mergeUnitGroupConfirmResult: any = null;

    componentDidMount() {

        this.getUnit();
    }

    getUnit() {

        Fetch.get(API.exam.exam_statistics.find_unit).then((data) => {
            let units: string[] = []
            const newData = data.filter((item: unit) => {
                if (item.unit === '') {
                    // Remove the item
                    return false;
                } else {
                    // Modify the item if necessary
                    if (item.unit.trim() === '') {
                        item.unit = '未填写';
                    }
                    units.push(item.unit);
                    return true;
                }
            });
            this.setState({
                unitData: newData,
                importLoading: false,
                units: units
            }, () => {
            })
        });
    }

    changeCheckbox = (e: any) => {

        let {selectUnits} = this.state;
        const value = e.target.value;
        if (e.target.checked === true) {
            selectUnits.push(value)
            this.setState({
                selectUnits: selectUnits
            })
        } else {
            selectUnits = selectUnits.filter(item => item !== value)
            this.setState({
                selectUnits: selectUnits
            })
        }
    }

    closeModal = () => {
        this.setState({
                importLoading: true,
                unitName: ''
            },
            () => {
            this.getUnit()
            }
        );
    }

    showMergeModel = () => {

        this.mergeUnitGroupConfirmResult.show()
    }

    handleInputChange = (e: any) => {

        this.setState({
            unitName: e.target.value
        })
    };

    checkUnit = () => {
        const {units, unitData, unitName} = this.state
        if (!unitName) {
            message.error("请填写单位名称")
            return;
        }
        const matches = stringSimilarity.findBestMatch(unitName, units).ratings;
        // 设定一个阈值，过滤出相似度高于阈值的结果
        const threshold = 0.2; // 这个值可以根据需要调整
        const filteredMatches = matches.filter(match => match.rating >= threshold);
        // 按相似度从高到低排序
        filteredMatches.sort((a, b) => b.rating - a.rating);
        if (filteredMatches) {
            let select: any[] = [];
            filteredMatches.forEach((item) => {
                const number = units.indexOf(item.target);
                item.target = unitData[number];
                select.push(item)
            })
            this.setState({
                selectUnits: select
            }, () => {
                this.showMergeModel()
            })
        }
    }
    cancel = () => {
        window.history.go(-1);
    }

    goLog = () => {
        window.location.href = '/#/exam/exam_statistics/log';
    }
    render() {
        const {unitData, selectUnits, unitName} = this.state;
        return (
            <>

                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                        <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Card title="合并单位组" size="small">
                            <Space direction="vertical">
                                <Space>
                                    <Input placeholder="单位名称" value={unitName} onChange={this.handleInputChange}/>
                                    <Button
                                        type="primary"
                                        size="middle"
                                        onClick={this.checkUnit}
                                    >
                                        点击搜索
                                    </Button>
                                    <Button
                                        size="middle"
                                        onClick={this.cancel}
                                    >
                                        取 消
                                    </Button>
                                    <Button
                                        type="link"
                                        size="middle"
                                        onClick={this.goLog}
                                    >
                                        操作记录
                                    </Button>
                                </Space>
                                <FormItem
                                    name="viewIds"
                                    label="单位"
                                    rules={[{required: true, message: '必填'}]}
                                >
                                    <Row gutter={[16, 16]}>
                                        {unitData.map((item: unit, index: number) =>
                                            <Col span={4} key={index}>
                                                {item.unit} | 参与人数：{item.count}人
                                            </Col>
                                        )}
                                    </Row>
                                </FormItem>
                            </Space>
                        </Card>
                    </div>
                    </Spin>
                <MergeUnitGroupConfirmResult
                    width={1800}
                    title={'确认单位'}
                    unitDatas={selectUnits}
                    onCancel={this.closeModal}
                    ref={(c) => (this.mergeUnitGroupConfirmResult = c)}
                />
            </>
        );
    }
}

export default function MergeUnitGroupPage() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <MergeUnitGroupPageClass/>
        </App>
    );
}
