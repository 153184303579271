import {Component} from 'react';
import {AutoComplete, Checkbox, Col, DatePicker, Form, Input, Row, Select,} from 'antd';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import FileUploader from '../../common/uploader';
import Editor from '../../common/editor/Editor';
import './temp.css';
import dayjs from "dayjs";
import MediaCodes from "../../common/uploader/MediaCodes";
import MediaUtils from "../../common/uploader/MediaPath";

const FormItem = Form.Item;
const Option = Select.Option;
const {RangePicker} = DatePicker;
export default class Active extends Component<any> {
    state = {
        categoryList: [],
        options: [],
        // projectList: [],
    };

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.getCategorySelect();
    }

    // onSearch = (searchText: String) => {
    //   this.props.onSearch(searchText, '');
    // };
    // 获取项目
    // getSelect = (id: string) => {
    //   Fetch.get(`${API.atl.project.select}?categoryId=${id}`).then((data) => {
    //     this.setState({
    //       projectList: data,
    //     });
    //   });
    // };
    //选项联动
    handleChange = (value: string) => {
        this.props.getSelect(value);
        const {form} = this.props;
        form.setFieldsValue({
            projectId: null,
        });
    };
    // 获取分类
    getCategorySelect = () => {
        Fetch.get(API.atl.category.select).then((data) => {
            this.setState({
                categoryList: data,
            });
        });
    };

    registerDisabledDate = (current: any) => {

        return current <= dayjs().subtract(1, 'days');
    }

    activityDisabledDate = (current: any) => {

        const {form} = this.props;
        const fieldsValue = form.getFieldValue("date1");
        if (fieldsValue && fieldsValue.length === 2) {
            return current <= dayjs(fieldsValue[1]);
        } else {
            return current <= dayjs().subtract(1, 'days');
        }
    }

    render() {
        const {categoryList} = this.state;
        const {disabledFlag, projectList} = this.props;
        //TODO 不确定对不对
        const defaultTime = [dayjs('00:00', 'HH:mm'), dayjs('00:00', 'HH:mm')];

        return (
            <>
                <Row>
                    <Col span={8}>
                        <FormItem
                            name="categoryId"
                            label="所属类别"
                            labelCol={{span: 6}}
                            wrapperCol={{span: 18}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <Select
                                placeholder="请选择分类"
                                onChange={this.handleChange}
                                disabled={disabledFlag}
                            >
                                {categoryList.map((item: any) => {
                                    return (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            name="projectId"
                            label="所属项目"
                            labelCol={{span: 6}}
                            wrapperCol={{span: 18}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <Select placeholder="请选择项目" disabled={disabledFlag}>
                                {projectList.map((item: any) => {
                                    return (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <FormItem
                            name="credits"
                            label="对应积分"
                            rules={[{required: true, message: '必填'}]}
                            labelCol={{span: 6}}
                            wrapperCol={{span: 18}}
                        >
                            <Input placeholder="单位（分）" disabled={disabledFlag}/>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            name="workPoints"
                            label="工分"
                            rules={[{required: true, message: '必填'}]}
                            labelCol={{span: 6}}
                            wrapperCol={{span: 18}}
                        >
                            <Input placeholder="单位（分）" disabled={disabledFlag}/>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            name="duration"
                            label="对应服务时长"
                            rules={[{required: true, message: '必填'}]}
                            labelCol={{span: 8}}
                            wrapperCol={{span: 16}}
                        >
                            <Input placeholder="单位（小时）" disabled={disabledFlag}/>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <FormItem
                            name="minDuration"
                            label="最小服务时长"
                            labelCol={{span: 6}}
                            wrapperCol={{span: 18}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <Input placeholder="单位（小时）" disabled={disabledFlag}/>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <FormItem
                            name="date1"
                            label="报名时间"
                            labelCol={{span: 6}}
                            wrapperCol={{span: 18}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <RangePicker
                                disabled={disabledFlag}
                                showTime={{
                                    format: 'HH:mm',
                                    hideDisabledOptions: true,
                                    defaultValue: defaultTime,
                                }}
                                format="YYYY-MM-DD HH:mm"
                                disabledDate={this.registerDisabledDate}
                                minuteStep={30}
                                inputReadOnly
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            name="date1Flag"
                            valuePropName="checked"
                            wrapperCol={{offset: 1, span: 16}}
                        >
                            <Checkbox disabled={disabledFlag}>
                                允许组织创建活动时修改
                            </Checkbox>
                        </FormItem>
                    </Col>
                </Row>


                <Row>
                    <Col span={8}>
                        <FormItem
                            name="date2"
                            label="活动时间"
                            labelCol={{span: 6}}
                            wrapperCol={{span: 18}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <RangePicker
                                disabled={disabledFlag}
                                showTime={{
                                    format: 'HH:mm',
                                    hideDisabledOptions: true,
                                    defaultValue: defaultTime,
                                }}
                                format="YYYY-MM-DD HH:mm"
                                disabledDate={this.activityDisabledDate}
                                minuteStep={30}
                                inputReadOnly
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            name="date2Flag"
                            valuePropName="checked"
                            wrapperCol={{offset: 1, span: 16}}
                        >
                            <Checkbox disabled={disabledFlag}>
                                允许组织创建活动时修改
                            </Checkbox>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <FormItem
                            name="address"
                            label="活动地址"
                            labelCol={{span: 6}}
                            wrapperCol={{span: 18}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <AutoComplete
                                disabled={disabledFlag}
                                options={this.props.options}
                                onSearch={this.props.onSearch}
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            name="addressFlag"
                            valuePropName="checked"
                            wrapperCol={{offset: 1, span: 16}}
                        >
                            <Checkbox disabled={disabledFlag}>
                                允许组织创建活动时修改
                            </Checkbox>
                        </FormItem>
                    </Col>
                </Row>

                <FormItem
                    name="coverIdsFlag"
                    valuePropName="checked"
                    label="活动封面"
                    labelCol={{span: 2}}
                    wrapperCol={{span: 21}}
                >
                    <Checkbox disabled={disabledFlag}>允许组织创建活动时修改</Checkbox>
                </FormItem>
                <Row>
                    <Col span={2}></Col>
                    <Col span={21}>
                        <FormItem
                            name="covers"
                            valuePropName="fileList"
                            labelCol={{span: 6}}
                            wrapperCol={{span: 18}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            {/*无需刷新*/}
                            <FileUploader
                                disabled={disabledFlag}
                                multiple
                                maxCount={1}
                                listType="picture-card"
                                checkSize={false}
                                desc="支持扩展名：.jpg、.png; 图片大小不能超过2M; 建议尺寸375 * 210; 最多1张"
                                typeCode={MediaCodes.template_pub.code}
                                onRefresh={MediaUtils.upLoadRefresh}
                            />
                        </FormItem>
                    </Col>
                </Row>

                <FormItem
                    name="contentFlag"
                    valuePropName="checked"
                    label="活动介绍"
                    labelCol={{span: 2}}
                    wrapperCol={{span: 21}}
                >
                    <Checkbox disabled={disabledFlag}>允许组织创建活动时修改</Checkbox>
                </FormItem>
                <Row>
                    <Col span={2}></Col>
                    <Col span={21}>
                        <FormItem
                            name="content"
                            rules={[{required: true, message: '必填'}]}
                        >
                            <Editor disabled={disabledFlag} url={API.upload}/>
                        </FormItem>
                    </Col>
                </Row>

                {/* <Row>
          <Col span={8}></Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
        </Row> */}
            </>
        );
    }
}
