import React, {Component} from 'react';
import {Breadcrumb, Button, Input, Select, Space, Table} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import {ColumnsType} from 'antd/es/table';
import {Link} from 'react-router-dom';
import 'dayjs/locale/zh-cn';
import App from '../../App';
import createModal from '../common/createModal';
import ExpressFrom from './expressFrom';
import {TheState} from './types';
import MediaPath from "../common/uploader/MediaPath";

type User = {};

const FormModal = createModal(ExpressFrom);

class OrderList1 extends Component<{}, TheState> {
    state: TheState = {
        page: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        content: [],

        roleId: '',
        loading: true,
        orderId: 0,
        statusArr: {
            1: '待支付',
            2: '待发货',
            3: '待收货',
            4: '已完成',
            5: '关闭',
        },
        status: '',
        code: '',
        productName: '',
        userName: '',
    };
    private modal: any = null;

    componentDidMount() {
        this.loadData();
        // this.modal.onSearch('');
    }

    changeInpt(id: number) {
        this.setState(
            {
                orderId: id,
            },
            () => {
                this.modal.show();
            }
        );
    }

    changeCondition = (condition: any, load = false) => {
        this.setState(
            {
                ...condition,
            },
            () => {
                if (load) {
                    this.loadData();
                }
            }
        );
    };

    loadData = () => {
        const {page, pageSize, status, code, productName, userName} = this.state;
        Fetch.get(
            `${API.goods.expressList}?page=${page}&size=${pageSize}&status=${status}&code=${code}&productName=${productName}&userName=${userName}`
        ).then((data) => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                loading: false,
            });
        });
    };

    reset = () => {
        this.setState(
            {
                status: '',
                code: '',
                productName: '',
                userName: '',
                page: 0,
            },
            this.loadData
        );
    };

    handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            this.loadData();
        }
    };

    render() {
        let that = this;
        const {page, pageSize, totalElements, content, statusArr, orderId} =
            this.state;
        const columns: ColumnsType<User> = [
            {
                title: '订单编号',
                dataIndex: 'code',
                align: `center`,
                render(value: any, record: any, index: any) {
                    return <span style={{wordBreak: 'break-all'}}>{value}</span>;
                },
            },
            {
                title: '商品编号',
                dataIndex: 'productCode',
                align: `center`,
                width: 120,
                render(value: any, record: any, index: any) {
                    return <span style={{wordBreak: 'break-all'}}>{value}</span>;
                },
            },
            {
                title: '商品封面',
                dataIndex: 'coverUrl',
                align: `center`,
                render: (text: any) => {
                    return (
                        <img
                            src={MediaPath.getFileUrl(text)}
                            alt="封面图"
                            style={{width: '50px', height: '30px'}}
                        />
                    );
                },
            },
            {
                title: '商品名称',
                dataIndex: 'name',
                align: `center`,
            },
            {
                title: '商品价值',
                dataIndex: 'worth',
                align: `center`,
                render(value: any) {
                    return value + '元';
                },
            },
            {
                title: '兑换规则',
                dataIndex: 'convertibleDivide',
                align: `center`,
                render(value: any, record: any) {
                    return value + (record.convertibleType == 1 ? '工分' : '积分');
                },
            },
            {
                title: '订单状态',
                dataIndex: 'status',
                align: `center`,
                render: (value: any) => {
                    return statusArr[value];
                },
            },
            {
                title: '下单人',
                dataIndex: 'username',
                width: 80,
                align: `center`,
            },
            {
                title: '下单时间',
                dataIndex: 'createdDate',
                align: `center`,
            },
            {
                title: '物流编号',
                dataIndex: 'expressNumber',
                align: `center`,
                render(value: any, record: any, index: any) {
                    if (record.status == 2)
                        return (
                            <Button
                                type="text"
                                onClick={() => {
                                    that.changeInpt(record.id);
                                }}
                            >
                                填写
                            </Button>
                        );
                    else {
                        return value;
                    }
                },
            },
            {
                title: '操作',
                dataIndex: 'createdDate',
                width: '65px',
                align: `center`,
                render: (text: any, record: any) => {
                    return (
                        <Space>
                            <Link to={`/orderdetail?id=${record.id}`}>详情</Link>
                        </Space>
                    );
                },
            },
        ];

        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>订单管理</Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Space onKeyDown={this.handleKeyDown}>
                        订单编号：
                        <Input
                            placeholder="订单编号"
                            value={this.state.code}
                            onChange={(e) =>
                                this.changeCondition({page: 0, code: e.target.value})
                            }
                        />{' '}
                        商品名称：
                        <Input
                            placeholder="商品名称"
                            value={this.state.productName}
                            onChange={(e) =>
                                this.changeCondition({page: 0, productName: e.target.value})
                            }
                        />
                        下单人：
                        <Input
                            placeholder="请输入"
                            value={this.state.userName}
                            onChange={(e) =>
                                this.changeCondition({page: 0, userName: e.target.value})
                            }
                        />
                        订单状态：
                        <Select
                            value={this.state.status}
                            onChange={(e) => this.changeCondition({status: e, page: 0})}
                            style={{width: '100px'}}
                        >
                            <Select.Option value={1}>待支付</Select.Option>
                            <Select.Option value={2}>待发货</Select.Option>
                            <Select.Option value={3}>待收货</Select.Option>
                            <Select.Option value={4}>已完成</Select.Option>
                            <Select.Option value={5}>关闭</Select.Option>
                        </Select>
                        <Button onClick={this.loadData} type="primary">
                            搜索
                        </Button>
                        <Button onClick={this.reset}>重置</Button>
                    </Space>
                    <Table
                        loading={this.state.loading}
                        className="mgTop15"
                        bordered
                        rowKey="id"
                        columns={columns}
                        dataSource={content}
                        pagination={{
                            current: page + 1,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) =>
                            this.changeCondition(
                                {
                                    page: pagination.current - 1,
                                    pageSize: pagination.pageSize,
                                },
                                true
                            )
                        }
                    />

                    <FormModal
                        ref={(c) => (this.modal = c)}
                        id={orderId}
                        loadData={this.loadData}
                    />
                </div>
            </>
        );
    }
}

export default function OrderList() {
    return (
        <App selectedKeys={['goodsorderlist']} openKeys="PMallMenu">
            <OrderList1/>
        </App>
    );
}
