import React from 'react';
import BraftEditor from 'braft-editor';
import common from '../common';
import MediaPath from "../uploader/MediaPath";
import MediaCodes from "../uploader/MediaCodes";

export default class Editor extends React.Component {
    upload = (param) => {
        const xhr = new XMLHttpRequest();

        const successFn = (response) => {
            // 假设服务端直接返回文件上传后的地址
            // 上传成功后调用param.success并传入上传后的文件地址
            let result = {};
            try {
                result = JSON.parse(xhr.responseText);
            } catch (e) {

            }
            param.success({
                url: MediaPath.getFileUrl(result.data.filePath),
            });
        };

        const progressFn = (event) => {
            // 上传进度发生变化时调用param.progress
            param.progress((event.loaded / event.total) * 100);
        };

        const errorFn = (response) => {
            // 上传发生错误时调用param.error
            param.error({
                msg: 'unable to upload.',
            });
        };

        xhr.upload.addEventListener('progress', progressFn, false);
        xhr.addEventListener('load', successFn, false);
        xhr.addEventListener('error', errorFn, false);
        xhr.addEventListener('abort', errorFn, false);

        const fd = new FormData();
        fd.append('file', param.file);
        fd.append('access_token', common.getAccessToken());
        fd.append('typeCode', MediaCodes.editor.code);
        xhr.open('POST', this.props.url, true);
        xhr.send(fd);
    };

    render() {
        const {
            value: content = null,
            onChange,
            disabled: disabled = false,
        } = this.props;
        const editorState = BraftEditor.createEditorState(content);

        return (
            <BraftEditor
                onChange={(e) => {

                    // if(e.isEmpty()){
                    //     onChange('')
                    // } else {
                    onChange(e);
                    // }
                }}
                disabled={disabled}
                language="zh"
                value={editorState}
                controls={[
                    'font-size',
                    'text-color',
                    'bold',
                    'italic',
                    'underline',
                    'strike-through',
                    'superscript',
                    'subscript',
                    'text-align',
                    'split',
                    'headings',
                    'list_ul',
                    'list_ol',
                    'blockquote',
                    'split',
                    'media',
                    'remove-styles',
                ]}
                media={{
                    uploadFn: this.upload,
                }}
                height={300}
            />
        );
    }
}
