import styles from '../import.module.css';
import {Button, message} from "antd";
import common from "../common";
import MediaPath from "../uploader/MediaPath";

export default function ImportResultModal(props: any) {

    const reason = props.importResult;
    const download = () => {
        if (reason.fileName) {
            common.downloadFile(reason.fileName, MediaPath.getFileUrl(reason.path), (callback: any) => {
                if (callback === true) {
                    message.success('处理完成');
                } else {
                    message.error('下载失败，请联系管理员');
                }
                props.hide();
                props.reload();
            });
        } else {
            window.open(MediaPath.getFileUrl(reason.path))
            ;
            props.hide();
            props.reload();
        }

    }
    const ok = () => {
        props.hide();
        props.reload();
    }
    return <>
        <div className={styles.constant}>
            <p>计划导入：{reason.count}条</p>
            <p>成功导入：{reason.success}条</p>
            <p>导入失败：{reason.fail}条 </p>
            <p>{reason.fail > 0 ? (
                <Button type="link" size="small" className={styles.downloadButton}
                        onClick={download}>下载Excel查看失败原因</Button>
            ) : ('')}</p>
            <Button type="primary" className={styles.ok} onClick={ok}>完成</Button>
        </div>
    </>;
}
