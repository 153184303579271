import React, {Component} from 'react';
import 'dayjs/locale/zh-cn';
import {Button, Col, Input, Row, Space, Table} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import {ColumnsType} from 'antd/es/table';
import {DonationActivity} from './types';
import styles from './donationActivity.module.css';
import {parseRole} from '../user/UserDetail';
import createModal from '../common/createModal';
import UserDonation from '../user/UserDonation';
import UserTogetherDonation from '../user/UserTogetherDonation';

type TheState = {
    page: number;
    pageSize: number;
    totalElements: number;
    totalPages: number;
    content: DonationActivity[];
    name: string;
    donation: DonationActivity | null;
};

const DonationModal = createModal(UserDonation);
const DonationTogetherModal = createModal(UserTogetherDonation);

class DonationList extends Component<{ activityId: string; together?: boolean },
    TheState> {
    state = {
        page: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        content: [],
        name: '',
        donation: null,
    };
    private modal: any = null;
    private togetherModal: any = null;

    componentDidMount() {
        this.loadData();
    }

    changeCondition = (condition: any, load = false) => {
        this.setState(
            {
                ...condition,
            },
            () => {
                if (load) {
                    this.loadData();
                }
            }
        );
    };

    loadData = () => {
        const {page, pageSize, name} = this.state;
        Fetch.get(
            `${API.donation.list}?page=${page}&size=${pageSize}&name=${name}&activityId=${this.props.activityId}&together=${this.props.together}`
        ).then((data) => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
            });
        });
    };

    exportExcel = () => {
        window.location.href = `${API.donation.export}?activityId=${this.props.activityId}&together=${this.props.together}`;
    };

    reset = () => {
        this.setState(
            {
                name: '',
                page: 0,
            },
            this.loadData
        );
    };

    showModal = (record: any) => {
        this.setState(
            {
                donation: record,
            },
            () => {
                if (record.togetherId) {
                    this.togetherModal.show();
                } else {
                    this.modal.show();
                }
            }
        );
    };

    render() {
        const {page, pageSize, totalElements, content} = this.state;
        const columns: ColumnsType<DonationActivity> = [
            {
                title: '微信昵称',
                dataIndex: 'wxNickname',
                align: `center`,
            },
            {
                title: '捐款方式',
                dataIndex: 'togetherId',
                align: `center`,
                render: (text: any) => {
                    return text ? '一起捐' : '单笔捐款';
                },
            },
            {
                title: '捐款时间',
                dataIndex: 'createdDate',
                align: `center`,
            },
            {
                title: '捐款金额',
                dataIndex: 'amount',
                align: `center`,
            },
            {
                title: '角色',
                dataIndex: 'roleId',
                align: `center`,
                render: (text: any) => {
                    return parseRole(Number(text));
                },
            },
            {
                title: '操作',
                align: `center`,
                render: (text: any, record: any) => {
                    return (
                        <Space>
                            <Button onClick={() => this.showModal(record)}>查看</Button>
                        </Space>
                    );
                },
            },
        ];

        return (
            <>
                <div className="site-layout-background">
                    <div>
                        <Row>
                            <Col span={8}>
                                <Space>
                                    <Button
                                        onClick={this.exportExcel}
                                        className={styles.addBtn}
                                        type="primary"
                                    >
                                        导出捐款列表
                                    </Button>
                                    <div>
                                        共{this.state.totalElements}人次参与
                                        {this.props.together ? '一起捐' : '单笔捐款'}
                                    </div>
                                </Space>
                            </Col>
                            <Col span={16} style={{textAlign: 'right'}}>
                                <Space>
                                    <Input
                                        placeholder="请输入昵称"
                                        value={this.state.name}
                                        onChange={(e) =>
                                            this.changeCondition({page: 0, name: e.target.value})
                                        }
                                    />
                                    <Button onClick={this.loadData}>搜索</Button>
                                    <Button onClick={this.reset}>重置</Button>
                                </Space>
                            </Col>
                        </Row>
                    </div>
                    <Table
                        bordered
                        rowKey={(record: any) => {
                            return record.id;
                        }}
                        columns={columns}
                        dataSource={content}
                        pagination={{
                            current: page + 1,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) =>
                            this.changeCondition(
                                {
                                    page: pagination.current - 1,
                                    pageSize: pagination.pageSize,
                                },
                                true
                            )
                        }
                    />
                </div>
                <DonationModal
                    title={'捐款详情'}
                    width={600}
                    donation={this.state.donation}
                    ref={(c) => (this.modal = c)}
                />
                <DonationTogetherModal
                    title={'一起捐详情'}
                    width={600}
                    donation={this.state.donation}
                    ref={(c) => (this.togetherModal = c)}
                />
            </>
        );
    }
}

export default DonationList;
