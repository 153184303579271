import {Card, Descriptions, Flex, Popover, Space} from 'antd';
import React from 'react';
import styles from "../add/ServiceUser.module.css";
import {eyeTypeArrShow} from "../util/AierCommon";
import {DetailClass} from "../util/ServiceUserDetailTypes"
import ViewFileListClass from "../../common/modal/ViewFileList";
import AierServiceUserIllnessLog from "./AierServiceUserIllnessLog";
import {QuestionCircleOutlined} from "@ant-design/icons";

export type HospitalFeeInfoType = {

    data: DetailClass['data'] | null,
    onRefresh: (type: string) => Promise<any>;
}

const HospitalFeeInfo: React.FC<HospitalFeeInfoType> = (props) => {

    const {data} = props;
    return (
        <>
            {data && data.fee != null && data.fee.timeOfAdmission != '' ? (
                <Card size="small" className={styles.mycard}
                      title={<>
                          费用信息
                          {/*{data.user.identityPassesType === 3 && (*/}
                          {/*    <span style={{*/}
                          {/*        color: "red",*/}
                          {/*        border: "1px solid",*/}
                          {/*        marginLeft: "10px"*/}
                          {/*    }}>无需事务部审核自动通过</span>*/}
                          {/*)}*/}
                      </>}
                      extra={data.illness?.length > 1 ? `联合手术` : ''}
                >
                    <Descriptions>
                        <Descriptions.Item label={
                            <>
                                <Flex gap="small">
                                    眼病
                                    <Popover placement="left" title={data.user.name + "的眼病记录"}
                                             content={<AierServiceUserIllnessLog quota={data.quota}/>}>
                                        <QuestionCircleOutlined
                                            style={{fontSize: '18px', color: '#1677ff'}}/>
                                    </Popover>
                                </Flex>
                            </>
                        }>
                            {data.fee.illnessName?.replaceAll(',', ' ')}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions>
                        <Descriptions.Item label="入院时间">
                            {data.fee.timeOfAdmission}
                        </Descriptions.Item>
                        {data.fee.timeOfDischarge ? (
                            <Descriptions.Item label="出院时间">
                                {data.fee.timeOfDischarge}
                            </Descriptions.Item>
                        ) : (
                            <Descriptions.Item label="出院时间">
                                未填写
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                    <Descriptions>
                        <Descriptions.Item label="总金额">
                            {data.fee.totalPrice}
                        </Descriptions.Item>
                        <Descriptions.Item label="医保报销">
                            {data.fee.medicareReimbursement}
                        </Descriptions.Item>
                        <Descriptions.Item label="报销前自付费用">
                            {data.fee.selfPayPrice}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions>
                        <Descriptions.Item label="其他补助">
                            {data.fee.otherGrants}
                        </Descriptions.Item>
                        <Descriptions.Item label="本项目资助">
                            {data.fee.subsidy}
                        </Descriptions.Item>
                        <Descriptions.Item label="报销后自付费用">
                            {data.fee.afterSelfPayPrice}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions>
                        {data.fee.cataractEyeType && (
                            <>
                                <Descriptions.Item label="白内障眼别">
                                    {eyeTypeArrShow[data.fee.cataractEyeType]}
                                </Descriptions.Item>
                            </>
                        )}
                        {data.fee.fundusEyeType && (
                            <>
                                <Descriptions.Item label="眼底病眼别">
                                    {eyeTypeArrShow[data.fee.fundusEyeType]}
                                </Descriptions.Item>
                            </>
                        )}
                        {data.fee.gilliumEyeType && (
                            <>
                                <Descriptions.Item label="翼状胬肉眼别">
                                    {eyeTypeArrShow[data.fee.gilliumEyeType]}
                                </Descriptions.Item>
                            </>
                        )}
                    </Descriptions>
                    <Descriptions>
                        <Descriptions.Item label="备注">
                            {data.fee.remark}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions>
                        <Descriptions.Item>
                            眼底病：
                            <br/>
                            1:视网膜激光光凝术
                            <br/>
                            2:玻璃体视网膜手术，含：玻璃体切除术、玻璃体穿刺注液（药）术、硅油取出术、后巩膜加固术、视网膜脱离（外路）
                        </Descriptions.Item>
                    </Descriptions>
                    <Space size={[8, 16]} wrap align="start">
                        <Card style={{width: '100%'}}>
                            {/*已刷新*/}
                            <ViewFileListClass
                                title="诊断报告"
                                fileList={data.fee.diagnosticReports}
                                onRefresh={props.onRefresh}
                            />
                        </Card>
                        <Card style={{width: '100%'}}>
                            {/*已刷新*/}
                            <ViewFileListClass
                                title="手术/救治同意书"
                                fileList={data.fee.consentForms}
                                onRefresh={props.onRefresh}
                            />
                        </Card>
                        <Card style={{width: '100%'}}>
                            {/*已刷新*/}
                            <ViewFileListClass
                                title="住院记录"
                                fileList={data.fee.hospitalizationRecords}
                                onRefresh={props.onRefresh}
                            />
                        </Card>
                        {data.fee.invoices.length > 0 ? (
                            <Card style={{width: '100%'}}>
                                {/*已刷新*/}
                                <ViewFileListClass
                                    title="发票"
                                    fileList={data.fee.invoices}
                                    onRefresh={props.onRefresh}
                                />
                            </Card>
                        ) : ('')}
                        {data.fee.identityPromise.length > 0 ? (
                            <Card style={{width: '100%'}}>
                                {/*已刷新*/}
                                <ViewFileListClass
                                    title="身份承诺书"
                                    fileList={data.fee.identityPromise}
                                    onRefresh={props.onRefresh}
                                />
                            </Card>
                        ) : ('')}
                        {data.fee.proofProve.length > 0 ? (
                            <Card style={{width: '100%'}}>
                                {/*已刷新*/}
                                <ViewFileListClass
                                    title="困难退役军人证明"
                                    fileList={data.fee.proofProve}
                                    onRefresh={props.onRefresh}
                                />
                            </Card>
                        ) : ('')}
                        {data.fee.preferentialTreatmentCard.length > 0 ? (
                            <Card style={{width: '100%'}}>
                                {/*已刷新*/}
                                <ViewFileListClass
                                    title="退役军人优待证"
                                    fileList={data.fee.preferentialTreatmentCard}
                                    onRefresh={props.onRefresh}
                                />
                            </Card>
                        ) : ('')}
                    </Space>
                </Card>
            ) : ('')}
        </>
    );
};

export default HospitalFeeInfo;
