import React, {useState} from 'react';
import styles from "../add/ServiceUser.module.css";
import {Button, message, Modal, Select, Space, Spin} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import {DescEyeDiseaseSimple, eyeTypeArr} from "../util/AierCommon";
import common from "../../common/common";
import MediaPath from "../../common/uploader/MediaPath";

type SearchData = {
    page: number | 0,
    eyeType?: number | undefined,
    illnessType?: string | undefined,
    bEyeType?: number | undefined,
    fundusEyeType?: number | undefined,
    gilliumEyeType?: number | undefined,
    eyeDisease?: number | undefined
}

const defSearch = {
    page: 0,
    eyeType: undefined,
    illnessType: undefined,
    bEyeType: undefined,
    fundusEyeType: undefined,
    gilliumEyeType: undefined,
    eyeDisease: undefined
}
//B白内障 F眼底病 G翼状胬肉
const EyeTypeMap = {
    1: {B: false, F: false, G: true},
    2: {B: false, F: true, G: true},
    3: {B: true, F: false, G: true},
    4: {B: true, F: true, G: false},
    5: {B: false, F: false, G: true},
    6: {B: false, F: true, G: true},
    7: {B: true, F: false, G: true},
    8: {B: true, F: true, G: false},
}

const defEyeTypeMap = {B: true, F: true, G: true}

/**
 * 账户信息不一致弹窗
 *
 * @param props
 * @constructor
 */
const {confirm} = Modal;
export default function AierDownloadInformedParams(props: any) {

    const [searchData, setSearchData] = useState<SearchData>(defSearch);
    //选择患者
    //眼别下拉筛选框
    const [selectStatus, setSelectStatus] = useState<{ B: boolean, F: boolean, G: boolean }>(defEyeTypeMap);
    const [tip, setTip] = useState<string>("加载中...");
    const [importLoading, setImportLoading] = useState<boolean>(false);
    const cancel = () => {
        props.hide();
    }

    const continueSubmit = () => {

        if (!props.serviceId) {
            message.error("请选择患者")
            return
        }
        if (!searchData.eyeDisease) {
            message.error("请选择眼病")
            return
        }
        const eyeDisease = searchData.eyeDisease;
        if (eyeDisease === 1) {
            if (!searchData.bEyeType || !searchData.fundusEyeType) {
                message.error("请选择眼别")
                return;
            }
        } else if (eyeDisease === 2) {
            if (!searchData.bEyeType) {
                message.error("请选择眼别")
                return;
            }
        } else if (eyeDisease === 3) {
            if (!searchData.fundusEyeType) {
                message.error("请选择眼别")
                return;
            }
        } else if (eyeDisease === 4) {
            if (!searchData.gilliumEyeType) {
                message.error("请选择眼别")
                return;
            }
        }
        const params = {
            serviceId: props.serviceId,
            eyeDisease: searchData.eyeDisease,
            cataractEyeType: searchData.bEyeType,
            fundusEyeType: searchData.fundusEyeType,
            gilliumEyeType: searchData.gilliumEyeType
        }
        confirm({
            title: `是否确认下载文件`,
            okText: `确认`,
            onOk() {
                setImportLoading(true)
                setTip("文件下载中...")
                Fetch.postJSON(API.cooperateServiceUsersStatusV2.download_informed_consent, params).then((data) => {
                    common.downloadFile2(data.fileName, MediaPath.getFileUrl(data.url))
                        .then(() => {
                            setImportLoading(false)
                            message.success("下载完成")
                        })
                        .catch(() => {
                            setImportLoading(false)
                            message.error("部分文件下载失败，请联系管理员。")
                        });
                }).catch(() => {
                    message.error("生成文件失败")
                    setImportLoading(false)
                });
            },
        });
    }
    const changeCondition = (searchDataTemp: SearchData) => {

        if (searchDataTemp.gilliumEyeType || searchDataTemp.eyeDisease ||
            searchDataTemp.fundusEyeType || searchDataTemp.bEyeType) {
        }
        const search = {
            ...searchData,
            ...searchDataTemp
        }
        setSearchData(search)
    }
    const changeEyeDisease = (e: any) => {

        const selectStatus = EyeTypeMap[e];
        const obj = {
            page: 0, eyeDisease: e
        }
        setSelectStatus(selectStatus)
        setSearchData(obj)
    }

    return <>
        <Spin tip={tip} spinning={importLoading}>
            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                <>
                    <Space>
                        <Select
                            showSearch
                            placeholder="眼病"
                            optionFilterProp="children"
                            onChange={(e) => {
                                changeEyeDisease(e)
                            }}
                            value={searchData.eyeDisease}
                            filterOption={(input: any, option: any) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            style={{width: 200}}
                            options={DescEyeDiseaseSimple}
                        />
                        <Select
                            showSearch
                            placeholder="白内障眼别"
                            optionFilterProp="children"
                            onChange={(e) => {
                                changeCondition({page: 0, bEyeType: e})
                            }}
                            value={searchData.bEyeType}
                            filterOption={(input: any, option: any) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            style={{width: 120}}
                            options={eyeTypeArr}
                            disabled={selectStatus.B}
                        />
                        <Select
                            showSearch
                            placeholder="眼底病眼别"
                            optionFilterProp="children"
                            onChange={(e) => {
                                changeCondition({page: 0, fundusEyeType: e})
                            }}
                            value={searchData.fundusEyeType}
                            filterOption={(input: any, option: any) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            style={{width: 120}}
                            options={eyeTypeArr}
                            disabled={selectStatus.F}
                        />
                        <Select
                            showSearch
                            placeholder="翼状胬肉眼别"
                            optionFilterProp="children"
                            onChange={(e) => {
                                changeCondition({page: 0, gilliumEyeType: e})
                            }}
                            value={searchData.gilliumEyeType}
                            filterOption={(input: any, option: any) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            style={{width: 120}}
                            options={eyeTypeArr}
                            disabled={selectStatus.G}
                        />
                    </Space>
                    <Space>
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={continueSubmit}
                            className={styles.postSave}
                        >
                            下载
                        </Button>
                        <Button
                            onClick={cancel}
                        >
                            取消
                        </Button>
                    </Space>
                </>
            </Space>
        </Spin>
    </>;
}