import React, {Component} from 'react';
import {Breadcrumb, Col, Modal, Row,} from 'antd';
import {Link} from 'react-router-dom';
import App from '../../App';
import styles from './operationalActivity.module.css';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import common from '../common/common';
import FileUploader from '../common/uploader/FileUploader';
import MediaPath from "../common/uploader/MediaPath";

const {confirm} = Modal;

class DemoDetailClass extends Component {

    state = {
        id: common.getQuery('id'),
        data: {},
        qrCode: ""
    };
    refd = React.createRef<FileUploader>();

    componentDidMount() {
        this.getData()
    };

    getData() {
        const {id} = this.state;
        Fetch.get(`${API.operationalActivity.operationalActivitiesGetDetail}?id=${id}`).then((data) => {
            this.setState({
                data: data,
                qrCode: data.qrCode
            });
        });
    }

    render() {
        const {data, qrCode} = this.state;
        type User = {};
        return (
            <div>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>
                        <Link to="/operationalActivity/list">推广活动</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>活动详情</Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Row>
                        <Col span={12}>
                            <div className={styles.detailDetail}>
                                编码：{data['code']}
                            </div>
                            <div className={styles.detailDetail}>
                                名称：{data['name']}
                            </div>
                            <div className={styles.detailDetail}>
                                按钮文案：{data['buttonName']}
                            </div>
                            <div className={styles.detailDetail}>
                                跳转链接：{data['bootstrapPage']}
                            </div>
                            <div className={styles.detailDetail}>
                                老用户提示 ：{data['oldUsersEnter'] == true ? '提示' : '不提示'}
                            </div>
                            <div className={styles.detailDetail}>
                                赠送积分：{data['givePoints']}
                            </div>
                            <div className={styles.detailDetail}>
                                赠送工分：{data['giveWorkPoints']}
                            </div>
                            <div className={styles.detailDetail}>
                                发放描述：{data['giveDesc']}
                            </div>
                            <div className={styles.detailDetail}>
                                微信二维码：
                                <img
                                    src={MediaPath.getFileUrl(qrCode)}
                                    style={{width: '150px', height: '150px'}}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default function OperationalActivityDetail() {
    return (
        <App selectedKeys={['operationalActivity']}>
            <DemoDetailClass></DemoDetailClass>
        </App>
    );
}
