import {message} from "antd";
import DhServiceUserParentClass from "../patientDetail/DhServiceUserParent";
import {DhDefaultRefreshFile} from "../common/DhDetailType";
import {defDictData} from "../common/DhDictData";
import {decryptData} from "../../common/crypto";
import common from "../../common/common";
import {v4 as uuidV4} from "uuid";
import dayjs from "dayjs";
import {AddDhPatientApplication} from "./AddDhPatientApplication";


class AddEditCommon<S = {}> extends DhServiceUserParentClass {

    protected dhPatientBookingModelResult: any = null;

    state: AddDhPatientApplication = {
        id: 0,
        data: {},
        taskId: decryptData(common.getQuery('taskId')),
        hospitalSelect: [],
        addressSelect: [],
        areaStr: '',
        saveResult: {
            id: 0,
            dhStatus: 0
        },
        taskData: {createdDate: '', registerType: 0, servicePhone: '', serviceName: ''},
        cancelLoading: false,
        subLoading: false,
        token: uuidV4(),
        paymentMethodId: '1',
        advanceMoney: false,
        dictInfo: defDictData,
        isWarVeteran: '1',
        loading: true,
        btnQ: {},
        startTime: dayjs().valueOf(),
        refreshData: DhDefaultRefreshFile(),
        refreshCount: 0,
        spinning: true,
        snap: '',
        bId: 0,
        booking: false,
    }

    setAddressSelect = (select: any) => {
        this.setState({
            addressSelect: select
        })
    }

    closeModal = () => {

    }

    onSaveSuccess = () => {

        window.history.go(-1);
    }

    openBookingModel = () => {

        this.dhPatientBookingModelResult.show()
    }

    getLat = (values: any) => {

        const {addressSelect} = this.state;
        const {locationAddr} = values;
        let lat = 0,
            lng = 0;
        if (addressSelect && locationAddr) {
            const address: any = addressSelect.find((item: {
                value: string | ''
            }) => {
                return item.value === locationAddr;
            });
            if (address && address.location) {
                lat = address.location.lat;
                lng = address.location.lng;
            } else {
                message.error('您输入的地址不正确，请通过下拉列表选择输入');
                return false;
            }
            return {
                lng: lng,
                lat: lat,
            };
        } else {
            return {
                lng: 116.397504,
                lat: 39.908821,
            };
        }
    };
}


export default AddEditCommon