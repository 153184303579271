import React, {Component} from 'react';
import {AutoComplete, Button, Form, Input, message, Select,} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import Api from '../common/api';
import styles from './institution.module.css';
import ZoneSelect from './ZoneSelect';
import FileUploader from '../common/uploader';
import MediaCodes from "../common/uploader/MediaCodes";
import MediaPath from "../common/uploader/MediaPath";

const FormItem = Form.Item;

class InstitutionForm extends Component {
    formRef = React.createRef();
    cityRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            provinceId: '',
            cityId: '',
            category: props.institution ? props.institution.category : '',
            treeNodes: null,
            industries: [],
            institutionCategory: '',
            options: [],
            optionsData: [],
            locationChange: false,
            region: '', //城市名称
        };
    }

    submit = (values) => {
        this.filter(values);
        if (values.id) {
            this.update(values);
        } else {
            this.create(values);
        }
    };

    componentDidMount() {
        const id = this.props.institution ? this.props.institution.id : '';
        Fetch.get(`${API.institution.tree}?notId=${id}`).then((treeNodes) => {
            this.setState({
                treeNodes,
            });
        });
        this.loadIndustries();
        this.loadInstitution();
        if (id) {
            this.setCitystr(this.props.institution.city);
            this.refreshFile()
        }
    }

    refreshFile = () => {
        const id = this.props.institution ? this.props.institution.id : '';
        Fetch.get(`${API.institution.admin_refresh_file}?institutionId=${id}`).then((data) => {
            const newInstitution = {};
            newInstitution.legalPersonIdcardUrl = this.buildFileObj(data.legalPersonIdCardUrls);
            newInstitution.businessLicenseUrl = this.buildFileObj(data.businessLicenseUrls);
            newInstitution.bankAccountUrl = this.buildFileObj(data.bankAccountUrls);
            this.formRef.current.setFieldsValue({
                ...newInstitution
            })
        });
    }

    buildFileObj = (fileList) => {
        if (!fileList) {
            return []
        }
        return fileList.map((item, index) => {
            const url = item.indexOf('http') !== -1 ? item : MediaPath.getFileUrl(item);
            return {
                id: index, uid: index, status: 'done', name: item, url,
                filePath: url,
                mediaId: index
            };
        });
    }
    loadInstitution() {
        Fetch.get(`${API.institution.zsgc}`).then((institution) => {
            let options;
            if (institution.category === 'COUNTRY') {
                options = [
                    {
                        name: '省级老基会',
                        value: 'PROVINCE',
                    },
                    {
                        name: '乐龄之家组织',
                        value: 'YUELING_ORG',
                    },
                    {
                        name: '乐龄下属组织',
                        value: 'YUELING_SUB_ORG',
                    },
                ];
            } else if (institution.category === 'PROVINCE') {
                options = [
                    {
                        name: '乐龄之家组织',
                        value: 'YUELING_ORG',
                    },
                    {
                        name: '乐龄下属组织',
                        value: 'YUELING_SUB_ORG',
                    },
                ];
            } else {
                options = [
                    {
                        name: '乐龄下属组织',
                        value: 'YUELING_SUB_ORG',
                    },
                ];
            }
            this.setState({
                options,
                institutionCategory: institution.category,
            });
        });
    }

    loadIndustries = () => {
        Fetch.get(`${API.dict.list}?type=industry`).then((industries) => {
            this.setState({
                industries,
            });
        });
    };

    create = (values) => {
        let obj = this.getlat(values);
        if (obj) {
            values = {
                ...values,
                ...obj,
            };
        } else {
            return;
        }

        Fetch.postJSON(`${API.institution.zsgc}`, values).then(() => {
            message.success('提交成功');
            this.props.hide();
            this.props.reload();
        });
    };

    update = (values) => {
        let obj = this.getlat(values);
        if (obj) {
            values = {
                ...values,
                ...obj,
            };
        } else {
            return;
        }
        Fetch.putJSON(`${API.institution.zsgc}/${values.id}`, values).then(() => {
            message.success('保存成功');
            this.props.hide();
            this.props.reload();
        });
    };
    getlat = (values) => {
        const {optionsData, locationChange} = this.state;
        let lat = 0,
            lng = 0;
        const address = optionsData.find((item) => {
            return item.value === values.address.detail;
        });
        if (address) {
            lat = address.location.lat;
            lng = address.location.lng;
        } else if (!values.id || locationChange) {
            message.error('您输入的地址不正确，请通过下拉列表选择输入');
            return false;
        } else {
            lat = this.props.institution.lat;
            lng = this.props.institution.lng;
        }
        return {
            lng: lng,
            lat: lat,
        };
    };
    filter = (values) => {
        values.address = {
            provinceId: values.provinceId,
            cityId: values.cityId,
            detail: values.address,
        };
        if (values.category === 'PROVINCE') {
            values.pid = 1;
        }
        values.legalPersonIdcardUrl = this.buildUrl(values.legalPersonIdcardUrl);
        values.businessLicenseUrl = this.buildUrl(values.businessLicenseUrl);
        values.bankAccountUrl = this.buildUrl(values.bankAccountUrl);
        if (values.type) {
            const items = [];
            for (let i = 0; i < values.type.length; i++) {
                items.push(values.type[i]);
            }
            values.type = items.join(',');
        }
    };

    buildUrl = (urls) => {

        if (urls) {
            const items = [];
            for (let i = 0; i < urls.length; i++) {
                let url = urls[i].url;
                url = MediaPath.splitUrlDomain(url);
                items.push(url);
            }
            return items.join(',');
        }
    }


    // filterTreeData = () => {
    //     const treeNodes = this.state.treeNodes;
    //     if (!treeNodes || treeNodes.length === 0) {
    //         return [];
    //     }
    //     if (this.state.category === 'YUELING_ORG') {
    //         if (this.state.institutionCategory === 'COUNTRY') {
    //             const items = treeNodes[0].children;
    //             return items.map((item) => {
    //                 return {
    //                     ...item,
    //                     children: [],
    //                 };
    //             });
    //         }
    //         return [
    //             {
    //                 ...treeNodes[0],
    //                 children: [],
    //             },
    //         ];
    //     }
    //     if (this.state.category === 'YUELING_SUB_ORG') {
    //         if (this.state.institutionCategory === '') {
    //             return [];
    //         }
    //         if (
    //             this.state.institutionCategory === 'YUELING_ORG' ||
    //             this.state.institutionCategory === 'YUELING_SUB_ORG'
    //         ) {
    //             return treeNodes;
    //         }
    //         if (this.state.institutionCategory === 'COUNTRY') {
    //             const provinces = treeNodes[0].children;
    //             const yueling = [];
    //             provinces.forEach((item) => {
    //                 if (item.children && item.children.length > 0) {
    //                     yueling.push.apply(yueling, item.children); //合并数组
    //                 }
    //             });
    //             return yueling;
    //         }
    //         return treeNodes[0].children || [];
    //     }
    //     return [];
    // };
    setCitystr = (val) => {

        this.setState({
            region: val,
        });
    };
    // 搜索地址
    onSearch = (searchText) => {

        if (!this.state.region && this.state.region === '') {
            message.error('请先选择省、市');
            this.formRef.current.setFieldsValue({
                address: '',
            });
            return;
        }
        let text = this.state.region;
        const rep = Api.zoneRep[text];
        text = rep ? rep : text;
        if (searchText && searchText.trim().length > 0) {
            Fetch.get(
                `${API.location2}?keyword=${searchText.trim()}&region=${
                    text
                }`
            ).then((data) => {
                const result = JSON.parse(data);
                if (result.status === 0) {
                    const options = result.data.map((item) => {
                        return {
                            label: item.title + ' ' + item.address,
                            value: item.title + ' ' + item.address,
                            location: item.location,
                        };
                    });
                    this.setState({
                        locationChange: true,
                        optionsData: options,
                    });
                }
            });
        }
    };

    render() {
        return (
            <div className={styles.container}>
                <Form
                    initialValues={{
                        ...this.props.institution,
                        pid: this.props.institution ? this.props.institution.pid + '' : '',
                    }}
                    onFinish={this.submit}
                    ref={this.formRef}
                >
                    <FormItem hidden noStyle name="id">
                        <Input/>
                    </FormItem>
                    <FormItem hidden noStyle name="userId">
                        <Input/>
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="name"
                        label="组织名称"
                        labelCol={{span: 7}}
                        wrapperCol={{span: 17}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <Input placeholder="请输入组织名称"/>
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="provinceId"
                        label={<span className={styles.star}>所属省份</span>}
                        labelCol={{span: 7}}
                        wrapperCol={{span: 17}}
                    >
                        <ZoneSelect
                            ref={this.cityRef}
                            setCitystr={this.setCitystr}
                            form={this.formRef}
                        />
                    </FormItem>

                    {/* <FormItem
            className={styles.mylabel}
            name="address"
            label="地址"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            rules={[{ max: 200, message: '最多200个字符', type: 'string' }]}
          >
            <Input placeholder="请输入地址"  />
          </FormItem> */}
                    <FormItem
                        name="address"
                        label="组织地址"
                        labelCol={{span: 7}}
                        wrapperCol={{span: 17}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <AutoComplete
                            options={this.state.optionsData}
                            onSearch={this.onSearch}
                        />
                    </FormItem>

                    {/* <FormItem
            className={styles.mylabel}
            name="category"
            label="组织性质"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            rules={[{ required: true, message: '必填' }]}
          >
            <Select onChange={this.changeCategory} placeholder="请选择">
              {this.state.options.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem> */}
                    <FormItem
                        className={styles.mylabel}
                        name="type"
                        label="组织类型"
                        labelCol={{span: 7}}
                        wrapperCol={{span: 17}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <Select mode="multiple" placeholder="请选择">
                            <Select.Option value="1">乐龄志愿服务组织</Select.Option>
                            <Select.Option value="2">敬老志愿服务组织</Select.Option>
                        </Select>
                    </FormItem>
                    {/* {(this.state.category === 'YUELING_ORG' ||
            this.state.category === 'YUELING_SUB_ORG') && (
            <FormItem
              className={styles.mylabel}
              name="pid"
              label="上级组织"
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 17 }}
              rules={[{ required: true, message: '必填' }]}
            >
              <TreeSelect treeData={this.filterTreeData()} />
            </FormItem>
          )} */}
                    <FormItem
                        className={styles.mylabel}
                        name="industryCode"
                        label="所属行业"
                        labelCol={{span: 7}}
                        wrapperCol={{span: 17}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <Select placeholder="请选择">
                            {this.state.industries.map((industry) => (
                                <Select.Option key={industry.id} value={industry.code}>
                                    {industry.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="username"
                        label="负责人"
                        labelCol={{span: 7}}
                        wrapperCol={{span: 17}}
                        rules={[
                            {required: true, message: '必填'},
                            () => ({
                                validator(_, value) {

                                    let val = value.replace(/^\s+|\s+$/gm, '');
                                    if (val !== '') {
                                        return Promise.resolve();
                                    }
                                    if (value) {
                                        return Promise.reject(new Error('负责人姓名不能为空'));
                                    } else {
                                        return Promise.reject(new Error(' '));
                                    }
                                },
                            }),
                        ]}

                        // x.replace(/^\s+|\s+$/gm,'');
                    >
                        <Input placeholder="请输入负责人"/>
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="phone"
                        label="负责人联系电话"
                        labelCol={{span: 7}}
                        wrapperCol={{span: 17}}
                        rules={[
                            {required: true, message: '必填'},
                            () => ({
                                validator(_, value) {

                                    let reg = /^[1][0-9]{10}$/;
                                    if (reg.test(value)) {
                                        return Promise.resolve();
                                    }
                                    if (value) {
                                        return Promise.reject(new Error('请输入正确的手机号'));
                                    } else {
                                        return Promise.reject(new Error(' '));
                                    }
                                },
                            }),
                        ]}
                    >
                        <Input
                            type="number"
                            placeholder="负责人联系电话"

                        />
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="financeUsername"
                        label="财务负责人"
                        labelCol={{span: 7}}
                        wrapperCol={{span: 17}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <Input
                            placeholder="请输入财务负责人"
                        />
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="financePhone"
                        label="财务负责人联系电话"
                        type="number"
                        labelCol={{span: 7}}
                        wrapperCol={{span: 17}}
                        rules={[{required: true, message: '必填'}]}
                    >
                        <Input
                            placeholder="请输入财务负责人联系电话"
                        />
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="legalPersonIdcardUrl"
                        valuePropName="fileList"
                        label="法人身份证复印件"
                        labelCol={{span: 7}}
                        wrapperCol={{span: 17}}
                        // rules={[{required: true, message: '必填'}]}
                    >
                        {/*无需刷新*/}
                        <FileUploader
                            multiple
                            maxCount={2}
                            listType="picture-card"
                            checkSize={false}
                            desc="支持扩展名：.jpg、.png; 图片大小不能超过2M; 最多正反2张"
                            typeCode={MediaCodes.institution.code}
                        />
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="businessLicenseUrl"
                        valuePropName="fileList"
                        label="营业执照扫描件盖公章"
                        labelCol={{span: 7}}
                        wrapperCol={{span: 17}}
                    >
                        {/*无需刷新*/}
                        <FileUploader
                            listType="picture-card"
                            checkSize={false}
                            maxCount={1}
                            desc="支持扩展名：.jpg、.png; 图片大小不能超过2M; "
                            typeCode={MediaCodes.institution.code}
                        />
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="bankAccountUrl"
                        valuePropName="fileList"
                        label="银行开户许可证扫描件（加盖公章）"
                        labelCol={{span: 7}}
                        wrapperCol={{span: 17}}
                        // rules={[{ required: true, message: '必填' }]}
                    >
                        {/*无需刷新*/}
                        <FileUploader
                            listType="picture-card"
                            maxCount={1}
                            checkSize={false}
                            desc="支持扩展名：.jpg、.png; 图片大小不能超过2M;"
                            typeCode={MediaCodes.institution.code}
                        />
                    </FormItem>
                    <FormItem
                        className={styles.mylabel}
                        name="description"
                        label="组织简介"
                        labelCol={{span: 7}}
                        wrapperCol={{span: 17}}
                        rules={[{max: 2000, message: '最多2000个字符', type: 'string'}]}
                    >
                        <Input.TextArea rows={5}/>
                    </FormItem>
                    <FormItem wrapperCol={{span: 12, offset: 6}} className="center">
                        <Button type="primary" htmlType="submit" className={styles.mybtn}>
                            提交
                        </Button>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

export default InstitutionForm;
