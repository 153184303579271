import React from 'react';
import {
    Button,
    Card,
    Col,
    Descriptions,
    Form,
    FormInstance,
    Input,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Space
} from 'antd';
import App from '../../../App';
import styles from './ServiceUserV2.module.css';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import {DefaultRefreshFile, DetailClass} from '../util/ServiceUserDetailTypes';
import UserInfo from "../detail/UserInfo";
import ScreeningInfo from "../detail/ScreeningInfo";
import FormItem from 'antd/lib/form/FormItem';
import FileUploader from "../../common/uploader";
import {buildAierData, defaultData, refreshFile} from "../util/AierCommon";
import {UploadOutlined} from '@ant-design/icons';
import IllnessInfo from "../detail/IllnessInfo";
import {decryptData} from "../../common/crypto";
import HospitalFeeInfo from "../detail/HospitalFeeInfo";
import WarVeteranInfo from "../detail/WarVeteranInfo";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import MediaCodes from '../../common/uploader/MediaCodes';
import ServiceUserDetailParentClass from "../detail/ServiceUserDetailParent";
import dayjs from "dayjs";

const {confirm} = Modal;

class EditPatientAccountClass extends ServiceUserDetailParentClass<DetailClass> {

    formRef = React.createRef<FormInstance>();
    initPaymentMethodId = '0';
    state: DetailClass = {
        id: decryptData(common.getQuery('id')),
        snap: defaultData.snap,
        data: defaultData.data,
        hospitalSelect: [],
        startTime: dayjs().valueOf(),
        refreshData: DefaultRefreshFile(),
        refreshCount: 0,
        spinning: true,
    };

    componentDidMount() {
        this.getData()
    };

    getDictData() {
        Fetch.get(`${API.dict.find_by_types}?types=aier_payment,aier_relational`).then((data) => {

            let updatedData = {...this.state.data};
            updatedData.dictInfo.aier_payment = data['aier_payment'];
            const aier_relational = data['aier_relational'];
            let result: { value: string, label: number }[] = []
            for (let i = 0; i < aier_relational.length; i++) {
                const item = aier_relational[i];
                result.push({value: item.code, label: item.name})
            }
            // @ts-ignore
            updatedData.dictInfo.aier_relational = result;
            this.buildDefaultValue(updatedData)
            this.setState({
                data: updatedData
            }, () => {
                if (this.state.data.account && this.formRef.current !== null) {
                    this.formRef.current.setFieldsValue({
                        ...this.state.data.account,
                    })
                }
            })
        });
    }

    buildDefaultValue = (updatedData: DetailClass["data"]) => {

        if (updatedData.account.paymentMethod) {
            updatedData.account.paymentMethodId = this.getPaymentMethodId(updatedData.account.paymentMethod,
                updatedData.dictInfo.aier_payment);
            this.initPaymentMethodId = updatedData.account.paymentMethodId
        }
        if (updatedData.account.relationship) {
            updatedData.account.relationshipId = this.getRelationshipId(updatedData.account.relationship,
                updatedData.dictInfo.aier_relational) + '';
        }
    }

    getPaymentMethodId = (key: string, dict: [{ name: string, code: string }]) => {

        let result = '0';
        for (let i = 0; i < dict.length; i++) {
            const item = dict[i];
            if (item.name === key) {
                result = item.code;
                break
            }
        }
        return result;
    }

    getRelationshipId = (key: string, dict: [{ value: number, label: string }]) => {

        let result = 0;
        for (let i = 0; i < dict.length; i++) {
            const item = dict[i];
            if (item.label === key) {
                result = item.value;
                break
            }
        }
        return result;
    }

    getData() {
        const {id} = this.state;
        Fetch.get(`${API.cooperateServiceUsers.get_edit_account_detail}?id=${id}`).then((data) => {
            buildAierData(data)
            const refreshData = refreshFile(data.files);
            // Step 1: 获取当前的状态对象
            const currentState = this.state.data;
            // Step 2: 创建一个新的对象用于存储更新后的属性
            const updatedObject = {...currentState};
            // Step 3: 针对要更新的属性进行检查，有值就更新，没有值就保持不变
            for (const prop in data) {
                if (data[prop] !== undefined && data[prop] !== null) {
                    updatedObject[prop] = data[prop];
                }
            }
            // Step 4: 使用this.setState将新的对象设置为组件的新状态
            this.setState({data: updatedObject,refreshData: refreshData}, () => {
                this.getDictData();
            });
        });
    }

    cancel = () => {
        window.history.go(-1);
    }
    submit = (values: any) => {

        const that = this;
        confirm({
            title: `是否保存患者账户信息？`,
            okText: `提交`,
            onOk() {
                that.buildFile(values);
                that.cleanField(values);
                values.serviceId = that.state.id;
                values.paymentMethod = that.state.data.account.paymentMethodId;
                values.relationship = values.relationshipId;
                that.doAdminExitAccount(values);
            }
        });
    }

    doAdminExitAccount = (values: any) => {

        Fetch.postJSON(API.cooperate_user_account.do_admin_exit_account, values).then(() => {
            message.success('提交成功');
            window.history.go(-1);
        });
    };


    cleanField = (values: any) => {
        delete values.applicant;
        delete values.payee;
        delete values.signature;
    }

    buildFile = (values: any) => {
        if (this.state.data.account.paymentMethodId == '2') {
            const files: any[] = [];
            this.addFiles(files, values.applicant, 9);
            this.addFiles(files, values.payee, 10);
            this.addFiles(files, values.signature, 11);
            values.fileListTemp = files;
        }
    }

    addFiles = (files: any[], data: any, type: number) => {
        data.forEach((item: { id: any; }) => {
            files.push({
                type: type,
                fileId: item.id
            })
        })
    }

    paymentMethodChange = (e: any) => {

        const updatedData = {...this.state.data};
        updatedData.account.paymentMethodId = e.target.value;
        this.setState({
            data: updatedData
        })
        if (e.target.value == this.initPaymentMethodId) {
            this.formRef.current?.setFieldsValue({
                ...this.state.data.account,
            })
        } else {
            const rName = e.target.value === "1" ? this.state.data.user.name : undefined;
            this.formRef.current?.setFieldsValue({
                ...defaultData.data.account,
                name: rName
            })
        }
    }

    render() {
        const {data, id, snap} = this.state;
        // @ts-ignore
        return (
            <div>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                        <UserInfo data={data}/>
                        <WarVeteranInfo data={data}/>
                        <IllnessInfo data={data}/>
                        <ScreeningInfo data={data}/>
                        <Card size="small" className={styles.mycard}
                              title={`银行账户`}>
                            <>
                                <Form
                                    onFinish={this.submit}
                                    ref={this.formRef}
                                >
                                    <Descriptions>
                                        <FormItem
                                            className={styles.mylabel}
                                            name="paymentMethod"
                                            label="收款方式"
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            <Radio.Group onChange={this.paymentMethodChange}
                                                         value={data.account.paymentMethodId}>
                                                {data.dictInfo.aier_payment.map(item => (
                                                    <>
                                                        {((item.code === '3' && data.advanceMoney === true) || item.code !== '3') && (
                                                            <Radio key={item.code} value={item.code}>
                                                                {item.name}
                                                            </Radio>
                                                        )}
                                                    </>
                                                ))}
                                            </Radio.Group>
                                        </FormItem>
                                    </Descriptions>
                                    {data.account.paymentMethodId == '1' ? (
                                        <Row gutter={40}>
                                            <Col span={8}>
                                                <FormItem
                                                    className={styles.mylabel}
                                                    name="name"
                                                    label="收款人姓名"
                                                    wrapperCol={{span: 15}}
                                                    rules={[{required: true, message: '必填'}]}
                                                >
                                                    <Input readOnly variant="borderless"
                                                           placeholder="收款人姓名"/>
                                                </FormItem>
                                            </Col>
                                            <Col span={8}>
                                                <FormItem
                                                    className={styles.mylabel}
                                                    name="accountBank"
                                                    label="开户行名称"
                                                    wrapperCol={{span: 15}}
                                                    rules={[{required: true, message: '必填'}]}
                                                >
                                                    <Input placeholder="开户行名称"/>
                                                </FormItem>
                                            </Col>
                                            <Col span={8}>
                                                <FormItem
                                                    className={styles.mylabel}
                                                    name="backCard"
                                                    label="银行卡号"
                                                    wrapperCol={{span: 15}}
                                                    rules={[{required: true, message: '必填'},
                                                        {
                                                            pattern: /^([1-9]{1})(\d{11}|\d{15}|\d{16}|\d{17}|\d{18})$/,
                                                            message: "银行卡号格式错误"
                                                        }]}
                                                >
                                                    <Input placeholder="银行卡号"/>
                                                </FormItem>
                                            </Col>
                                        </Row>
                                    ) : ('')}
                                    {data.account.paymentMethodId == '2' ? (
                                        <>
                                            <Row gutter={40}>
                                                <Col span={8}>
                                                    <FormItem
                                                        className={styles.mylabel}
                                                        name="name"
                                                        label="收款人姓名"
                                                        wrapperCol={{span: 15}}
                                                        rules={[{required: true, message: '必填'}]}
                                                    >
                                                        <Input placeholder="收款人姓名"/>
                                                    </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                    <FormItem
                                                        className={styles.mylabel}
                                                        name="idNumber"
                                                        label="身份证号"
                                                        wrapperCol={{span: 15}}
                                                        rules={[{
                                                            required: true,
                                                            message: '必填'
                                                        }, {
                                                            pattern: /(^\d{15}$)|(^\d{17}([0-9]|X|x)$)/,
                                                            message: "身份证号格式错误"
                                                        }]}
                                                    >
                                                        <Input placeholder="身份证号"/>
                                                    </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                    <FormItem
                                                        className={styles.mylabel}
                                                        name="phone"
                                                        label="手机号"
                                                        wrapperCol={{span: 15}}
                                                        rules={[{
                                                            required: true,
                                                            message: '必填'
                                                        }, {pattern: /^1\d{10}$/, message: "手机号格式错误"}]}
                                                    >
                                                        <Input placeholder="手机号"/>
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={40}>
                                                <Col span={8}>
                                                    <FormItem
                                                        className={styles.mylabel}
                                                        name="backCard"
                                                        label="银行卡号"
                                                        wrapperCol={{span: 15}}
                                                        rules={[{required: true, message: '必填'},
                                                            {
                                                                pattern: /^([1-9]{1})(\d{11}|\d{15}|\d{16}|\d{17}|\d{18})$/,
                                                                message: "银行卡号格式错误"
                                                            }]}
                                                    >
                                                        <Input placeholder="银行卡号"/>
                                                    </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                    <FormItem
                                                        className={styles.mylabel}
                                                        name="accountBank"
                                                        label="开户行"
                                                        wrapperCol={{span: 15}}
                                                        rules={[{required: true, message: '必填'}]}
                                                    >
                                                        <Input placeholder="开户行"/>
                                                    </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                    <FormItem
                                                        className={styles.mylabel}
                                                        name="relationshipId"
                                                        label="收款人与申请人的关系"
                                                        wrapperCol={{span: 15}}
                                                        rules={[{required: true, message: '必填'}]}
                                                        valuePropName="value"
                                                    >
                                                        <Select
                                                            style={{width: 120}}
                                                            options={data.dictInfo.aier_relational}
                                                        >
                                                        </Select>
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={40}>
                                                <Col span={8}>
                                                    <FormItem
                                                        className={styles.mylabel}
                                                        name="applicant"
                                                        valuePropName="fileList"
                                                        label="申请人户口本个人登记页"
                                                        wrapperCol={{span: 15}}
                                                        rules={[{required: true, message: '必填'}]}
                                                    >
                                                        {/*已刷新*/}
                                                        <FileUploader
                                                            multiple
                                                            listType="picture"
                                                            className="upload-list-inline"
                                                            accept='*'
                                                            checkSize={false}
                                                            noChekckSizeMal={true}
                                                            icon={<UploadOutlined/>}
                                                            typeCode={MediaCodes.aier_file.code}
                                                            onRefresh={this.refreshFileOne}
                                                        />
                                                    </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                    <FormItem
                                                        className={styles.mylabel}
                                                        name="payee"
                                                        valuePropName="fileList"
                                                        label="收款人户口本个人登记页"
                                                        wrapperCol={{span: 15}}
                                                        rules={[{required: true, message: '必填'}]}
                                                    >
                                                        {/*已刷新*/}
                                                        <FileUploader
                                                            multiple
                                                            listType="picture"
                                                            className="upload-list-inline"
                                                            accept='*'
                                                            checkSize={false}
                                                            noChekckSizeMal={true}
                                                            icon={<UploadOutlined/>}
                                                            typeCode={MediaCodes.aier_file.code}
                                                            onRefresh={this.refreshFileOne}
                                                        />
                                                    </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                    <FormItem
                                                        className={styles.mylabel}
                                                        name="signature"
                                                        valuePropName="fileList"
                                                        label="申请人签字"
                                                        wrapperCol={{span: 15}}
                                                        rules={[{required: true, message: '必填'}]}
                                                    >
                                                        {/*已刷新*/}
                                                        <FileUploader
                                                            multiple
                                                            listType="picture"
                                                            className="upload-list-inline"
                                                            accept='*'
                                                            checkSize={false}
                                                            noChekckSizeMal={true}
                                                            icon={<UploadOutlined/>}
                                                            typeCode={MediaCodes.aier_file.code}
                                                            onRefresh={this.refreshFileOne}
                                                        />
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                        </>
                                    ) : ('')}
                                    <Space>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            size="large"
                                            className={styles.postSave}
                                        >
                                            保存
                                        </Button>
                                        <Button
                                            size='large'
                                            className={styles.backBtn}
                                            style={{marginRight: '15px'}}
                                            onClick={this.cancel}
                                        >
                                            取消
                                        </Button>
                                    </Space>
                                </Form>
                            </>
                            {/*)}*/}
                        </Card>
                        <HospitalFeeInfo data={data} onRefresh={this.refreshFile}/>
                    </Space>
                </div>
            </div>
        );
    }
}

export default function EditPatientAccount() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <EditPatientAccountClass/>
        </App>
    );
}
