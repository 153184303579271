import React, {useEffect, useState} from 'react';
import 'dayjs/locale/zh-cn';
import {Breadcrumb, Card, Col, Descriptions, Row, Steps} from 'antd';

import styles from './project.module.css';
import {Project} from './types';
import {useParams} from 'react-router-dom';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import App from '../../App';
import {getCurrentStep, getMenu, isFinal,} from '../gongyiActivity/GongyiActivityDetail';
import createModal from '../common/createModal';
import ReviewForm from '../gongyiActivity/ReviewForm';
import {ReasonType} from '../gongyiActivity/types';
import ReviewItem from '../gongyiActivity/ReviewItem';
import MediaPath from "../common/uploader/MediaPath";

const ReviewFormModal = createModal(ReviewForm);
const {Step} = Steps;

function ProjectDetail() {
    const [project, setProject] = useState<Project>();
    const params = useParams<{ id: string }>();

    const loadData = () => {
        Fetch.get(`${API.project.zsgc}?id=${params.id}`).then((data) => {
            setProject(data);
        });
    };

    const [reason, setReason] = useState<ReasonType>();
    const loadReason = () => {
        Fetch.get(`${API.reason.zsgc}?activityId=${params.id}&category=2`).then(
            (data) => {
                setReason(data);
            }
        );
    };

    const [review, setReview] = useState<any>();
    const loadReview = () => {
        Fetch.get(`${API.review.zsgc}?activityId=${params.id}&category=2`).then(
            (data) => {
                setReview(data);
            }
        );
    };

    useEffect(
        function () {
            loadData();
            loadReason();
            loadReview();
        },
        [params.id]
    );

    function renderStatus(status: string) {
        if (status === 'DRAFT') {
            return '草稿';
        } else if (status === 'PUBLISHED') {
            return '已发布';
        } else if (status === 'DISABLED') {
            return '已下架';
        }
    }

    let modal: any = null;

    if (!project) {
        return null;
    }

    const down = () => {
        const result = window.confirm('是否确定下架？');
        if (result) {
            Fetch.put(`${API.project.status}?id=${params.id}&status=DISABLED`).then(
                () => {
                    loadData();
                }
            );
        }
    };
    const up = () => {
        Fetch.put(`${API.project.online}?id=${params.id}&status=PUBLISHED`).then(
            () => {
                loadData();
            }
        );
    };

    const isReviewing = () => {
        return (
            project.reviewStatus === 'PROVINCE' ||
            project.reviewStatus === 'FINAL' ||
            project.reviewStatus === 'PROVINCE_REJECTED' ||
            project.reviewStatus === 'FINAL_REJECTED'
        );
    };
    return (
        <>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>
                    <a href="/#/project">公益资讯</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>公益资讯详情</Breadcrumb.Item>
            </Breadcrumb>
            <div
                className="site-layout-background"
                style={{padding: 24, minHeight: 720}}
            >
                <Card
                    title="封面图"
                    extra={getMenu(
                        params.id,
                        project.reviewStatus,
                        () => {
                            modal.show();
                        },
                        down,
                        reason,
                        project.status,
                        up,
                        'PROJECT',
                        project.offlineRole
                    )}
                >
                    <Row>
                        {project.covers && project.covers.length === 0 && <span>暂无</span>}
                        {project.covers &&
                            project.covers.map((file: any) => {
                                return (
                                    <Col key={file.id} span={8}>
                                        <div>
                                            <img src={MediaPath.getFileUrl(file.url)} width={100}/>
                                        </div>
                                    </Col>
                                );
                            })}
                    </Row>
                </Card>
                {isReviewing() && (
                    <Card title="审核流程" style={{marginTop: '10px'}}>
                        <Steps progressDot current={getCurrentStep(project.reviewStatus)}>
                            <Step
                                title={
                                    //@ts-ignore
                                    <ReviewItem
                                        category="PROJECT"
                                        activity={project}
                                        title="省级初审"
                                        review={review}
                                        current={0}
                                        institution={project.institution}
                                        submitDate={project.submitDate}
                                    />
                                }
                            />
                            <Step
                                title={
                                    //@ts-ignore
                                    <ReviewItem
                                        category="PROJECT"
                                        activity={project}
                                        title="老基会终审"
                                        review={review}
                                        current={1}
                                        institution="国家老基会"
                                        submitDate={project.submitDate}
                                    />
                                }
                            />
                            <Step title="发布成功"/>
                        </Steps>
                    </Card>
                )}
                <Card className={styles.mycard}>
                    <Descriptions>
                        <Descriptions.Item span={3} label="发布者">
                            {project.publisher}
                        </Descriptions.Item>
                        <Descriptions.Item span={3} label="资讯状态">
                            {renderStatus(project.status)}
                        </Descriptions.Item>
                        <Descriptions.Item span={3} label="发布时间">
                            {project.publishDate ? project.publishDate : ' - '}
                        </Descriptions.Item>
                        <Descriptions.Item span={3} label="内容">
                            <div dangerouslySetInnerHTML={{__html: project.content}}/>
                        </Descriptions.Item>
                    </Descriptions>
                </Card>
                <ReviewFormModal
                    title="审核"
                    width={600}
                    ref={(c) => (modal = c)}
                    isFinal={isFinal(project.reviewStatus)}
                    reload={loadData}
                    activityId={params.id}
                    institution={project.institution}
                    firstReviewDate={project.validateDate}
                    category="PROJECT"
                />
            </div>
        </>
    );
}

export default function ProjectDetailIndex() {
    return (
        <App selectedKeys={['project']} openKeys="sub1">
            <ProjectDetail/>
        </App>
    );
}
