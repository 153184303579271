import {Card, Descriptions} from 'antd';
import React, {useEffect, useState} from 'react';
import {DhDetailDataType} from "../common/DhDetailType";
import {BookingStatus, BookingTime} from "../common/DhDictData";

type BookingInfoType = {
    bookingVos: DhDetailDataType["data"]["bookingVos"]
}


const BookingInfo: React.FC<BookingInfoType> = (props) => {

    const [bookingVosLength, setBookingVosLength] = useState<number>(0);
    useEffect(() => {
        if (props.bookingVos) {
            setBookingVosLength(props.bookingVos.length)
        }
    }, [props.bookingVos])
    return (
        <>
            {(props.bookingVos && props.bookingVos.length > 0) && (
                <Card size="small"
                      title={`预约信息`}
                >
                    {props.bookingVos.map((item, index) => (

                        <Card key={index}
                              size="small"
                                  title={'第' + (bookingVosLength - index) + '次预约'}>

                                <Descriptions>
                                    <Descriptions.Item label="编码">
                                        {item.code}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="预约时间">
                                        {item.bookingDate}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="上午&下午">
                                        {BookingTime[item.bookingTime]}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="预约序号">
                                        {item.number}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="预约状态">
                                        {BookingStatus[item.bookingStatus]}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Card>
                    ))}
                </Card>
            )}
        </>
    );
};

export default BookingInfo;
