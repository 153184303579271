import React from 'react';
import {Button, message, Modal, Space, Spin, Table} from 'antd';
import API from '../../../common/api';
import {ColumnsType} from 'antd/es/table';
import {Link} from 'react-router-dom';
import 'dayjs/locale/zh-cn';
import App from '../../../../App';
import encryptData, {decryptData} from '../../../common/crypto';
import BaseListPage from "../../../common/listpage/BaseListPage";
import Fetch from "../../../common/FetchIt";
import {BillBatchDetailType} from "../page/PageTypeTypes";
import {billBatchDetail, financialPageData} from "../page/PageCommon";
import common from "../../../common/common";
import styles from "../../reimbursedbatch/ReimbursedBatch.module.css";
import BreadcrumbInfo from "../../../common/breadcrumb/BreadcrumbInfo";
import {getKey} from "../../../common/breadcrumb/BreadcrumbUtil";
import MediaPath from "../../../common/uploader/MediaPath";


type list = {
    billAmount: number | 0
    billId: number | 0
    billMonth: string | ''
    code: string | ''
    hospitalName: string | ''
    receiveAccount: string | ''
    receiveAccountBack: string | ''
    receiveName: string | ''
};
const {confirm} = Modal;

class FinancialBillBatchDetailListClass extends BaseListPage {

    state: BillBatchDetailType = {
        ...billBatchDetail,
        searchData: {
            batchId: decryptData(common.getQuery('batchId')),
            page: 0
        }
    };

    componentDidMount() {

        super.componentDidMount();
    }

    loadData = () => {
        const {pageSize, searchData} = this.state;
        let url = `${API.service_user_bill_batch.detail_list}?page=${searchData.page}&size=${pageSize}&batchId=${searchData.batchId}`;
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
            });
        })
    };


    closeModal = () => {
        this.setState({
                importLoading: false,
            }, () => {
                window.location.reload()
            }
        );
    }

    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
    };

    reset = () => {
        super.reset(financialPageData.searchData);
    };

    downloadTotal = () => {

        let that = this;
        const {searchData} = this.state
        confirm({
            title: `是否确认导出？`,
            onOk() {
                that.setState({
                    importLoading: true,
                    tip: "数据导出中"
                });
                let url = `${API.service_user_bill_batch.download_excel}?batchId=${searchData.batchId}`;
                Fetch.get(url).then((data) => {
                    if (!data) {
                        message.error('下载失败，请联系管理员');
                        that.setState({
                            importLoading: false,
                        });
                        return
                    }
                    common.downloadFile(data.code + ".xls", MediaPath.getFileUrl(data.path), (callback: any) => {
                        if (callback === true) {
                            message.success('处理完成');
                        } else {
                            message.error('下载失败，请联系管理员');
                        }
                        that.setState({
                            importLoading: false,
                        });
                    });
                }).catch((e) => {
                    that.setState({
                        importLoading: false,
                    });
                });
            },
        });
    }

    render() {

        const {
            pageSize, totalElements,
            content, btnQ, searchData
        } = this.state;
        const columns: ColumnsType<list> = [

            {
                title: '医院名称',
                dataIndex: 'hospitalName',
                align: `center`,
                width: 60,
                render(value: any) {
                    return <span style={{wordBreak: 'break-all'}}>{value}</span>;
                },
            },
            {
                title: '账单月',
                dataIndex: 'billMonth',
                align: `center`,
                width: 60,
                render: (text: any) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '收款名称',
                dataIndex: 'receiveName',
                align: `center`,
                width: 80,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '收款账户开户行',
                dataIndex: 'receiveAccountBack',
                align: `center`,
                width: 50,
                render(value: any, record: any, index: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '收款账户',
                dataIndex: 'receiveAccount',
                align: `center`,
                width: 50,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '账单金额',
                dataIndex: 'billAmount',
                align: `center`,
                width: 50,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '操作',
                align: `center`,
                dataIndex: 'status',
                width: 50,
                render: (text: any, record: any) => {
                    return (
                        <Space>
                            <Link to={`/serviceUserBill/detail?billId=${encryptData(record.billId + "")}`}>
                                详情
                            </Link>
                        </Space>
                    );
                }
                ,
            },
        ];

        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    {/*<Breadcrumb style={{margin: '16px 0'}}>*/}
                    {/*    <Breadcrumb.Item>报销名单Ⅱ</Breadcrumb.Item>*/}
                    {/*    <Breadcrumb.Item>导出批次</Breadcrumb.Item>*/}
                    {/*    <Breadcrumb.Item>批次详情</Breadcrumb.Item>*/}
                    {/*</Breadcrumb>*/}
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space direction={'vertical'} style={{width: '100%'}}>
                            <Button className={styles.downloadBtn} onClick={() => this.downloadTotal()}>
                                下载未报销名单
                            </Button>
                            <Table
                                className="mgTop15"
                                bordered
                                rowKey="id"
                                columns={columns}
                                dataSource={content}
                                pagination={{
                                    current: searchData.page + 1,
                                    pageSize,
                                    total: totalElements,
                                    showTotal: (total) => `共${total}条`,
                                    showSizeChanger: false,
                                }}
                                onChange={(pagination: any) =>
                                    this.pageChange(
                                        {
                                            page: pagination.current - 1
                                        }
                                    )
                                }
                            />
                        </Space>
                    </div>
                </Spin>
            </>
        );
    }

}

export default function FinancialBillBatchDetailList() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <FinancialBillBatchDetailListClass/>
        </App>
    );
}
