import React, {Component} from 'react';
import {Breadcrumb, Button, Space, Table} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {Link} from 'react-router-dom';
import styles from './supplier.module.css';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import common from '../common/common';
import App from '../../App';
import MediaPath from "../common/uploader/MediaPath";

class OrderDetail1 extends Component {
    state = {
        data: {
            addressVo: {},
            orderVo: {},
            productVo: {},
        },
        status: {
            status1: '待支付',
            status2: '待发货',
            status3: '待收货',
            status4: '已完成',
            status5: '关闭',
        },
        orderStatus: '',
        expressData: [],
        id: common.getQuery('id'),
        expressText: '',
    };

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        // orderdetail;
        const {id} = this.state;
        Fetch.get(`${API.goods.orderdetail}/${id}`).then((data) => {
            this.setState({data});
        });
    };
    getExpressData = () => {
        // orderdetail;
        const {id} = this.state;
        Fetch.get(`${API.goods.expressdetail}/${id}`).then((data) => {
            // expressData;
            // {"result":false,"returnCode":"500","message":"查询无结果，请隔段时间再查"}

            let datas = JSON.parse(data);
            if (datas.data) {
                this.setState({expressData: datas.data});
            } else {
                this.setState({
                    expressText: datas.message,
                });
            }
        });
    };

    render() {
        const {data, expressData, status, expressText} = this.state;
        const columns: ColumnsType<any> = [
            {
                title: '封面图',
                dataIndex: 'coverUrl',
                align: `center`,
                render: (text: any) => {
                    return (
                        <img
                            src={MediaPath.getFileUrl(text)}
                            alt="封面图"
                            style={{width: '50px', height: '30px'}}
                        />
                    );
                },
            },
            {
                title: '商品名称',
                dataIndex: 'name',
                align: `center`,
            },
            {
                title: '兑换规则',
                dataIndex: 'convertibleDivide',
                align: `center`,
                render(value: any, record: any | {}, index: any) {
                    let str = record.convertibleType == 1 ? '工分' : '积分';
                    return value + str;
                },
            },
            {
                title: '商品价值',
                dataIndex: 'worth',
                align: `center`,
            },
            {
                title: '供应商',
                dataIndex: 'supplierName',
                align: `center`,
            },
            {
                title: '商品编号',
                dataIndex: 'code',
                align: `center`,
            },
        ];
        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>
                        <Link to="goodsorderlist">订单管理</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>订单详情</Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                    id="goodsview"
                >
                    <div className={styles.detailTitle}>订单详情</div>
                    <div className={styles.detailDetail}>
                        <Space>
                            <div>订单状态：{status['status' + data.orderVo['status']]}</div>
                            <div>
                                订单总额：{data.orderVo['totalPrice']}
                                {data.orderVo['payType'] == 1 ? '工分' : '积分'}
                            </div>
                            <div>订单编号：{data.orderVo['code']}</div>
                        </Space>
                    </div>
                    <div className={styles.detailDetail}>
                        <Space>
                            <div>下单人：{data.orderVo['username']}</div>
                            <div>下单时间：{data.orderVo['createdDate']}</div>
                        </Space>
                    </div>
                    <br/>
                    <div className={styles.detailTitle}>收货人信息</div>
                    <div className={styles.detailDetail}>
                        收货人：{data.addressVo['name']}
                    </div>
                    <div className={styles.detailDetail}>
                        收货电话：{data.addressVo['phone']}
                    </div>
                    <div className={styles.detailDetail}>
                        收货地址：{data.addressVo['areaStr']}, {data.addressVo['addr']}
                    </div>
                    <div className={styles.detailDetail}>
                        备注：{data.orderVo['remark']}
                    </div>
                    <br/>
                    <div className={styles.detailTitle}>商品信息</div>
                    <Table
                        pagination={false}
                        className="mgTop15"
                        bordered
                        rowKey="id"
                        columns={columns}
                        dataSource={[data.productVo]}
                    />
                    <br/>

                    {data.orderVo['status'] == 3 || data.orderVo['status'] == 4 ? (
                        <>
                            <div className={styles.detailTitle}>
                                物流信息 <Button onClick={this.getExpressData}>点击查询</Button>
                            </div>
                            {expressData.length > 0 ? (
                                <div className={styles.express}>
                                    {expressData.map((item: any, index: number) => {
                                        return (
                                            <div className={styles.expressBox} key={index}>
                                                <div>{item.context}</div>
                                                <div className={styles.time}>{item.time}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div style={{margin: '15px'}}>{expressText}</div>
                            )}
                        </>
                    ) : (
                        ''
                    )}
                </div>
            </>
        );
    }
}

export default function OrderDetail() {
    return (
        <App selectedKeys={['orderdetail']} openKeys="PMallMenu">
            <OrderDetail1/>
        </App>
    );
}
