import React from 'react';
import {Button, Card, Collapse, CollapseProps, Descriptions, InputNumber, message, Modal, Space} from 'antd';
import App from '../../../App';
import styles from './ServiceUser.module.css';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import FileUploader from '../../common/uploader/FileUploader';
import {DefaultRefreshFile, DetailClass} from "../util/ServiceUserDetailTypes";
import {buildAierData, defaultData, refreshFile} from "../util/AierCommon";
import UserInfo from "../detail/UserInfo";
import ScreeningInfo from "../detail/ScreeningInfo";
import AccountInfo from "../detail/AccountInfo";
import HospitalFeeInfo from "../detail/HospitalFeeInfo";
import IllnessInfo from "../detail/IllnessInfo";
import {decryptData} from "../../common/crypto";
import Decimal from 'decimal.js';
import createModal from "../../common/createModal";
import AuditRejectionModule from "../../common/modal/AuditRejectionModule";
import WarVeteranInfo from "../detail/WarVeteranInfo";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import ServiceUserDetailParentClass from "../detail/ServiceUserDetailParent";
import dayjs from "dayjs";

const AuditRejection = createModal(AuditRejectionModule);

const {confirm} = Modal;


class ServiceUserFinancialAuditClass extends ServiceUserDetailParentClass {

    state: DetailClass = {
        id: decryptData(common.getQuery('id')),
        snap: defaultData.snap,
        data: defaultData.data,
        hospitalSelect: [],
        startTime: dayjs().valueOf(),
        refreshData: DefaultRefreshFile(),
        refreshCount: 0,
        spinning: true,
    };
    refd = React.createRef<FileUploader>();
    private importResult: any = null;

    componentDidMount() {
        this.getData()
    };

    getData() {
        const {id} = this.state;
        Fetch.get(`${API.cooperateServiceUsers.get_patient_details}?id=${id}`).then((data) => {
            const {fee} = data;
            const total = new Decimal(this.getNumber(fee.selfPayPrice))
                .add(new Decimal(this.getNumber(fee.otherGrants)))
                .add(new Decimal(this.getNumber(fee.medicareReimbursement)));
            let totalMsg = '';
            if (total.comparedTo(this.getNumber(fee.totalPrice)) != 0) {
                totalMsg = "患者支付+其他补助+医保报销不等于总金额";
            }
            buildAierData(data);
            const refreshData = refreshFile(data.files);
            this.setState({
                data: {
                    ...data,
                    fee: {
                        ...data.fee,
                        subsidyTemp: data.fee.estimatedAmount
                    },
                    totalMsg: totalMsg,
                },
                refreshData: refreshData
            });
        });
    }

    getNumber = (value: number | null) => {

        return value == null ? 0 : value;
    }

    financialApproved = () => {
        let id = this.state.id;
        let subsidy = this.state.data.fee.subsidyTemp;
        if (subsidy == null || subsidy == 0) {
            message.error('请填写报销金额');
            return;
        }
        confirm({
            title: `是否确认报销？`,
            onOk() {
                const data = {
                    serviceUserId: id,
                    subsidy: subsidy
                }
                Fetch.putJSON(API.cooperateServiceUsersStatusV2.update_financial_pass, data).then(() => {
                    message.success('审核成功');
                    window.location.href = '/#/financialAuditList/auditList';
                });
            },
        });
    }

    subsidyChange = (e: any) => {
        const estimatedAmount = this.state.data.fee.estimatedAmount;
        if (estimatedAmount === null) {
            message.error('详情加载失败请联系管理员');
            return;
        }
        const {data} = this.state
        this.setState({
            data: {
                ...data,
                fee: {
                    ...data.fee,
                    subsidyTemp: e
                }
            }
        })
    }

    goBack = () => {
        window.location.href = '/#/financialAuditList/auditList';
    }

    fAuditRefuse = () => {

        this.importResult.show();
    }
    closeModal = () => {

    }

    render() {
        const {data, id, snap} = this.state
        const items: CollapseProps['items'] = [
            {
                key: '1',
                label: '身份信息',
                children: <WarVeteranInfo data={data}/>
            },
            {
                key: '2',
                label: '病情描述',
                children: <IllnessInfo data={data}/>
            },
            {
                key: '3',
                label: '检查登记信息',
                children: <ScreeningInfo data={data}/>
            },
            {
                key: '4',
                label: '银行账户',
                children: <AccountInfo data={data}/>
            },
        ];
        return (
            <div>
                <BreadcrumbInfo/>
                <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                    <UserInfo data={data}/>
                    <Collapse items={items} size={'small'} defaultActiveKey={['1']}/>
                    {/*<WarVeteranInfo id={id} hospitalSelect={[]} snap={snap} data={data}/>*/}
                    {/*<IllnessInfo data={data}/>*/}
                    {/*<ScreeningInfo data={data}/>*/}
                    {/*<AccountInfo data={data}/>*/}
                    <HospitalFeeInfo data={data} onRefresh={this.refreshFile}/>
                    <Card size="small" className={styles.mycard}
                          title={`报销金额`}>
                        {data != null && data.fee != null &&
                        data.fee.selfPayPrice != null &&
                        data.fee.estimatedAmount != null &&
                        data.fee.selfPayPrice > data.fee.estimatedAmount ? (
                            <Descriptions>
                                <Descriptions.Item>
                                    患者花费金额大于报销金额，当前患者可报销金额{data.fee.estimatedAmount}。
                                    {data.totalMsg}
                                </Descriptions.Item>
                            </Descriptions>
                        ) : (
                            <Descriptions>
                                <Descriptions.Item>
                                    当前患者可报销金额{data?.fee.estimatedAmount}。
                                    {data?.totalMsg}
                                </Descriptions.Item>
                            </Descriptions>
                        )}
                        <Descriptions>
                            <Descriptions.Item label="报销金额（元）">
                                <InputNumber
                                    // precision={2}
                                    style={{width: '30%'}}
                                    value={data?.fee.subsidyTemp == null ? undefined : data?.fee.subsidyTemp}
                                    min={0}
                                    placeholder="报销金额"
                                    onChange={this.subsidyChange}
                                />
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                    <Card>
                        <Button
                            size="large"
                            className={styles.backBtn}
                            style={{marginRight: '15px'}}
                            onClick={this.goBack}
                        >
                            返回
                        </Button>
                        <Space className={styles.fAudit}>
                            <Button
                                type="primary"
                                size="large"
                                onClick={this.financialApproved}
                            >
                                通过
                            </Button>
                            <Button
                                type="primary"
                                danger
                                size="large"
                                onClick={this.fAuditRefuse}
                            >
                                拒绝
                            </Button>
                        </Space>
                    </Card>
                </Space>
                <AuditRejection
                    width={500}
                    title={'审核拒绝'}
                    importResult={{id: this.state.id, type: 'AIER_FINANCIAL_AUDIT'}}
                    onCancel={this.closeModal}
                    ref={(c) => (this.importResult = c)}
                />
            </div>
        );
    }
}

export default function ServiceUserFinancialAudit() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <ServiceUserFinancialAuditClass/>
        </App>
    );
}
