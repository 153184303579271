import {Component} from 'react';
import {message, Modal, Table} from 'antd';
import {ColumnsType} from 'antd/es/table';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import MediaPath from "../common/uploader/MediaPath";

export default class MallModel extends Component<any> {
    state = {
        content: [],
        page: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        loading: true,
        tempSelect: {},
        selectedRowKeys: [],
    };

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.loadData();
    }

    isOk = () => {
        // this.state.tempSelect;

        // productCreate
        let datas = new Array();
        for (const item in this.state.tempSelect) {
            datas = datas.concat(this.state.tempSelect[item]);
        }
        Fetch.postJSON(API.goods.productCreate, {productIds: datas}).then(() => {
            message.success('提交成功');
            this.props.setVisible(false);
            this.props.loadData();
            // window.history.go(-1);
        });
    };
    setVisible = (flag: boolean) => {
        this.props.setVisible(flag);
    };
    loadData = () => {
        const {page, pageSize} = this.state;
        Fetch.get(`${API.goods.productModel}?page=${page}&size=${pageSize}`).then(
            (data) => {
                this.setState({
                    content: data.content,
                    totalElements: data.totalElements,
                    totalPages: data.totalPages,
                    loading: false,
                });
                // setTotalTotalPages(data.totalPages),
            }
        );
    };
    changeCondition = (condition: any, load = false) => {
        this.setState(
            {
                loading: load,
                ...condition,
            },
            () => {
                if (load) {
                    this.loadData();
                    const {page, tempSelect} = this.state;
                    if (tempSelect.hasOwnProperty('page' + page)) {
                        let a = `page${page}`;
                        this.setState({
                            selectedRowKeys: tempSelect[a],
                        });
                    }
                }
            }
        );
    };
    onSelectChange = (newSelectedRowKeys: React.Key[]) => {

        const {page, tempSelect} = this.state;
        this.setState({
            selectedRowKeys: newSelectedRowKeys,
            tempSelect: {
                ...tempSelect,
                ['page' + page]: newSelectedRowKeys,
            },
        });
    };

    render() {
        const {page, pageSize, totalElements, content, loading, selectedRowKeys} =
            this.state;
        const {visible} = this.props;
        const columns: ColumnsType<any> = [
            {
                title: '封面图',
                dataIndex: 'coverUrl',
                align: `center`,
                render: (text: string) => {
                    return (
                        <img
                            src={MediaPath.getFileUrl(text)}
                            alt="封面图"
                            style={{width: '50px', height: '30px'}}
                        />
                    );
                },
            },
            {
                title: '商品名称',
                dataIndex: 'name',
                align: `center`,
            },
            {
                title: '兑换规则',
                dataIndex: 'convertibleDivide',
                align: `center`,
                render: (text: any, row: any) => {
                    return row.convertibleType === 1 ? text + '工分' : text + '积分';
                },
            },
            {
                title: '商品价值',
                dataIndex: 'worth',
                align: `center`,
                render: (text: any) => {
                    return text + '元';
                },
            },
            {
                title: '商品库存',
                dataIndex: 'stock',
                align: `center`,
            },
            {
                title: '供应商',
                dataIndex: 'pointsMallSupplierName',
                align: `center`,
            },
            {
                title: '商品编号',
                dataIndex: 'code',
                align: `center`,
            },
            {
                title: '创建时间',
                dataIndex: 'createdDate',
                align: `center`,
            },
        ];
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <>
                <Modal
                    title="添加商品到积分商城"
                    centered
                    open={visible}
                    onOk={() => this.isOk()}
                    onCancel={() => this.setVisible(false)}
                    width={1000}
                    okText="保存"
                >
                    <Table
                        className="mgTop10"
                        rowSelection={rowSelection}
                        bordered
                        rowKey={(record: any) => {
                            return record.id;
                        }}
                        columns={columns}
                        dataSource={content}
                        loading={loading}
                        pagination={{
                            current: page + 1,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) =>
                            this.changeCondition(
                                {
                                    page: pagination.current - 1,
                                    pageSize: pagination.pageSize,
                                },
                                true
                            )
                        }
                    />
                </Modal>
            </>
        );
    }
}
