import React, {useEffect} from 'react';
import './FileViewerCss.css';
import DocViewer, {DocViewerRenderers, IDocument} from 'doc-viewer';

type FullScreenModalProps = {
    isOpen: boolean;
    onClose: () => void;
    fileLists: IDocument[] | { uri: any; fileName: any; }[],
    fileIndex: number | 0
}

const FileViewer = ({isOpen, onClose, fileLists, fileIndex}: FullScreenModalProps) => {

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    if (!isOpen) {
        return null;
    }
    const innerWidth = window.innerWidth;
    const innerHeight = window.innerHeight;
    return (
        <div className="full-screen-modal">
            <div className="modal-content">
                <DocViewer
                    pluginRenderers={DocViewerRenderers}
                    theme={{
                        primary: "#ffffff",
                        secondary: "#ffffff",
                        tertiary: "#ffffff",
                        textPrimary: "#ffffff",
                        textSecondary: "#ffffff",
                        textTertiary: "#ffffff",
                        disableThemeScrollbar: false
                    }}
                    prefetchMethod="GET"
                    style={{
                        // maxHeight: "800px",
                        height: innerHeight + 'px',
                        // maxWidth: "1800px",
                        width: innerWidth + 'px',
                        backgroundColor: 'rgba(0,0,0,0)'
                    }}
                    documents={fileLists}
                    initialActiveDocument={fileLists[fileIndex]}
                    config={{
                        noRenderer: {
                            overrideComponent: ({document, fileName}) => {
                                const fileText = fileName || document?.fileType || "";
                                if (fileText) {
                                    return (
                                        <>
                                            <div className="noReader">{fileText}文件无法预览，请点击右上角下载</div>
                                        </>)
                                }
                                return <div className="noReader">文件无法预览，请点击右上角下载</div>;
                            },
                        },
                        csvDelimiter: ",",
                        pdfVerticalScrollByDefault: true,
                    }}
                    onCloseFun={onClose}
                />
            </div>
        </div>
    );
};

export default FileViewer;
