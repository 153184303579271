import type {UploadFile} from 'antd/es/upload/interface';
import {EyeStatusType} from "./EyeDiseasesUtil";
import {FileTypeTypes} from "../../common/modal/ViewFileListTypes";

export type IllnessDetail = {

    illnessCode: string,
    eyeType: number
}

export type QuotaDetail = {

    quotaCode: string,
    complete: number
}
export type DetailClass = {
    id: string | null,
    hospitalSelect: [],
    snap: string | null,
    startTime: number,
    refreshData?: DetailRefreshFileType,
    refreshCount: number,
    spinning: boolean,
    data: {
        user: {
            name: string | null,
            phone: string | null,
            idCard: string | null,
            createUserId: number | null,
            createInstitution: number | null,
            gender: number | 0,
            age: number | null,
            disabilityLevel: number | null,
            homeAddress: string | null,
            accountType: number | null,
            guardianName: string | null,
            guardianPhone: string | null,
            economicSituation: number | null,
            povertyType: number | null,
            hospitalId: number | null,
            hospitalName: string | null,
            status: number | 0,
            dateBirth: string | null,
            nationality: string | null,
            addressAreaStr: string | null,
            originAreaStr: string | null,
            illnessInfo: string | null,
            releaseTime: string | null,
            billId: number | null,
            identityPassesType: number | null
        },
        account: {
            name: string | null,
            phone: string | null,
            backCard: string | null,
            accountBank: string | null,
            hospitalsId: number | null,
            hospitalsName: string | null,
            cardUrl: string | null,
            paymentMethod: string | null,
            idNumber: string | null,
            relationship: string | null,
            applicant: FileTypeTypes[],
            payee: FileTypeTypes[],
            signature: FileTypeTypes[],
            applicantShow: string | '',
            payeeShow: string | '',
            signatureShow: string | '',
            paymentMethodId: string | '',
            relationshipId: string | ''
        },
        files: [
            {
                fileId: number | null,
                name: string | null,
                type: number | null,
                url: string | null
            }
        ],
        screening: {
            leftEyeVision: number | null,
            rightEyeVision: number | null,
            screeningTime: string | null,
            suggestion: string | null,
            doctorName: string | null,
            eligible: number | 0,
        },
        fee: {
            id: number | 0,
            timeOfAdmission: string | '',
            timeOfDischarge: string | '',
            eyeType: number | 0,
            totalPrice: number | null,
            medicareReimbursement: number | null,
            selfPayPrice: number | null,
            otherGrants: number | null,
            subsidy: number | null,
            subsidyTemp: number | null,
            remark: string | null,
            diagnosticReports: FileTypeTypes[],
            consentForms: FileTypeTypes[],
            hospitalizationRecords: FileTypeTypes[],
            invoices: FileTypeTypes[],
            voucher: FileTypeTypes[],
            identityPromise: FileTypeTypes[],
            proofProve: FileTypeTypes[],
            preferentialTreatmentCard: FileTypeTypes[],
            estimatedAmount: number | null,
            afterSelfPayPrice: number | null,
            eyeDiseases: string[],
            cataractEyeType: number | 0,
            fundusEyeType: number | 0,
            gilliumEyeType: number | 0,
            illnessName: string | '',
            united: string | undefined
        }, illnessInfo: {
            informationOne: FileTypeTypes[],
            informationTwo: string | '',
            informationThere: string | '',
        }, warVeteran: {
            idNumber: string | ''
            name: string | ''
            phone: string | ''
            veteranRelations: string | '',
            myself: string | null,
            serviceIdentity: string | '',
            serviceIdentityStr: string | null
        }
        // diagnosticReports: UploadFile[],
        // consentForms: UploadFile[],
        // hospitalizationRecords: UploadFile[],
        // invoices: UploadFile[]
        dictInfo: {
            account_type: any | null,
            aier_payment: [{ name: string, code: string }],
            aier_relational: [{ value: number, label: string }]
        }
        totalMsg: string | '',
        secondEyeType: number | null,
        advanceMoney: boolean | null,
        illness: IllnessDetail[],
        quota:QuotaDetail[],
    }
};

export type Chart = {
    data: {
        offsetWidth: number | 0,
        total: number | 0,
        last7Day?: [
            {
                key: string | "",
                value: number | 0
            }
        ],
        provinceTotal: [
            {
                key: string | "",
                value: number | 0
            }
        ],
        statsTotal: [
            {
                key: string | "",
                value: number | 0
            }
        ],
        provinceStats: [
            {
                name: string | "",
                totalUser: number | 0,
                completeSurgery: number | 0,
                completeReimburse: number | 0
                toReviewed: number | 0,
                auditFailed: number | 0,
                waitScreening: number | 0,
                waitSurgery: number | 0,
                notSurgery: number | 0,
                reimburseFail: number | 0,
                reconciling: number | 0
            }
        ]
    },
    dayStats: {
        provinceStats: [{
            name: string | "",
            totalUser: number | 0,
            completeSurgery: number | 0,
            completeReimburse: number | 0,
            completeSurgeryCases: number | 0,
            completeReimburseCases: number | 0,
            msg: string | ''
        }]
    }
}

export type ServiceUserCommonType = {

    data: DetailClass['data'] | null
}

export type CheckboxType = {

    label: string,
    value: string,
    disabled: boolean
}

export type EyeTypeType = {

    label: string,
    value: number
}

export type EyeDiseasesConfig = {

    checkBox: CheckboxType[],
    cataractEyeType: boolean,
    fundusEyeType: boolean,
    gilliumEyeType: boolean,
    cataractSelect: EyeTypeType[],
    fundusSelect: EyeTypeType[],
    gilliumSelect: EyeTypeType[],
    cataractStatus: EyeStatusType,
    fundusStatus: EyeStatusType,
    gilliumStatus: EyeStatusType,
}

export type IllnessForm = {

    eyeDiseases: string[],
    cataractEyeType: number | undefined,
    fundusEyeType: number | undefined,
    gilliumEyeType: number | undefined,
}


export type DetailRefreshFileType = {

    diagnosticReports: FileTypeTypes[],
    consentForms: FileTypeTypes[],
    hospitalizationRecords: FileTypeTypes[],
    invoices: FileTypeTypes[],
    identityPromise: FileTypeTypes[],
    proofProve: FileTypeTypes[],
    preferentialTreatmentCard: FileTypeTypes[],
    informationOne: FileTypeTypes[],
    voucher: FileTypeTypes[],
    applicant: FileTypeTypes[],
    payee: FileTypeTypes[],
    signature: FileTypeTypes[],
}

export function DefaultRefreshFile(): DetailRefreshFileType {
    return {
        diagnosticReports: [],
        consentForms: [],
        hospitalizationRecords: [],
        invoices: [],
        identityPromise: [],
        proofProve: [],
        preferentialTreatmentCard: [],
        informationOne: [],
        voucher: [],
        applicant: [],
        payee: [],
        signature: [],
    }
}