import React from 'react';
import {Button, Divider, Input, Space, Spin, Table} from 'antd';
import API from '../../common/api';
import {ColumnsType} from 'antd/es/table';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import BaseListPage from "../../common/listpage/BaseListPage";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {BaseDhPageDate, DhSalesmanData, DhSalesmanType} from "../common/DhPageCommon";
import {salesStatusColorObj, salesStatusObj} from "../common/DhDictData";
import {PlusOutlined} from "@ant-design/icons";
import createModal from "../../common/createModal";
import AddDhSalesmanModelImport from "./AddDhSalesmanModel";
import styles from "../../cooperate/hospitalauditv2/ServiceUserV2.module.css";
import {addParams, STRING_STR} from "../../cooperate/bill/page/ListCommon";


type User = {};

const AddDhSalesmanModel = createModal(AddDhSalesmanModelImport);

class DhSalesmanListClass extends BaseListPage {

    state: DhSalesmanType = {
        ...DhSalesmanData,
        btnQ: {
            ROLE_AIER_M_DETAILS: 0
        }
    };
    private AddDhSalesmanModelResult: any = null;

    componentDidMount() {

        super.componentDidMount();
    }

    loadData = () => {
        const {pageSize, searchData} = this.state;
        let url = `${API.dentistry.dh_salesman.admin_list}?page=${searchData.page}&size=${pageSize}`;
        url = url + addParams("name", searchData.name, STRING_STR);
        url = url + addParams("seatUserId", searchData.seatUserId, STRING_STR);
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                importLoading: false
            });
        })
    };


    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
    };

    reset = () => {
        super.reset(BaseDhPageDate.searchData);
    };

    handleKeyDown = (e: any) => {
        super.handleKeyDown(e);
    }

    goCreate = () => {
        this.AddDhSalesmanModelResult.show()
    }

    closeModal = () => {
        this.loadData()
    }

    remove = (userId: number) => {

    }

    render() {
        const {
            pageSize, totalElements,
            btnQ, searchData, content
        } = this.state;
        const columns: ColumnsType<User> = [

            {
                title: '姓名',
                dataIndex: 'name',
                align: `center`,
                render: (text: any) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '坐席ID',
                dataIndex: 'seatUserId',
                align: `center`,
                render: (text: any) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '已处理',
                dataIndex: 'processed',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '待处理',
                dataIndex: 'pending',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '状态',
                dataIndex: 'salesStatus',
                align: `center`,
                render(value: any) {
                    return <>
                        <div>
                            <div
                                style={{
                                    wordBreak: 'break-all',
                                    color: salesStatusColorObj[value]
                                }}>{salesStatusObj[value]}</div>
                        </div>
                    </>;
                },
            },
            {
                title: '添加时间',
                dataIndex: 'createdDate',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '操作',
                align: `center`,
                dataIndex: 'status',
                // width: 120,
                render: (text: any, record: any) => {
                    return (
                        <Space split={<Divider type="vertical"/>}>
                            <Button className={styles.antBtnPadding} type="link" danger size={"small"}
                                    onClick={() => this.remove(record.userId)}>
                                移除
                            </Button>
                        </Space>
                    );
                },
            },
        ];

        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space onKeyDown={this.handleKeyDown}>
                            <Input
                                placeholder="姓名"
                                value={searchData.name}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, name: e.target.value})
                                }}
                            />
                            <Input
                                placeholder="坐席ID"
                                value={searchData.seatUserId}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, seatUserId: e.target.value})
                                }}
                            />
                            <Button onClick={this.loadData}>
                                搜索
                            </Button>
                            <Button onClick={() => this.reset()}>重置</Button>
                        </Space>
                        <div style={{float: 'right', marginLeft: 'auto'}}>
                            <Button
                                onClick={this.goCreate}
                                type="primary"
                                icon={<PlusOutlined/>}
                            >
                                添加业务员
                            </Button>
                        </div>
                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
                <AddDhSalesmanModel
                    width={500}
                    title={'添加业务员'}
                    onCancel={this.closeModal}
                    ref={(c) => (this.AddDhSalesmanModelResult = c)}
                />
            </>
        );
    }

}

export default function DhSalesmanList() {

    return (

        <App selectedKeys={
            ['DH_SALESMAN_LIST']
        }
             openKeys="ORAL_GUARD">
            <DhSalesmanListClass/>
        </App>
    )
        ;
}
