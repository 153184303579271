import React from 'react';
import {Button, Card, message, Modal, Space, Spin} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import FileUploader from '../../common/uploader/FileUploader';
import {decryptData} from "../../common/crypto";
import createModal from "../../common/createModal";

import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {DhDefaultRefreshFile, DhDetailDataType} from "../common/DhDetailType";
import PatientInfo from "../patientDetail/PatientInfo";
import DhWarVeteranInfo from "../patientDetail/DhWarVeteranInfo";
import DhAccountInfo from "../patientDetail/DhAccountInfo";
import DiagnosisReportInfo from "../patientDetail/DiagnosisReportInfo";
import ServiceUserFeeInfo from "../patientDetail/ServiceUserFeeInfo";
import DhSubsidyInfoInfo from "../patientDetail/DhSubsidyInfoInfo";
import ImportResultModal from "./DhAuditRejectionModule";
import {dhRefreshFile, dhShowFile} from "../common/edit/DhBackfillDataConfig";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import dayjs from "dayjs";
import DhServiceUserParentClass from "../patientDetail/DhServiceUserParent";

const AuditRejection = createModal(ImportResultModal);

const {confirm} = Modal;



class DhFinancialAuditClass extends DhServiceUserParentClass<DhDetailDataType> {
    state: DhDetailDataType  = {
        id: decryptData(common.getQuery('id')),
        // snap: defaultData.snap,
        data: {},
        startTime: dayjs().valueOf(),
        refreshData: DhDefaultRefreshFile(),
        refreshCount: 0,
        spinning: true,
        snap: '',
        bId: 0,
        booking: false,
        // hospitalSelect: [],
    };
    refd = React.createRef<FileUploader>();
    private importResult: any = null;

    componentDidMount() {
        this.getData()
    };

    getData() {
        const {id} = this.state;
        Fetch.get(`${API.dentistry.service_user.get_finance_detail}?serviceId=${id}`).then((data) => {
            dhShowFile(data).then(() => {
                const promise = dhRefreshFile(data.files);
                this.setState({
                    data: data,
                    importLoading: false,
                    spinning: false,
                    refreshData: promise
                });
            })
        });
    }

    financialApproved = () => {
        let id = this.state.id;
        confirm({
            title: `是否确认报销？`,
            onOk() {
                const data = {
                    serviceId: id,
                }
                Fetch.putJSON(API.dentistry.service_user_status.er_process_pass, data).then(() => {
                    message.success('审核成功');
                    window.location.href = '/#/dh/finance/list';
                });
            },
        });
    }

    goBack = () => {
        window.location.href = '/#/dh/finance/list';
    }

    fAuditRefuse = () => {

        this.importResult.show();
    }
    closeModal = () => {

    }

    render() {
        const {data} = this.state
        return (
            <div>
                <Spin tip={"加载中"} spinning={this.state.spinning}>
                    <BreadcrumbInfo/>
                    <Space direction="vertical" size="middle" style={{display: 'flex'}}>

                        <PatientInfo userDetail={data.userDetail} addressDetail={data.addressDetail}
                                     warVeteran={data.warVeteran}/>
                        <DhWarVeteranInfo warVeteran={data.warVeteran}/>
                        <DhAccountInfo account={data.account}/>
                        <DiagnosisReportInfo report={data.report} onRefresh={this.refreshFile}/>
                        <ServiceUserFeeInfo key={"DhServiceUserFee"} serviceUserFee={data.serviceUserFee}
                                            warVeteran={data.warVeteran} onRefresh={this.refreshFile}/>
                        <DhSubsidyInfoInfo key={"DhSubsidyInfoInfo"} subsidyInfo={data.subsidyInfo}/>
                        <Card>
                            <Button
                                size="large"
                                style={{marginRight: '15px'}}
                                onClick={this.goBack}
                            >
                                返回
                            </Button>
                            <Space>
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={this.financialApproved}
                                >
                                    通过
                                </Button>
                                <Button
                                    type="primary"
                                    danger
                                    size="large"
                                    onClick={this.fAuditRefuse}
                                >
                                    拒绝
                                </Button>
                            </Space>
                        </Card>
                    </Space>
                    <AuditRejection
                        width={500}
                        title={'审核拒绝'}
                        importResult={{id: this.state.id}}
                        onCancel={this.closeModal}
                        ref={(c) => (this.importResult = c)}
                    />
                </Spin>
            </div>
        );
    }
}

export default function DhFinancialAudit() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <DhFinancialAuditClass/>
        </App>
    );
}
