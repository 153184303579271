import React, {Component} from 'react';
import {Button, Card, Col, Form, FormInstance, Input, InputNumber, Layout, message, Row} from "antd";
import Fetch from "../common/FetchIt";
import API from "../common/api";
import styles from './login.module.css';
import {UserSetting} from './types';
import App from "../../App";
import UserSider from "./UserSider";
import ZoneSelect from "./ZoneSelect";
import FileUploader from "../common/uploader";
import MediaCodes from "../common/uploader/MediaCodes";
import MediaPath from "../common/uploader/MediaPath";

const FormItem = Form.Item;

type FormProps = {}

type FormState = {
    provinceId: number | null,
}
const {Content} = Layout;

class UserSettingForm extends Component<FormProps, FormState> {
    formRef = React.createRef<FormInstance>();

    state = {
        provinceId: null,
    }

    submit = (values: any) => {
        this.save(values)
    };

    save = (values: UserSetting) => {
        if (typeof values.fileIds !== 'string') {
            values.fileIds = values.fileIds.map((item: any) => item.id).join(',');
        }
        Fetch.putJSON(`${API.organization.zsgc}/${values.id}`, values).then(() => {
            message.success('提交成功');
        });
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        Fetch.get(`${API.organization.zsgc}`).then(data => {
            const fileIds = data.mediaList.map((item: any) => {
                return {id: item.id, uid: item.id, status: 'done', name: item.fileName, url: item.url
                }
            })
            this.formRef.current?.setFieldsValue({
                ...data,
                fileIds,
            })
            this.setState({
                provinceId: data.address.provinceId,
            })
        });
    };

    changeProvince = (provinceId: number) => {
        this.setState({
            provinceId
        })
    }

    render() {
        return (
            <Card className="userCard">
                <Layout>
                    <UserSider selectedKey="1"/>
                    <Content className={styles.mycontent}>
                        <Form
                            onFinish={this.submit}
                            layout="vertical"
                            ref={this.formRef}
                        >
                            <FormItem hidden noStyle name="id">
                                <Input/>
                            </FormItem>
                            <Row gutter={10}>
                                <Col span={12}>
                                    <FormItem className={styles.mylabel} name="name" label="组织昵称"
                                              rules={[{required: true, message: '必填'}]}>
                                        <Input

                                        />
                                    </FormItem>
                                    <ZoneSelect
                                        onChange={this.changeProvince}
                                        provinceId={this.state.provinceId}
                                    />
                                    <FormItem className={styles.mylabel} name={['address', 'detail']} label="街道地址"
                                              rules={[{required: true, message: '必填'}]}>
                                        <Input

                                        />
                                    </FormItem>
                                    <FormItem className={styles.mylabel} name="content" label="组织简介"
                                              rules={[{required: true, message: '必填'}]}>
                                        <Input.TextArea
                                            rows={5}
                                        />
                                    </FormItem>
                                    <FormItem className={styles.mylabel} name="username" label="账号负责人"
                                              rules={[{required: true, message: '必填'}]}>
                                        <Input

                                        />
                                    </FormItem>
                                    <FormItem className={styles.mylabel} name="phone" label="负责人手机号"
                                              rules={[{required: true, message: '必填'}]}>
                                        <InputNumber
                                            style={{width: '100%'}}

                                        />
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem className={styles.mylabel} name="fileIds" valuePropName="fileList"
                                              label="资料"
                                              rules={[{required: true, message: '必填'}]}>
                                        {/*无效页面*/}
                                        <FileUploader
                                            checkSize={false}
                                            desc="支持扩展名：.jpg、.png; 图片大小不能超过2M;"
                                            typeCode={MediaCodes.user_setting.code}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                            <FormItem wrapperCol={{span: 12}} className="center">
                                <Button type="primary" htmlType="submit" className={styles.mybtn}>更新基本信息</Button>
                            </FormItem>
                        </Form>
                    </Content>
                </Layout>
            </Card>
        );
    }
}

/**
 * 用户RoleId 为3时可以跳转该页面
 * @deprecated
 * @constructor
 */
export default function SettingIndex() {
    return (
        <App selectedKeys={['setting']}>
            <UserSettingForm/>
        </App>
    )
}
