import {Component} from 'react';

import {Breadcrumb, Button, message, Select, Space, Table,} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {RecruitType} from './types';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import App from '../../App';

import common from '../common/common';

class GongyiActivityRecruit1 extends Component {
    state = {
        volunteerType: '',
        name: '',

        gender: '',
        status: '',
        username: '',
        id: common.getQuery('id'),
        page: 0,
        pageSize: 15,
        totalElements: 0,
        datas: [],
        loading: true,
        peopleNum: 0,
        recruitedNum: 0,
    };

    componentDidMount() {
        this.getData();
    }

    // 获取数据
    getData = () => {
        const {page, pageSize, gender, status, name, id, volunteerType} =
            this.state;

        Fetch.get(
            `${API.gongyiActivity.activityUsers}?page=${page}&size=${pageSize}&gender=${gender}&status=${status}&activityId=${id}&volunteerType=${volunteerType}`
        ).then((data) => {
            this.setState({
                datas: data.page.content,
                peopleNum: data.peopleNum.peopleNum,
                recruitedNum: data.peopleNum.recruitedNum,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                loading: false,
            });
        });
    };

    changeName = (val: String) => {
    };
    // 招募
    recruit = (row: any) => {
        const {id} = this.state;
        Fetch.put(API.gongyiActivity.updateStatusRecruit, {
            activityId: id,
            id: row.id,
        }).then((data) => {
            message.success('招募成功');
            this.getData();
        });
    };
    changeSex = (val: string) => {
        this.setState({
            gender: val,
        });
    };
    changeType = (val: string) => {
        this.setState({
            volunteerType: val,
        });
    };
    changeStatus = (val: string) => {
        this.setState({
            status: val,
        });
    };

    // 取消招募
    delRecruit = (row: any) => {
        const {id} = this.state;
        Fetch.put(API.gongyiActivity.updateStatusRecruitCancel, {
            activityId: id,
            id: row.id,
        }).then((data) => {
            message.success('取消招募成功');
            this.getData();
        });
    };
    // 取消招募
    refuseRecruit = (row: any) => {
        const {id} = this.state;
        Fetch.put(API.gongyiActivity.updateStatusRefuse, {
            activityId: id,
            id: row.id,
        }).then((data) => {
            message.success('拒绝招募成功');
            this.getData();
        });
    };
    // 下一页
    changeCondition = (condition: any, load = false) => {
        this.setState(
            {
                ...condition,
            },
            () => {
                if (load) {
                    this.getData();
                }
            }
        );
    };

    render() {
        const columns: ColumnsType<RecruitType> = [
            {
                title: '姓名',
                dataIndex: 'name',
                align: `center`,
            },
            {
                title: '手机号',
                dataIndex: 'phone',
                align: `center`,
                render: (text: string, record: any) => {
                    if (text && text !== '') {
                        return text;
                    } else if (record.wxPhone && record.wxPhone !== '') {
                        return record.wxPhone;
                    } else {
                        return '';
                    }
                },
            },
            {
                title: '性别',
                dataIndex: 'gender',
                align: `center`,
                render: (text: number) => {
                    if (text === 1) {
                        return '男';
                    } else if (text === 2) {
                        return '女';
                    } else {
                        return '';
                    }
                },
            },
            {
                title: '志愿者类型',
                dataIndex: 'volunteerType',
                align: `center`,
                render: (text: number) => {
                    if (text === 1) {
                        return '乐龄志愿者';
                    } else if (text === 2) {
                        return '敬老志愿者';
                    } else {
                        return '';
                    }
                },
            },
            {
                title: '所在地',
                dataIndex: 'address',
                align: `center`,
            },
            {
                title: '报名时间',
                dataIndex: 'createdDate',
                align: `center`,
            },
            {
                title: '状态',
                dataIndex: 'status',
                render: (text: number) => {
                    if (text === 1) {
                        return '未招募';
                    } else if (text === 2) {
                        return '已招募';
                    } else if (text === 6) {
                        return '已签出';
                    } else if (text === 5) {
                        return '已签到';
                    } else if (text === 3) {
                        return '拒绝招募';
                    } else {
                        return '';
                    }
                },
                align: `center`,
            },

            {
                title: '操作',
                align: `center`,
                dataIndex: 'status',
                render: (text: number, record: any) => {
                    if (text === 1) {
                        return (
                            <Space>
                                {/* <Button type="link" size="small">
                   详情
                 </Button> */}
                                <Button
                                    type="link"
                                    size="small"
                                    danger
                                    onClick={() => {
                                        this.recruit(record);
                                    }}
                                >
                                    招募
                                </Button>
                                <Button
                                    type="link"
                                    size="small"
                                    danger
                                    onClick={() => {
                                        this.refuseRecruit(record);
                                    }}
                                >
                                    拒绝
                                </Button>
                            </Space>
                        );
                    } else if (text === 2) {
                        return (
                            <Space>
                                <Button
                                    type="link"
                                    size="small"
                                    danger
                                    onClick={() => {
                                        this.delRecruit(record);
                                    }}
                                >
                                    取消招募
                                </Button>
                            </Space>
                        );
                    } else if (text === 3) {
                        return (
                            <Space>
                                <Button
                                    type="link"
                                    size="small"
                                    onClick={() => {
                                        this.recruit(record);
                                    }}
                                >
                                    招募
                                </Button>
                            </Space>
                        );
                    } else {
                        return '—';
                        // return (
                        // <Space>
                        //   <Button type="link" size="small">
                        //     详情
                        //   </Button>
                        // </Space>
                        // );
                    }
                },
            },
        ];
        const {
            volunteerType,
            loading,
            gender,
            page,
            pageSize,
            totalElements,
            datas,
            status,
            peopleNum,
            recruitedNum,
        } = this.state;

        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>
                        <a href="/#/gongyiActivity">公益活动</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>报名管理</Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Space>
                        性别：
                        <Select
                            placeholder="请选择状态"
                            value={gender}
                            onChange={(e) => this.changeSex(e)}
                            style={{width: 120}}
                        >
                            <Select.Option value="">全部</Select.Option>
                            <Select.Option value="1">男</Select.Option>
                            <Select.Option value="2">女</Select.Option>
                        </Select>
                        志愿者类型：
                        <Select
                            placeholder="请选择状态"
                            value={volunteerType}
                            onChange={(e) => this.changeType(e)}
                            style={{width: 120}}
                        >
                            <Select.Option value="">全部</Select.Option>
                            <Select.Option value="1">乐龄志愿者</Select.Option>
                            <Select.Option value="2">敬老志愿者</Select.Option>
                        </Select>
                        状态
                        <Select
                            placeholder="请选择状态"
                            value={status}
                            onChange={(e) => this.changeStatus(e)}
                            style={{width: 120}}
                        >
                            <Select.Option value="">全部</Select.Option>
                            <Select.Option value="1">未招募</Select.Option>
                            <Select.Option value="2">已招募</Select.Option>
                            <Select.Option value="3">已拒绝</Select.Option>
                        </Select>
                        <Button onClick={this.getData} type="primary">
                            搜索
                        </Button>
                    </Space>
                    <div style={{float: 'right'}}>
                        已招募人数 {recruitedNum ? recruitedNum : 0}/{peopleNum}
                    </div>
                    <Table
                        loading={loading}
                        className="mgTop15"
                        bordered
                        rowKey={(record: any) => {
                            return record.id;
                        }}
                        columns={columns}
                        dataSource={datas}
                        pagination={{
                            current: page + 1,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) =>
                            this.changeCondition(
                                {
                                    page: pagination.current - 1,
                                    pageSize: pagination.pageSize,
                                },
                                true
                            )
                        }
                    />
                </div>
            </>
        );
    }
}

export default function GongyiActivityRecruit() {
    return (
        <App selectedKeys={['gongyi']} openKeys="sub1">
            <GongyiActivityRecruit1/>
        </App>
    );
}
