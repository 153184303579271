import {Card, Descriptions, Space} from 'antd';
import React from 'react';
import ViewFileListClass from "../../common/modal/ViewFileList";
import {DhDetailDataType} from "../common/DhDetailType";

type  VoucherInfoType = {
    userDetail: DhDetailDataType["data"]["userDetail"],
    onRefresh: (type: string) => Promise<any>;
}
const DhVoucher: React.FC<VoucherInfoType> = (props) => {

    return (
        <>
            {props && props.userDetail != null &&
            props.userDetail.releaseTime &&
            props.userDetail.voucher.length > 0 ? (
                <Card size="small"
                      title={`报销凭证`}>
                    <Space size={[8, 16]} wrap align="start">
                        <Card style={{width: '100%'}}>
                            <Descriptions>
                                <Descriptions.Item label="补贴款发放时间">
                                    {props.userDetail.releaseTime}
                                </Descriptions.Item>
                            </Descriptions>
                            <Card style={{width: '25%'}}>
                                <ViewFileListClass
                                    title="报销凭证"
                                    fileList={props.userDetail.voucher}
                                    onRefresh={props.onRefresh}
                                />
                            </Card>
                        </Card>
                    </Space>
                </Card>
            ) : ('')}
        </>
    );
};

export default DhVoucher;
