import React, {Component} from 'react';
import {Button, Form, FormInstance, Input, message} from "antd";
import Fetch from "../common/FetchIt";
import Editor from "../common/editor/Editor";
import API from "../common/api";
import styles from './donationActivity.module.css';
import {Progress} from './types';
import FileUploader from '../common/uploader';
import MediaCodes from "../common/uploader/MediaCodes";
import MediaUtils from "../common/uploader/MediaPath";

const FormItem = Form.Item;

type FormProps = {
    progress: Progress,
    hide: () => void,
    reload: () => void,
}

type FormState = {
    options: [],
}

class DonationProgressForm extends Component<FormProps, FormState> {
    formRef = React.createRef<FormInstance>();
    draft = 'PRE_PUBLISHED';

    state: FormState = {
        options: [],
    };

    saveDraft = () => {
        this.draft = 'DRAFT';
        this.formRef.current?.submit();
    }

    submit = (values: any) => {
        values.coverIds = values.coverIds.map((item: any) => item.id).join(',');
        values.content = values.content.toHTML ? values.content.toHTML() : values.content;
        if (values.content == "<p></p>") {
            message.error("内容不能为空！")
            return
        }
        if (values.id) {
            this.update(values);
        } else {
            values.status = this.draft;
            this.create(values);
        }
    };

    create = (values: Progress) => {
        Fetch.postJSON(`${API.progress.zsgc}`, values).then(() => {
            message.success('提交成功');
            this.props.hide();
            this.props.reload();
        });
    }

    update = (values: Progress) => {
        Fetch.putJSON(`${API.progress.zsgc}/${values.id}`, values).then(() => {
            message.success('保存成功');
            this.props.hide();
            this.props.reload();
        });
    }

    render() {
        return (
            <div className={styles.container}>
                <Form
                    initialValues={{
                        ...this.props.progress,
                        continueWhenFull: true,
                        timeLimited: true,
                    }}
                    onFinish={this.submit}
                    ref={this.formRef}
                >
                    <FormItem hidden noStyle name="id">
                        <Input/>
                    </FormItem>
                    <FormItem hidden noStyle name="activityId">
                        <Input/>
                    </FormItem>
                    <FormItem className={styles.mylabel} name="coverIds" valuePropName="fileList" label="封面图"
                              labelCol={{span: 5}} wrapperCol={{span: 19}} rules={[{required: true, message: '必填'}]}>
                        {/*无需刷新 无效文件，小程序端已经不在显示*/}
                        <FileUploader
                            multiple
                            checkSize={false}
                            desc="支持扩展名：.jpg、.png; 图片大小不能超过2M; 建议尺寸 224 * 160"
                            typeCode={MediaCodes.donation_pub.code}
                            onRefresh={MediaUtils.upLoadRefresh}
                        />
                    </FormItem>
                    <FormItem className={styles.mylabel} name="name" label="进展名称" labelCol={{span: 5}}
                              wrapperCol={{span: 19}} rules={[{required: true, message: '必填'}]}>
                        <Input

                        />
                    </FormItem>
                    <FormItem className={styles.mylabel} name="publisher" label="发布者" labelCol={{span: 5}}
                              wrapperCol={{span: 19}} rules={[{required: true, message: '必填'}]}>
                        <Input

                        />
                    </FormItem>
                    <FormItem className={styles.mylabel} name="description" label="简介" labelCol={{span: 5}}
                              wrapperCol={{span: 19}} rules={[{required: true, message: '必填'}, {
                        max: 200,
                        message: '最多200个字符',
                        type: 'string'
                    }]}>
                        <Input.TextArea
                            rows={5}
                        />
                    </FormItem>
                    <FormItem className={styles.mylabel} name="content" label="进展内容" labelCol={{span: 5}}
                              wrapperCol={{span: 19}} rules={[{required: true, message: '必填'}]}>
                        <Editor
                            url={API.upload}
                        />
                    </FormItem>
                    <FormItem wrapperCol={{span: 12, offset: 6}} className="center">
                        <Button type="primary" htmlType="submit" className={styles.mybtn}>提交</Button>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

export default DonationProgressForm;
