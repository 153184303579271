import React, {useEffect, useState} from 'react';
import {Button, Input, message, Modal, Space, Spin, Table} from 'antd';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import {PlusOutlined} from "@ant-design/icons";
import createModal from "../../common/createModal";
import HospitalTeethFeeModelClass from "./hospitalTeethFeeModel";
import {ColumnsType} from "antd/es/table";

const confirm = Modal.confirm;

interface ModalType {
    teethName: string,
    name: string,
    teethPrice: number,
    deleted: number,
    hospitalId: number,
    id: number,
    hide: any,
    hospitalName: string,
}

type data = {
    id: number,
    hospitalName: string,
    teethName: string,
    teethPrice: string,
    deleted: boolean,
}
type User = {};

const HospitalTeethFeeModel = createModal(HospitalTeethFeeModelClass);
const HospitalTeethFeeIndexList: React.FC<ModalType> = (props) => {
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalElements, setTotalElements] = useState<number>(0);

    const [data, setData] = useState<data[]>([]);
    const [teethList, setTeethList] = useState<any>([]);
    const [teethName, setTeethName] = useState<string>('');

    let hospitalTeethFeeModel: any = null;

    useEffect(
        function () {
            loadData();
        },
        [props.id]
    );

    const loadData = () => {

        const hospitalId = props.hospitalId ? props.hospitalId : 0;
        const name = teethName ? teethName : "";
        Fetch.get(`${API.dhHospitalTeethFee.list}?page=${page}&size=${pageSize}&hospitalId=${hospitalId}&name=${name}`).then((data) => {

            setData(data.content)
            setTotalElements(data.totalElements)

        });
    };

    const submit = (values: any) => {
        hospitalTeethFeeModel.show()
        setTeethList({
            isAdd: true
        })
    };
    const update = (values: any) => {
        hospitalTeethFeeModel.show();

        setTeethList({
            id: values,
            isAdd: false
        });
    };

    const del = (id: any, deleted: boolean) => {
        const isDeleted = deleted ? 0 : 1;
        confirm({
            title: `您是否确认要停用ID为： “${id}”`,
            onOk() {
                Fetch.putJSON(API.dhHospitalTeethFee.update, {id: id, deleted: isDeleted}).then(() => {
                        message.success('提交成功');
                        loadData();
                    }
                );
            },
        });
    }
    const changeCondition = (condition: any) => {
        setPage(condition.page)
        if (condition.name) {
            setTeethName(condition.name)
        }

    };
    const reset = () => {

        setTeethName('')
    }

    const columns: ColumnsType<User> = [

        {
            title: '医院',
            dataIndex: 'hospitalName',
            align: `center`,
            render: (text: any) => {
                return (
                    <span style={{wordBreak: 'break-all'}}>{text}</span>
                );
            },
        },
        {
            title: '牙齿名称',
            dataIndex: 'teethName',
            align: `center`,
            render: (text: any) => {
                return (
                    <span style={{wordBreak: 'break-all'}}>{text}</span>
                );
            },
        },
        {
            title: '牙齿价格',
            dataIndex: 'teethPrice',
            align: `center`,
            render: (text: any) => {
                return (
                    <span style={{wordBreak: 'break-all'}}>{text}</span>
                );
            },
        },
        {
            title: '操作',
            key: 'operation',
            align: `center`,
            width: 100,
            render: (text: any, record: any) => {
                return (
                    <Space>
                        <>
                            <Button
                                onClick={() => update(record.id)}>
                                修改
                            </Button>
                            <Button
                                onClick={() => del(record.id, record.deleted)}>
                                停用
                            </Button>
                        </>
                    </Space>
                );
            },
        },
    ];

    const pageChange = (condition: any) => {
        setPage(condition.page)
        loadData()
    };

    return <>
        {data && (
            <>
                <Space>
                    <div>
                        <Button type="primary" onClick={submit}
                                style={{marginTop: '15px', marginBottom: '15px'}}
                                icon={<PlusOutlined/>}>新建</Button>
                    </div>

                    <div style={{display: "flex", justifyContent: "right", alignItems: "center", width: '100%'}}>
                        <div style={{width: '200px', margin: " 0 10px"}}>
                            <Input
                                placeholder="请输入牙齿名称"
                                value={teethName}
                                onChange={(e) => {
                                    changeCondition({page: 0, name: e.target.value})
                                }}
                            />
                        </div>
                    </div>
                    <Button onClick={loadData}>
                        搜索
                    </Button>
                    <Button onClick={() => reset()}>重置</Button>
                </Space>
                <Spin tip="加载中..." spinning={false}>
                    <Table
                        className="mgTop15"
                        bordered
                        rowKey="id"
                        columns={columns}
                        dataSource={data}
                        pagination={{
                            current: page + 1,
                            pageSize: pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) =>
                            pageChange(
                                {
                                    page: pagination.current - 1
                                }
                            )
                        }
                    />
                </Spin>
            </>
        )}

        <HospitalTeethFeeModel
            width={1500}
            title={'编辑'}
            id={teethList.id}
            hospitalId={props.hospitalId}
            isAdd={teethList.isAdd}
            onCancel={loadData}
            ref={(c) => (hospitalTeethFeeModel = c)}
        />
    </>

}

export default HospitalTeethFeeIndexList;
