/**
 * Created by sunlong on 16/12/28.
 */
import React from 'react';
import {Button, message, Space, Upload} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import './FileUploader.css'
import API from '../api';
import common from '../common';
import axios from 'axios';
import MediaPath from "./MediaPath";

/**
 * @typedef {Object} FileUploaderProps
 * @property {File[]} [fileList] - Optional list of files
 * @property {(file: File) => Promise<void>} [onPreview] - Preview handler that returns a Promise
 */
export default class FileUploader extends React.Component {
    state = {
        fileList: this.props.fileList ? this.props.fileList : [],
    };

    handleUpload = (info) => {
        // setTimeout(() => {
        let {fileList} = info;
        if (!this.props.multiple) {
            fileList = fileList.slice(-1);
        }
        // 2. read from response and show file link
        fileList = fileList.map((file) => {
            if (file.response) {
                file.url = file.response.data.filePath;
                file.id = file.response.data.mediaId;
                file.filePath = MediaPath.getFileUrl(file.response.data.filePath)
                file.create = true;
            }
            return file;
        });

        // 3. filter successfully uploaded files according to response from server
        fileList = fileList.filter((file) => {
            if (file.response) {
                return file.response.success;
            }
            return true;
        });
        this.setState({fileList});
        return fileList;
        // }, 100);
    };

    onUploadChange = (info) => {

        this.props.onChange(this.handleUpload(info), info);
    };

    beforeUpload = (file, fileList) => {
        if (!this.props.noChekckSizeMal) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error('图片大小超出限制');
                return Upload.LIST_IGNORE;
            }
        }
        if (this.props.checkSize) {
            return this.isSize(file);
        }
        return true;
    };

    //检测尺寸
    isSize = (file) => {
        return new Promise((resolve, reject) => {
            let width = this.props.width;
            let height = this.props.height;
            let _URL = window.URL || window.webkitURL;
            let img = new Image();
            img.onload = function () {
                let valid = img.width === width && img.height === height;
                valid ? resolve() : reject();
            };
            img.src = _URL.createObjectURL(file);
        }).then(() => {
                return file;
            },
            () => {
                message.error(file.name + '图片尺寸不符合要求，请修改后重新上传！');
                return Promise.reject();
            }
        );
    };
    // componentDidUpdate = (prevProps, prevState) => {
    //   if (prevProps.fileList) {
    //     this.setState({
    //       fileList: prevProps.fileList,
    //     });
    //   }
    // };
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.fileList) {
            this.setState({
                fileList: nextProps.fileList,
            });
        }
    };

    renderButton = () => {
        if (
            this.props.maxCount !== undefined &&
            this.state.fileList.length >= this.props.maxCount
        ) {
            return null;
        }
        if (this.props.listType === 'picture-card') {
            return (
                <div>
                    <PlusOutlined/>
                </div>
            );
        } else {
            return (
                // disabled={this.props.disabled ? true : false}
                <Button icon={this.props.icon ? this.props.icon : ''}
                        disabled={!!this.props.disabled}>
                    {this.props.text ? this.props.text : '上传'}
                </Button>
            );
        }
    };

    // customUpload = ({action, file, onProgress, onError, onSuccess}) => {
    //     // 创建FormData用于上传
    //     if (!this.props.typeCode) {
    //         message.error("未设置编码。");
    //         return
    //     }
    //     const formData = new FormData();
    //     formData.append("file", file);
    //     formData.append("typeCode", this.props.typeCode);
    //     // 使用axios发送上传请求
    //     axios.post(action, formData, {
    //         onUploadProgress: (progressEvent) => {
    //             // 计算并更新上传进度
    //             const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    //             onProgress({percent});
    //         },
    //     }).then((response) => {
    //         // 请求成功调用onSuccess
    //         onSuccess(response.data, file);
    //         message.success(`文件上传成功`);
    //     }).catch((error) => {
    //         // 请求失败调用onError
    //         console.error("Upload failed:", error);
    //         onError(error);
    //         message.error(`文件上传失败`);
    //     });
    // };

    // 如果链接有签名
    // 如果没有签名
    // 到这里的链接已经是完整的链接， 所以是已经区分过CDN地址。只要判断是否需要刷新
    onPreview = (e) => {
        if (e.filePath.indexOf(API.fileSignKey) >= 0) {
            if (e.create === true) {
                return;
            }
            if (!this.props.onRefresh) {
                message.error("未定义刷新方法")
                return;
            }
            this.props.onRefresh(e.fileType, e.mediaId).then((data) => {
                window.open(data, '_blank', 'noopener,noreferrer');
            })
        } else {
            window.open(e.filePath, '_blank', 'noopener,noreferrer');
        }
    }

    buildData = (e) => {
        console.log(e)
        return {filename: "123"}
    }

    render() {
        const {disabled: disabled = false} = this.props;
        return (
            <Space>
                <Upload
                    disabled={disabled}
                    name="file"
                    multiple={!!this.props.multiple}
                    action={API.upload}
                    listType={this.props.listType ? this.props.listType : 'picture'}
                    accept={this.props.accept ? this.props.accept : 'image/*'}
                    headers={{Authorization: `Bearer ${common.getAccessToken()}`}}
                    onChange={this.onUploadChange}
                    maxCount={this.props.maxCount || 99}
                    beforeUpload={this.beforeUpload2}
                    fileList={this.state.fileList}
                    className={this.props.className || ''}
                    // customRequest={this.customUpload}
                    onPreview={this.onPreview}
                    data={(file) => {
                        return {
                            typeCode: this.props.typeCode,
                        };
                    }}
                >
                    {this.renderButton()}
                </Upload>
                {this.state.fileList.length === 0 && (
                    <div style={{color: 'gray', fontSize: '12px'}}>
                        {this.props.desc || ''}
                    </div>
                )}
            </Space>
        );
    }
}
