import React, {Component} from 'react';
import {Avatar, Divider, message, Select, Space, Spin, Table, Tabs} from "antd";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";

import App from '../../../App';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import {ColumnsType} from "antd/es/table";
import {AntDesignOutlined, TrophyOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import encryptData from "../../common/crypto";
import createModal from "../../common/createModal";
import MergeUnitGroupModal from "./MergeUnitGroup";
import MediaUtils from "../../common/uploader/MediaPath";

const MergeUnitGroupResult = createModal(MergeUnitGroupModal);

const ExamRankingTabsKey = "examRankingTabs";
type User = { paperCode: string };
type Tab = {
    key: string,
    label: string,
    children: any
}
type StateType = {
    pageSearchData: {
        page: number
    },
    tip: "加载中...",
    importLoading: true,
    unitData: [],
    tabsData: Tab[],
    searchData: [],
    tabsDefault: "0",
    tabsSelect: number
    selectedItems: undefined,
    pageSize: number,
    totalElements: number
}

type unit = {
    unit: string
    count: number
}

class ExamAnswerUserRankingClass extends Component<{}> {

    state: StateType = {
        pageSearchData: {
            page: 0
        },
        tip: "加载中...",
        importLoading: true,
        unitData: [],
        tabsData: [],
        searchData: [],
        tabsDefault: "0",
        tabsSelect: 0,
        selectedItems: undefined,
        pageSize: 10,
        totalElements: 0
    }
    private mergeUnitGroupResult: any = null;
    componentDidMount() {

        this.getUnit();
    }

    getTable = () => {
        const columns: ColumnsType<User> = [
            {
                title: '排名',
                dataIndex: 'index',
                align: `center`,
                render: (text: any) => {
                    return (
                        <>
                            {text === 1 && (
                                <TrophyOutlined style={{color: "#ffdc8a", fontSize: "50px"}}/>
                            )}
                            {text === 2 && (
                                <TrophyOutlined style={{color: "#c8cfe2", fontSize: "40px"}}/>
                            )}
                            {text === 3 && (
                                <TrophyOutlined style={{color: "#efc6a6", fontSize: "30px"}}/>
                            )}
                            {text > 3 && (
                                text
                            )}
                        </>
                    );
                },
            },
            {
                title: '头像',
                dataIndex: 'avatar',
                align: `center`,
                render: (text: any) => {
                    return (
                        <Avatar
                            size={50}
                            icon={<AntDesignOutlined/>}
                            src={text && text.indexOf("http") >= 0 ? text : MediaUtils.getFileUrl(text)}
                        />
                    );
                },
            },
            {
                title: '姓名',
                dataIndex: 'userName',
                align: `center`
            },
            {
                title: '手机号',
                dataIndex: 'userPhone',
                align: `center`
            },
            {
                title: '得分',
                align: `center`,
                dataIndex: 'maxScore'
            },
            {
                title: '答题时间',
                align: `center`,
                dataIndex: 'minTime'
            },
            {
                title: '答题次数',
                align: `center`,
                dataIndex: 'number'
            },
            {
                title: '单位',
                align: `center`,
                dataIndex: 'unit'
            },
            {
                title: '操作',
                align: `center`,
                dataIndex: 'unit',
                render: (text: any, record: any) => {
                    return (
                        <Space split={<Divider type="vertical"/>}>
                            <Link to={`/exam/answer_user/detail?id=${encryptData(record.phoneId + "")}`}>
                                查看详情
                            </Link>
                        </Space>
                    );
                },
            },
        ];
        return columns;
    }

    getUnit() {

        const {
            tabsSelect
        } = this.state;
        Fetch.get(API.exam.exam_statistics.find_unit).then((data) => {
            let tabsDataTemp: any[] = [];
            data.forEach((item: unit, index: number) => {
                const strIndex = String(index);
                if (item.unit === '') {
                    item.unit = '个人组'
                }
                if (item.unit.trim() === '') {
                    item.unit = '未填写'
                }
                tabsDataTemp.push({key: strIndex, label: item.unit + " | " + item.count + "人"})
            })
            const examRankingTabs = sessionStorage.getItem(ExamRankingTabsKey);
            this.setState({
                unitData: data,
                tabsData: tabsDataTemp,
                searchData: tabsDataTemp,
                importLoading: false,
                tabsSelect: examRankingTabs === null ? tabsSelect : examRankingTabs
            }, () => {
                this.findRanking();
            })
        });
    }

    pageChange(condition: any) {

        const {pageSearchData} = this.state;
        this.setState({
            pageSearchData: {
                ...pageSearchData, ...condition
            }
        }, () => {
            this.findRanking();
        });
    };

    findRanking() {

        const {
            unitData, tabsData, searchData, pageSize, totalElements,
            pageSearchData, tabsSelect
        } = this.state;
        let item = tabsData[tabsSelect];
        const unit = this.getUnitName(item.label);
        const url = `${API.exam.exam_statistics.find_ranking}?page=${pageSearchData.page}&size=${pageSize}&unit=${unit}`
        Fetch.get(url).then((data) => {
            data.content.forEach((item: any, index: number) => {
                item.index = pageSearchData.page * 10 + index + 1
            })
            const children = (<>
                <Table
                    className="mgTop15"
                    bordered
                    rowKey="phoneId"
                    columns={this.getTable()}
                    dataSource={data.content}
                    pagination={{
                        current: pageSearchData.page + 1,
                        pageSize: pageSize,
                        total: data.totalElements,
                        showTotal: (total) => `共${total}条`,
                        showSizeChanger: false,
                    }}
                    onChange={(pagination: any) =>
                        this.pageChange(
                            {
                                page: pagination.current - 1
                            }
                        )
                    }
                />
            </>)
            const updatedTabsData = tabsData.map((tab: Tab, index) => {
                return Number(index) === Number(index) ? {...tab, children: children} : tab
            });
            this.setState({
                tabsDefault: tabsSelect + "",
                selectedItems: unitData[tabsSelect],
                tabsData: updatedTabsData,
                importLoading: false
            })
        })
    }

    tabsChange = (e: any) => {

        const {pageSearchData} = this.state
        pageSearchData.page = 0;
        this.setState({importLoading: true, tabsSelect: e, pageSearchData: {...pageSearchData}}, () => {
            sessionStorage.setItem(ExamRankingTabsKey, e)
            this.findRanking();
        })
    }

    setSelectedItems = (e: string, o: any) => {
        const {unitData} = this.state;
        const unitName = this.getUnitName(e);
        const number = unitData.findIndex((item: { unit: string }) => item.unit.trim() === unitName.trim());
        this.setState({importLoading: true, tabsSelect: number}, () => {
            this.findRanking();
        })
    }

    closeModal = () => {
        this.setState({
                importLoading: true,
            }, () => {
                this.getUnit();
            }
        );
    }

    getUnitName = (item: string) => {
        if (item) {
            return item.trim().split("|")[0]
        } else {
            message.error("条件为空")
            return '';
        }
    }

    showMergeModel = () => {

        this.mergeUnitGroupResult.show()
    }
    render() {
        const {tabsData, searchData, tabsDefault, selectedItems, unitData} = this.state;
        return (
            <>

                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                        <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Select
                            showSearch
                            placeholder="单位名称"
                            value={selectedItems}
                            onChange={this.setSelectedItems}
                            style={{width: '50%'}}
                            options={tabsData.map((item: { key: string, label: string }) => ({
                                value: item.label,
                                label: item.label,
                            }))}
                        />
                        <Tabs type="line"
                              tabPosition="top"
                              activeKey={tabsDefault}
                              items={tabsData}
                              tabBarExtraContent={
                                  <>
                                      <Link to={`/exam/exam_statistics/merge_group`}>
                                          合并单位组
                                      </Link>
                                  </>
                              }
                              onChange={this.tabsChange}
                        />
                    </div>
                </Spin>
                <MergeUnitGroupResult
                    width={1800}
                    title={'编辑数据权限'}
                    unitDatas={unitData}
                    onCancel={this.closeModal}
                    ref={(c) => (this.mergeUnitGroupResult = c)}
                />
            </>
        );
    }
}

export default function ExamAnswerUserRanking() {

    return (

        <App selectedKeys={
            ['ExamStatistics']
        }
             openKeys="EXAM">
            <ExamAnswerUserRankingClass/>
        </App>
    )
}
