import React, {useState} from 'react';
import {Button, DatePicker, message, Space} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import Fetch from "../common/FetchIt";
import API from "../common/api";
import common from "../common/common";
import dayjs from "dayjs";
import MediaPath from "../common/uploader/MediaPath";

/**
 * 报销失败弹窗
 * @param props
 * @constructor
 */
export default function TaxesDownloadModal(props: any) {

    const [taxesDate, setTaxesDate] = useState(dayjs().date(10));
    const [btLoading, setBtLoading] = useState(false);
    const cancel = () => {
        props.hide();
    }
    const ok = () => {
        props.hide();
        props.reload();
    }

    const download = () => {
        setBtLoading(true)
        Fetch.get(
            `${API.taxes_user.download_taxes}?taxesDate=` + taxesDate.format("YYYY-MM-DD")
        ).then((data) => {
            setBtLoading(false)
            if (data) {
                const currentTimeStamp = Math.floor(Date.now() / 1000);
                const time = taxesDate.subtract(1, 'months').format("YYYY-MM");
                common.downloadFile("北京乐龄芳华社会工作发展中心工资表_" + time + currentTimeStamp + ".xlsx",
                    MediaPath.getFileUrl(data), (callback: any) => {
                        if (callback === true) {
                            message.success('处理完成');
                            ok();
                        } else {
                            message.error('下载失败，请联系管理员');
                        }
                    });
            } else {
                message.error('下载失败，请联系管理员');
            }
        }).catch((err) => {
            setBtLoading(false)
        });
    }

    return <>
        <>
            <Space direction="vertical" size="middle" style={{display: 'flex'}}>

                <FormItem
                    name="msg"
                    label="选择报税月份"
                    wrapperCol={{span: 25}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <DatePicker
                        defaultValue={taxesDate}
                        onChange={(e) => {
                            if (e) {
                                setTaxesDate(e.date(10))
                            } else {
                                setTaxesDate(dayjs().date(10))
                            }
                        }}
                        inputReadOnly={true} picker="month"/>
                </FormItem>
                <Space>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        onClick={download}
                        loading={btLoading}
                    >
                        下载
                    </Button>
                    <Button
                        size='large'
                        onClick={cancel}
                    >
                        取消
                    </Button>
                </Space>
            </Space>
        </>
    </>;
}
