import React, {useEffect, useState} from 'react';
import styles from "../add/ServiceUser.module.css";
import {buildIllness, defaultData, eyeTypeArrShow} from "../util/AierCommon";
import {Button, Card, DatePicker, Descriptions, Form, FormInstance, message, Space} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import FormItem from 'antd/lib/form/FormItem';
import FileUploader from "../../common/uploader";
import {UploadOutlined} from '@ant-design/icons';
import type {RangePickerProps} from 'antd/es/date-picker';
import dayjs from 'dayjs';
import MediaCodes from "../../common/uploader/MediaCodes";
import MediaUtils from "../../common/uploader/MediaPath";

/**
 * 报销弹窗
 * @param props
 * @constructor
 */
export default function ServiceUserReimbursed(props: any) {

    const reason = props.importResult;
    const formRef = React.createRef<FormInstance>();
    const [feeData, setFeeData] = useState(defaultData.data);
    const cancel = () => {
        props.hide();
    }
    const ok = () => {
        props.hide();
        props.reload();
    }
    const submit = (values: any) => {
        buildFile(values)
        values.serviceId = reason;
        values.releaseTime = values.releaseTime.format('YYYY-MM-DD');
        Fetch.postJSON(API.cooperateServiceUsersStatusV2.update_reimbursement, values).then(() => {
            message.success('提交成功');
            ok();
        });
    }

    const buildFile = (values: any) => {

        const files: any[] = [];
        addFiles(files, values.voucher, 8);
        values.files = files;
        delete values.voucher;
    }

    const addFiles = (files: any[], data: any, type: number) => {
        data.forEach((item: { id: any; }) => {
            files.push({
                type: type,
                fileId: item.id
            })
        })
    }
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {

        return current && (current < dayjs().startOf('month') || current >= dayjs().endOf('month'))
    };
    useEffect(() => {
        Fetch.get(`${API.aierHospitalFee.get_detail}?id=${reason}`).then((data) => {
            const illnessForm = buildIllness(data);
            if (illnessForm) {
                data.fee.eyeDiseases = illnessForm.eyeDiseases;
                data.fee.cataractEyeType = illnessForm.cataractEyeType;
                data.fee.fundusEyeType = illnessForm.fundusEyeType;
                data.fee.gilliumEyeType = illnessForm.gilliumEyeType;
            }
            setFeeData(data)
        });
    }, []);

    return <>
        {feeData != null && feeData.fee.timeOfAdmission != '' ? (
            <>
                <Form onFinish={submit}
                      ref={formRef}>
                    <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                        <Card size="small" className={styles.mycard}
                              title={`费用信息`}>
                            <Descriptions>
                                <Descriptions.Item label="眼病">
                                    {feeData.fee.illnessName?.replaceAll(',', ' ')}
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="入院时间">
                                    {feeData.fee.timeOfAdmission}
                                </Descriptions.Item>
                                {feeData.fee.timeOfDischarge ? (
                                    <Descriptions.Item label="出院时间">
                                        {feeData.fee.timeOfDischarge}
                                    </Descriptions.Item>
                                ) : (
                                    <Descriptions.Item label="出院时间">
                                        未填写
                                    </Descriptions.Item>
                                )}
                                {/*<Descriptions.Item label="眼别">*/}
                                {/*    {eyeTypeArrShow[feeData.eyeType]}*/}
                                {/*</Descriptions.Item>*/}
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="总金额">
                                    {feeData.fee.totalPrice}
                                </Descriptions.Item>
                                <Descriptions.Item label="医保报销">
                                    {feeData.fee.medicareReimbursement}
                                </Descriptions.Item>
                                <Descriptions.Item label="报销前自付费用">
                                    {feeData.fee.selfPayPrice}
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="其他补助">
                                    {feeData.fee.otherGrants}
                                </Descriptions.Item>
                                <Descriptions.Item label="本项目资助">
                                    {feeData.fee.subsidy}
                                </Descriptions.Item>
                                <Descriptions.Item label="报销后自付费用">
                                    {feeData.fee.afterSelfPayPrice}
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                {feeData.fee.cataractEyeType && (
                                    <>
                                        <Descriptions.Item label="白内障眼别">
                                            {eyeTypeArrShow[feeData.fee.cataractEyeType]}
                                        </Descriptions.Item>
                                    </>
                                )}
                                {feeData.fee.fundusEyeType && (
                                    <>
                                        <Descriptions.Item label="眼底病眼别">
                                            {eyeTypeArrShow[feeData.fee.fundusEyeType]}
                                        </Descriptions.Item>
                                    </>
                                )}
                                {feeData.fee.gilliumEyeType && (
                                    <>
                                        <Descriptions.Item label="翼状胬肉眼别">
                                            {eyeTypeArrShow[feeData.fee.gilliumEyeType]}
                                        </Descriptions.Item>
                                    </>
                                )}
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label="备注">
                                    {feeData.fee.remark}
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>

                        <Card size="small" className={styles.mycard}
                              title={`上传凭证`}>
                            <Descriptions>
                                <Descriptions.Item>
                                    <FormItem
                                        className={styles.mylabel}
                                        name="releaseTime"
                                        label="补贴款发放时间"
                                        // labelCol={{span: 0}}
                                        wrapperCol={{span: 17}}
                                        rules={[{required: true, message: '必填'}]}
                                    >
                                        <DatePicker
                                            disabledDate={disabledDate}
                                            style={{width: '100%'}}/>
                                    </FormItem>
                                </Descriptions.Item>
                                <Descriptions.Item>
                                    <FormItem
                                        className={styles.mylabel}
                                        name="voucher"
                                        valuePropName="fileList"
                                        label="上传凭证"
                                        // labelCol={{span: 0}}
                                        wrapperCol={{span: 17}}
                                        rules={[{required: true, message: '必填'}]}
                                    >
                                        {/*报销上传凭证弹窗不需要刷新*/}
                                        <FileUploader
                                            multiple
                                            listType="picture"
                                            className="upload-list-inline"
                                            accept='*'
                                            checkSize={false}
                                            noChekckSizeMal={true}
                                            icon={<UploadOutlined/>}
                                            typeCode={MediaCodes.aier_file.code}
                                            onRefresh={MediaUtils.upLoadRefresh}
                                        />
                                    </FormItem>
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                className={styles.postSave}
                            >
                                保存
                            </Button>
                            <Button
                                size='large'
                                onClick={cancel}
                            >
                                取消
                            </Button>
                        </Space>
                    </Space>
                </Form>
            </>
        ) : ('')}
    </>;
}
