import {Component} from 'react';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import {DefaultRefreshFile, DetailClass, DetailRefreshFileType} from '../util/ServiceUserDetailTypes';
import {defaultData, refreshFile} from '../util/AierCommon'
import {decryptData} from "../../common/crypto";
import dayjs from "dayjs";
import {FileTypeTypes} from "../../common/modal/ViewFileListTypes";

class ServiceUserDetailParentClass<S = {}> extends Component {

    state: DetailClass = {
        id: decryptData(common.getQuery('id')),
        snap: decryptData(common.getQuery('snap')),
        data: defaultData.data,
        hospitalSelect: [],
        startTime: dayjs().valueOf(),
        refreshData: undefined,
        refreshCount: 0,
        spinning: true,
    };

    componentDidMount() {

    };

    refreshFile = (type: string) => {

        return new Promise((resolve, reject) => {
            this.refreshData().then((data: DetailRefreshFileType) => {
                resolve(data[type])
            })
        })
    }

    refreshFileOne = (type: string, mediaId: number) => {

        return new Promise((resolve, reject) => {
            this.refreshData().then((data: DetailRefreshFileType) => {
                const datum = data[type];
                datum.forEach((item: FileTypeTypes) => {
                    if (item.mediaId === mediaId) {
                        resolve(item.filePath);
                    }
                })
            })
        })
    }

    private refreshData = () => {

        const that = this;
        return new Promise<DetailRefreshFileType>((resolve, reject) => {
            const {id, snap, startTime, refreshData, refreshCount} = that.state;
            //开启时间
            const turnOnTime = (dayjs().valueOf() - startTime) / 1000;
            //刷新覆盖时间
            const refresh = refreshCount * API.fileMaxTime;
            if (turnOnTime - refresh > API.fileMaxTime || !refreshData) {
                let url = `${API.cooperateServiceUsers.refresh_file}?id=${id}`;
                if (snap === 'true') {
                    let bId = decryptData(common.getQuery('bId'));
                    url = `${API.service_users_snap.refresh_file}?id=${id}&bId=${bId}`;
                }
                that.setState({spinning: true}, () => {
                    Fetch.get(url).then((data) => {
                        console.log("刷新！！！！")
                        const refreshFiles = refreshFile(data);
                        const count = Math.floor(turnOnTime / API.fileMaxTime); // 输出 31
                        this.setState({
                            refreshData: refreshFiles,
                            refreshCount: count,
                            spinning: false
                        }, () => {
                            resolve(refreshFiles);
                        })
                    });
                })
            } else {
                resolve(refreshData);
            }
        })
    }
}

export default ServiceUserDetailParentClass;
