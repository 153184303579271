import React, {useState} from 'react';
import {Card, Col, Radio, Row, Select, Space} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import styles from "../../cooperate/hospitalauditv2/ServiceUserV2.module.css";
import FileUploader from "../../common/uploader";
import {UploadOutlined} from "@ant-design/icons";
import {EditBaseType} from "../common/edit/DhEditAndDetailPageConfig";
import MediaCodes from "../../common/uploader/MediaCodes";

const Option = Select.Option;

type EditFreeClinicsReportType = EditBaseType & {
    upLoadRefresh: (type: string, mediaId: number) => Promise<any>;
};

export default function EditFreeClinicsReport(props: EditFreeClinicsReportType) {

    const [dhOperation, setDhOperation] = useState<string>('1');

    const dhOperationChange = (e: any) => {

        setDhOperation(e.target.value)
    };

    return (
        <>
            <Card size="small" className={styles.mycard}
                  title={`义诊报告`}>

                <Row>
                    <Col span={24}>
                        <FormItem
                            className={styles.mylabel}
                            name="rSurgery"
                            label="是否接受手术"
                            rules={[{required: true, message: '必填'}]}
                        >
                            <Radio.Group options={props.dictInfo.dhOperationArray} onChange={dhOperationChange}>
                            </Radio.Group>
                        </FormItem>
                    </Col>
                </Row>
                {dhOperation === "2" && (
                    <Row>
                        <Col span={24}>
                            <FormItem
                                className={styles.mylabel}
                                name="rNoSurgeryReason"
                                label="不接受手术原因"
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Radio.Group options={props.dictInfo.noSurgeryReasonArray}>
                                </Radio.Group>
                            </FormItem>
                        </Col>
                    </Row>
                )}
                <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                    <Card style={{width: '100%'}}>
                        <FormItem
                            className={styles.mylabel}
                            name="rDiagnosticReports"
                            valuePropName="fileList"
                            label="义诊报告"
                            rules={[{required: true, message: '必填'}]}
                        >
                            {/*已刷新*/}
                            <FileUploader
                                multiple
                                listType="picture"
                                className="upload-list-inline"
                                accept='*'
                                checkSize={false}
                                noChekckSizeMal={true}
                                icon={<UploadOutlined/>}
                                typeCode={MediaCodes.dental_file.code}
                                onRefresh={props.upLoadRefresh}
                            />
                        </FormItem>
                    </Card>
                </Space>
            </Card>
        </>
    );
}
