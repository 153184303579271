import React from 'react';
import {Button, Card, Form, FormInstance, message, Modal, Space,} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {defaultDhDetailData, DhDefaultRefreshFile, DhDetailDataType} from "../common/DhDetailType";
import {decryptData} from "../../common/crypto";
import PatientInfo from "../patientDetail/PatientInfo";
import styles from "../../cooperate/hospitalauditv2/ServiceUserV2.module.css";
import EditFreeClinicsReport from "../editPatient/EditFreeClinicsReport";
import {defDictData, DictObjectType, getDictData} from "../common/DhDictData";
import DhWarVeteranInfo from "../patientDetail/DhWarVeteranInfo";
import DhAccountInfo from "../patientDetail/DhAccountInfo";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import dayjs from "dayjs";
import DhServiceUserParentClass from "../patientDetail/DhServiceUserParent";


type ReportType = DictObjectType & {

    dhOperation: string | ""
}

const {confirm} = Modal;

class DhHospitalUploadReportClass extends DhServiceUserParentClass<DhDetailDataType & ReportType> {


    formRef = React.createRef<FormInstance>();
    state: DhDetailDataType & ReportType = {
        id: decryptData(common.getQuery('id')),
        data: defaultDhDetailData.data,
        dictInfo: defDictData,
        dhOperation: "",
        startTime: dayjs().valueOf(),
        refreshData: DhDefaultRefreshFile(),
        refreshCount: 0,
        spinning: true,
        snap: '',
        bId: 0,
        booking: true,
    };

    componentDidMount() {

        getDictData().then((data) => {
            this.getData();
            this.setState({
                dictInfo: data
            })
        })
    };

    getData() {

        const {id} = this.state;
        let url = `${API.dentistry.dh_service_user_booking.do_get_patient_detail}?id=${id}`;
        Fetch.get(url).then((data) => {
            this.setState({
                data: data,
            });
        });
    }

    cancel = () => {
        window.history.go(-1);
    }
    back = () => {
        // @ts-ignore
        window.history.back(-1)
    }

    submit = (values: any) => {
        let that = this;
        confirm({
            title: `是否提交数据`,
            onOk() {
                let target: any = {};
                that.buildData(values, target);
                target.bookingId = that.state.id;
                that.create(target);
            }
        });
    }

    buildData = (values: any, target: any) => {

        target.surgery = values.rSurgery;
        target.noSurgeryReason = values.rNoSurgeryReason
        this.buildFile(values, target);
    }

    buildFile = (values: any, target: any) => {
        const files: any[] = [];
        this.addFiles(files, values.rDiagnosticReports, 1);
        target.files = files;
    }

    cleanField = (values: any) => {
        delete values.diagnosticReports;
    }

    addFiles = (files: any[], data: any, type: number) => {
        data.forEach((item: { id: any; }) => {
            files.push({
                type: type,
                fileId: item.id
            })
        })
    }

    update = (values: any) => {

        Fetch.postJSON(API.dentistry.dh_service_user_booking.do_upload_report, values).then(() => {
            message.success('提交成功');
            window.history.go(-1);
        });
    };

    create = (values: any) => {

        Fetch.postJSON(API.dentistry.dh_service_user_booking.do_upload_report, values).then(() => {
            message.success('提交成功');
            window.history.go(-1);
        });
    };

    // dhOperationChange = (e: any) => {
    //     this.setState({dhOperation: e.target.value})
    // }

    render() {
        const {data, dictInfo} = this.state;
        return (
            <div>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Form
                        onFinish={this.submit}
                        ref={this.formRef}
                    >
                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                            <PatientInfo userDetail={data?.userDetail} addressDetail={data?.addressDetail}
                                         warVeteran={data.warVeteran}/>
                            <DhWarVeteranInfo warVeteran={data.warVeteran}/>
                            <DhAccountInfo account={data.account}/>
                            <EditFreeClinicsReport form={this.formRef} dictInfo={dictInfo} upLoadRefresh={this.refreshFileOne}/>
                            <Card>
                                <Space>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                        className={styles.postSave}
                                    >
                                        保存
                                    </Button>
                                    <Button
                                        size='large'
                                        className={styles.backBtn}
                                        style={{marginRight: '15px'}}
                                        onClick={this.cancel}
                                    >
                                        取消
                                    </Button>
                                </Space>
                            </Card>
                        </Space>
                    </Form>
                </div>
            </div>
        );
    }
}

export default function DhHospitalUploadReport() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <DhHospitalUploadReportClass/>
        </App>
    );
}
