import {Component} from 'react';
import {AutoComplete, Col, DatePicker, Form, Input, Radio, Row, Select, Switch} from 'antd';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import FileUploader from '../../common/uploader';
import Editor from '../../common/editor/Editor';
import {LinkTable} from '../../gongyiActivity/LinkTable';
import './temp.css';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import MediaCodes from "../../common/uploader/MediaCodes";
import MediaUtils from "../../common/uploader/MediaPath";

dayjs.extend(customParseFormat);

const FormItem = Form.Item;
const Option = Select.Option;
const {RangePicker} = DatePicker;
export default class Active extends Component<any> {
    state = {
        categoryList: [],
        options: [],
        projectList: [],
        needSign: true,
        signRule: 1,
        needDonation: false,
        registerEndDate: null,
        signInEndDate: null
    };

    constructor(props: any) {
        super(props);
        // this.state = { needDonation: this.props.content.needDonation };
    }

    componentDidMount() {
        this.getCategorySelect();
    }

    // componentWillReceiveProps

    // onSearch = (searchText: String) => {
    //   this.props.onSearch(searchText, '');
    // };
    // 获取项目
    getSelect = (id: string) => {
        Fetch.get(`${API.atl.project.select}?categoryId=${id}`).then((data) => {
            this.setState({
                projectList: data,
            });
        });
    };
    //选项联动
    handleChange = (value: string) => {
        this.getSelect(value);
        const {form} = this.props;
        form.setFieldsValue({
            projectId: null,
        });
    };
    // 获取分类
    getCategorySelect = () => {
        Fetch.get(API.atl.category.select).then((data) => {
            this.setState({
                categoryList: data,
            });
        });
    };
    // 是否需要签到
    changeNeedSign = (value: boolean) => {
        this.setState({
            needSign: value,
        });
    };
    // 规则变化
    changeSignRule = (value: any) => {

        this.setState({
            signRule: value,
        });
    };
    // 收否需要募捐
    changeNeedDonation = (flas: boolean) => {
        this.setState({
            needDonation: flas,
        });
    };

    changeRegistrationTime = (e: any) => {
    }

    changeRegistered = (e: any) => {

    }

    changeSignIn = (e: any) => {
        // const {form} = this.props;
        // form.setFieldsValue({
        //     // date2: [moment('2021-01-01 12:15'), moment('2021-01-01 12:50')],
        // });
    }

    range = (start: number, end: number) => {
        const result: number[] = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }

    activityDisabledDate = (current: any) => {

        const {form} = this.props;
        const fieldsValue = form.getFieldValue("date1");
        if (fieldsValue && fieldsValue.length === 2) {
            return current <= dayjs(fieldsValue[1]);
        } else {
            return current <= dayjs().subtract(1, 'days');
        }
    }

    registerDisabledDate = (current: any) => {

        return current <= dayjs().subtract(1, 'days');
    }

    changeDate2 = (e: any, str: any) => {

        // const {form} = this.props;
        // const needDiff = form.getFieldValue("minDuration");
        // const start = moment(str[0]);
        // const end = moment(str[1]);
        // const diff = end.diff(start, 'hour');
        // if (diff < needDiff) {
        //     message.error('活动时间小于最小活动时长请调整');
        // }
    }


    render() {
        const {categoryList, projectList, needSign, signRule, needDonation} =
            this.state;
        const {disabledFlag, account} = this.props;
        const defaultTime = [dayjs('00:00', 'HH:mm'), dayjs('00:00', 'HH:mm')];


        return (
            <>
                <Row>
                    <Col span={8}>
                        <FormItem
                            name="categoryId"
                            label="所属类别"
                            labelCol={{span: 9}}
                            wrapperCol={{span: 15}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <Select
                                placeholder="请选择分类"
                                onChange={this.handleChange}
                                disabled={true}
                            >
                                {categoryList.map((item: any) => {
                                    return (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            name="projectId"
                            label="所属项目"
                            labelCol={{span: 9}}
                            wrapperCol={{span: 15}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <Select placeholder="请选择项目" disabled={true}>
                                {projectList.map((item: any) => {
                                    return (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <FormItem
                            name="credits"
                            label="对应积分"
                            rules={[{required: true, message: '必填'}]}
                            labelCol={{span: 12}}
                            wrapperCol={{span: 12}}
                        >
                            {account.credits}分
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem
                            name="workPoints"
                            label="工分"
                            rules={[{required: true, message: '必填'}]}
                            labelCol={{span: 12}}
                            wrapperCol={{span: 12}}
                        >
                            {account.workPoints}分
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem
                            name="duration"
                            label="对应服务时长"
                            rules={[{required: true, message: '必填'}]}
                            labelCol={{span: 14}}
                            wrapperCol={{span: 10}}
                        >
                            {account.duration}小时
                        </FormItem>
                    </Col>
                </Row>
                <FormItem
                    name="minDuration"
                    label="最小服务时长"
                    labelCol={{span: 3}}
                    wrapperCol={{span: 7}}
                >
                    {account.minDuration}小时
                </FormItem>

                <FormItem
                    name="date1"
                    label="报名时间"
                    labelCol={{span: 3}}
                    wrapperCol={{span: 7}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <RangePicker
                        disabled={disabledFlag || !account.date1Flag}
                        showTime={{
                            format: 'HH:mm',
                            hideDisabledOptions: true,
                            defaultValue: defaultTime,
                        }}
                        format="YYYY-MM-DD HH:mm"
                        disabledDate={this.registerDisabledDate}
                        // disabledTime={this.registerDisabledRangeTime}
                        onChange={this.changeRegistrationTime}
                        inputReadOnly
                        minuteStep={30}
                    />
                </FormItem>
                {/*<FormItem*/}
                {/*    name="signDate"*/}
                {/*    label="签到时间"*/}
                {/*    labelCol={{span: 3}}*/}
                {/*    wrapperCol={{span: 21}}*/}
                {/*    rules={[{required: true, message: '必填'}]}*/}
                {/*>*/}
                {/*    <RangePicker*/}
                {/*        showTime={{format: 'HH:mm'}}*/}
                {/*        format="YYYY-MM-DD HH:mm"*/}
                {/*        disabledDate={this.signInDisabledDate}*/}
                {/*        disabledTime={this.signInDisabledRangeTime}*/}
                {/*        onChange={this.changeSignIn}*/}
                {/*        minuteStep={30}*/}
                {/*    />*/}
                {/*</FormItem>*/}
                <FormItem
                    name="signRule"
                    label="签到规则"
                    labelCol={{span: 3}}
                    wrapperCol={{span: 21}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <Radio.Group
                        onChange={(event) => {
                            this.changeSignRule(event.target.value);
                        }}
                    >
                        <Radio value={1}>关联活动地址签到</Radio>
                        <Radio value={3}>使用数字签到</Radio>
                    </Radio.Group>
                </FormItem>

                {
                    signRule === 3 && (
                        <FormItem
                            name="signNumber"
                            label="签到ID"
                            labelCol={{span: 3}}
                            wrapperCol={{span: 6}}
                            rules={[
                                {required: true, message: '必填'},
                                {len: 4, message: '请输入4位数字!'},
                            ]}
                        >
                            <Input type="number"/>
                        </FormItem>
                    )
                }
                {
                    signRule === 1 && (
                        <FormItem
                            name="signRadius"
                            label="签到范围"
                            labelCol={{span: 3}}
                            wrapperCol={{span: 21}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <Radio.Group>
                                <Radio value={100}>100米范围</Radio>
                                <Radio value={200}>200米范围</Radio>
                                <Radio value={500}>500米范围</Radio>
                                <Radio value={1000}>1公里范围</Radio>
                            </Radio.Group>
                        </FormItem>
                    )
                }

                <FormItem
                    name="signInTime"
                    label="活动开始前多久可以签到"
                    labelCol={{span: 5}}
                    wrapperCol={{span: 5}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <Select placeholder="请选择签出时间">
                        <Option value={15}>15分钟 </Option>
                        <Option value={30}>30分钟 </Option>
                        <Option value={45}>45分钟 </Option>
                        <Option value={60}>60分钟 </Option>
                    </Select>
                </FormItem>

                <FormItem
                    name="signOutTime"
                    label="活动结束前多久可以签出"
                    labelCol={{span: 5}}
                    wrapperCol={{span: 5}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <Select placeholder="请选择签出时间">
                        <Option value={15}>15分钟 </Option>
                        <Option value={30}>30分钟 </Option>
                        <Option value={45}>45分钟 </Option>
                        <Option value={60}>60分钟 </Option>
                    </Select>
                </FormItem>
                <FormItem
                    name="date2"
                    label="活动时间"
                    labelCol={{span: 3}}
                    wrapperCol={{span: 7}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <RangePicker
                        disabled={disabledFlag || !account.date2Flag}
                        showTime={{
                            format: 'HH:mm',
                            hideDisabledOptions: true,
                            defaultValue: defaultTime,
                        }}
                        disabledDate={this.activityDisabledDate}
                        // disabledTime={this.activityDisabledRangeTime}
                        format="YYYY-MM-DD HH:mm"
                        minuteStep={30}
                        inputReadOnly
                        onChange={this.changeDate2}
                    />
                </FormItem>
                <FormItem
                    name="peopleNum"
                    label="活动招募人数"
                    labelCol={{span: 3}}
                    wrapperCol={{span: 7}}
                    rules={[
                        {required: true, message: '必填'},
                        {
                            pattern: new RegExp(/^[1-9]\d*$/, 'g'),
                            message: '招募人数不能小于1',
                        },
                    ]}
                >
                    <Input type="number" min="0"/>
                </FormItem>
                <FormItem
                    name="username"
                    label="活动负责人"
                    labelCol={{span: 3}}
                    wrapperCol={{span: 7}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <Input/>
                </FormItem>
                <FormItem
                    name="phone"
                    label="电话"
                    labelCol={{span: 3}}
                    wrapperCol={{span: 7}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <Input/>
                </FormItem>
                <FormItem
                    name="job"
                    label="职务"
                    labelCol={{span: 3}}
                    wrapperCol={{span: 7}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <Input/>
                </FormItem>
                <FormItem
                    name="address"
                    label="活动地址"
                    labelCol={{span: 3}}
                    wrapperCol={{span: 7}}
                    rules={[{required: true, message: '必填'}]}
                >
                    <AutoComplete
                        disabled={disabledFlag || !account.addressFlag}
                        options={this.props.options}
                        onSearch={this.props.onSearch}
                    />
                </FormItem>

                {/* <Row>
          <Col span={8}>
            <FormItem
              name="needSign"
              label="是否需要签到"
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 25 }}
              rules={[{ required: true, message: '必填' }]}
              valuePropName="checked"
            >
              <Switch onChange={this.changeNeedSign} />
            </FormItem>
          </Col>
        </Row> */
                }

                <FormItem
                    label="活动封面"
                    name="covers"
                    valuePropName="fileList"
                    labelCol={{span: 3}}
                    wrapperCol={{span: 18}}
                    rules={[{required: true, message: '必填'}]}
                >
                    {/*无需刷新 已验证*/}
                    <FileUploader
                        disabled={disabledFlag || !account.coverIdsFlag}
                        multiple
                        maxCount={1}
                        listType="picture-card"
                        checkSize={false}
                        desc="支持扩展名：.jpg、.png; 图片大小不能超过2M; 建议尺寸375 * 210; 最多1张"
                        typeCode={MediaCodes.gongyi_pub.code}
                        onRefresh={MediaUtils.upLoadRefresh}
                    />
                </FormItem>

                <FormItem
                    labelCol={{span: 3}}
                    wrapperCol={{span: 18}}
                    label="活动介绍"
                    name="content"
                    rules={[{required: true, message: '必填'}]}
                >
                    <Editor
                        disabled={disabledFlag || !account.contentFlag}
                        url={API.upload}
                    />
                </FormItem>
                <FormItem
                    name="needDonation"
                    label="开启募捐"
                    labelCol={{span: 3}}
                    wrapperCol={{span: 18}}
                    valuePropName="checked"
                >
                    <Switch onChange={this.changeNeedDonation}/>
                </FormItem>

                {
                    needDonation && (
                        <FormItem
                            name="linkDonation"
                            label="开启募捐活动"
                            labelCol={{span: 3}}
                            wrapperCol={{span: 18}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <LinkTable selectionType="radio"/>
                        </FormItem>
                    )
                }
                <FormItem
                    name="relatedDonations"
                    label="关联募捐活动"
                    labelCol={{span: 3}}
                    wrapperCol={{span: 18}}
                >
                    <LinkTable selectionType="checkbox"/>
                </FormItem>
                {/* <Row>
          <Col span={8}></Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
        </Row> */
                }
            </>
        )
            ;
    }
}
