import React from 'react';
import {Button, Card, Col, Form, FormInstance, message, Modal, Row, Space, Spin} from 'antd';
import API from '../../../common/api';
import 'dayjs/locale/zh-cn';
import App from '../../../../App';
import {decryptData} from '../../../common/crypto';
import common from "../../../common/common";
import Fetch from "../../../common/FetchIt";
import {DefaultRefreshFile, HospitalBillDetail} from "../hospitalbilldetail/types/HospitalBillType";
import {aireBillRefreshData, billDetailDefault, showFile} from "../hospitalbilldetail/types/HospitalDetailCommon";
import BillInfo from "../hospitalbilldetail/BillInfo";
import BillDetailInfo from "../hospitalbilldetail/BillDetailInfo";
import FormItem from 'antd/lib/form/FormItem';
import FileUploader from "../../../common/uploader";
import {UploadOutlined} from '@ant-design/icons';
import BillFileInfo from "../hospitalbilldetail/BillFileInfo";
import BillAccountInfo from "../hospitalbilldetail/BillAccountInfo";
import BreadcrumbInfo from "../../../common/breadcrumb/BreadcrumbInfo";
import {getKey} from "../../../common/breadcrumb/BreadcrumbUtil";
import MediaPath from "../../../common/uploader/MediaPath";
import MediaCodes from "../../../common/uploader/MediaCodes";
import dayjs from "dayjs";
import ServiceUserDetailParentClass from "../hospitalbilldetail/ServiceUserBillParent";


const {confirm} = Modal;
type User = {};

type BillReceipt = {
    tip: string | undefined
}

/**
 * 上传收据页面
 */
class HospitalBillReceiptClass extends ServiceUserDetailParentClass<HospitalBillDetail & BillReceipt> {

    formRef = React.createRef<FormInstance>();
    state: HospitalBillDetail & BillReceipt = {
        billId: decryptData(common.getQuery('billId')),
        data: billDetailDefault.data,
        tip: "加载中...",
        startTime: dayjs().valueOf(),
        refreshData: DefaultRefreshFile(),
        refreshCount: 0,
        spinning: true
    };

    componentDidMount() {

        this.getData();
    }

    getData() {

        const {billId} = this.state;
        this.setState({
            tip: "数据加载中..."
        }, () => {
            let url = `${API.service_user_bill.get_bill_detail}?billId=${billId}`;
            Fetch.get(url).then((data: any) => {
                showFile(data);
                const refreshData = aireBillRefreshData(data.files);
                this.setState({
                    data: data,
                    importLoading: false,
                    refreshData: refreshData,
                    spinning: false
                });
                // @ts-ignore
                this.formRef.current.setFieldsValue({
                    ...data.fileClassify
                })
            }).catch(() => {
                this.setState({
                    importLoading: false
                });
            });
        });
    }

    cancel = () => {
        window.history.go(-1);
    }
    back = () => {
        // @ts-ignore
        window.history.back(-1)
    }
    submit = (values: any) => {

        let that = this;
        confirm({
            title: `是否提交收据文件`,
            onOk() {
                that.create(values);
            }
        });
    };

    create = (values: any) => {

        const {billId} = this.state;
        this.buildFile(values);
        values.billId = billId;
        this.cleanField(values);
        Fetch.postJSON(API.service_user_bill.update_receipt_complete, values).then(() => {
            message.success('提交成功');
            window.history.go(-1);
        });
    };

    buildFile = (values: any) => {

        const files: any[] = [];
        this.addFiles(files, values.receipt, 3);
        values.fileList = files;
    }

    addFiles = (files: any[], data: any, type: number) => {
        data.forEach((item: { id: any; }) => {
            files.push({
                type: type,
                fileId: item.id
            })
        })
    }

    cleanField = (values: any) => {
        delete values.receipt;
    }

    downloadCreateReceipt = (values: any) => {
        const {billId, data} = this.state;
        this.setState({
            importLoading: true,
            tip: "文件下载中..."
        }, () => {
            Fetch.get(`${API.service_user_bill.create_receipt}?billId=${billId}`).then((result) => {
                const currentTimeStamp = Math.floor(Date.now() / 1000);
                let fileName = data.accountVo.name + "(" + data.billDetail.billMonth + ")收据" + currentTimeStamp;
                common.downloadFile(fileName + ".pdf", MediaPath.getFileUrl(result), (callback: any) => {
                    if (callback === true) {
                        message.success('处理完成').then(r => {
                            this.getData();
                        });
                    } else {
                        message.error('下载失败，请联系管理员').then(r => {
                            this.getData();
                        });
                    }
                    this.setState({
                        importLoading: false,
                    });
                });
            }).catch(() => {
                this.setState({
                    importLoading: false,
                });
            });
        })
    }

    render() {
        const {
            billId, data
        } = this.state;
        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.spinning}>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                            <BillInfo data={data}/>
                            <BillAccountInfo data={data}/>
                            <BillFileInfo data={data} onRefresh={this.refreshFile}/>
                            <Card size="small"
                                  title={`上传收据`}
                            >
                                <Form
                                    onFinish={this.submit}
                                    ref={this.formRef}
                                >
                                    <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                        <Row gutter={20}>
                                            {data.billDetail.receiptTime !== null && (
                                                <Col span={10}>
                                                    <FormItem
                                                        name="receipt"
                                                        valuePropName="fileList"
                                                        label="收据"
                                                        labelCol={{span: 4}}
                                                        wrapperCol={{span: 15}}
                                                        rules={[{required: true, message: '必填'}]}
                                                    >
                                                        {/*已刷新*/}
                                                        <FileUploader
                                                            multiple
                                                            listType="picture"
                                                            className="upload-list-inline"
                                                            accept='*'
                                                            checkSize={false}
                                                            noChekckSizeMal={true}
                                                            icon={<UploadOutlined/>}
                                                            typeCode={MediaCodes.aier_file.code}
                                                            onRefresh={this.refreshFileOne}
                                                        />
                                                    </FormItem>
                                                </Col>
                                            )}
                                            <Col span={10}>
                                                <Button
                                                    type="primary"
                                                    size="large"
                                                    onClick={this.downloadCreateReceipt}
                                                >
                                                    下载收据文件
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Space>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                size="large"
                                            >
                                                保存
                                            </Button>
                                            <Button
                                                // type="primary"
                                                size="large"
                                                onClick={this.cancel}
                                            >
                                                返回
                                            </Button>
                                        </Space>
                                    </Space>
                                </Form>
                            </Card>
                            <BillDetailInfo billId={billId}/>
                        </Space>
                    </div>
                </Spin>
            </>
        );
    }
}

export default function HospitalBillReceipt() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <HospitalBillReceiptClass/>
        </App>
    );
}
