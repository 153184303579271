import React, {useEffect, useRef, useState} from 'react';
import 'dayjs/locale/zh-cn';
import {Breadcrumb, Button, Checkbox, Col, Form, FormInstance, Input, message, Radio, Row, Space, Spin,} from 'antd';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import {useHistory, useParams} from 'react-router-dom';
import App from '../../../App';
import Active from './active';
import Donation from './donation';
import dayjs from "dayjs";
import MediaPath from "../../common/uploader/MediaPath";

const FormItem = Form.Item;
const formRef = React.createRef<FormInstance<any>>();

//-----------组件-------
function Template() {
    const activeRef = useRef(null);
    const donationRef = useRef(null);
    const [options, setoptions] = useState<any>([]);
    const [itemType, setType] = useState<any>(1);
    const [spinningFlag, setSpinning] = useState<boolean>(false);
    // 禁用表单
    const [disableFlag, setDisable] = useState<boolean>(false);
    const {id} = useParams<{ id: string }>();
    const {detail} = useParams<{ detail: string }>();
    const history = useHistory();
    let changed = false;
    const [account, setAccount] = useState<any>({
        name: '',
        type: 1,
        remember: false,
        id: id === '0' ? null : id,
    });
    const [projectList, setProjectList] = useState<[]>([]);
    // const [componentDisabled, setComponentDisabled] = useState<boolean>(true);
    // 生命周期
    useEffect(
        function () {
            // onSearch('', setoptions);
            if (id === '0') {
                return;
            }
            if (detail === '1') {
                setDisable(true);
            }
            setSpinning(true);
            Fetch.get(`${API.atl.template.get_detail}?id=${id}`).then((data) => {
                let content = JSON.parse(data.content);
                const covers = content.covers.map((item: any) => {
                    return {
                        id: item.id,
                        uid: item.id,
                        status: 'done',
                        name: item.fileName,
                        url: MediaPath.getFileUrl(item.url),
                        filePath: MediaPath.getFileUrl(item.url),
                        mediaId: item.id,
                    };
                });
                content = {
                    ...content,
                    id: id,
                    date1: [
                        dayjs(content.registerStartDate),
                        dayjs(content.registerEndDate),
                    ],
                    date2: [dayjs(content.startDate), dayjs(content.endDate)],
                    covers,
                };
                setAccount(content);

                setType(content.type);
                form.setFieldsValue(content);
                setSpinning(false);

                if (content.projectId) {
                    getSelect(content.categoryId);
                    // if (itemType === '1') {
                    //   // @ts-ignore
                    //   // activeRef.current.getSelect(content.categoryId);
                    //   this.getSelect()
                    // } else {
                    //   this.getSelect();
                    //   // @ts-ignore
                    //   // donationRef.current.getSelect(content.categoryId);
                    // }
                }
            });
        },
        [id, detail]
    );
    //-----------取消-------
    const cancle = () => {
        history.goBack();
    };
    // 获取项目
    const getSelect = (id: string) => {
        Fetch.get(`${API.atl.project.select}?categoryId=${id}`).then((data) => {
            setProjectList(data);
        });
    };
    // ------------提交-----------
    const submit = (values: any) => {
        if (values.type === 1) {
            if (values.date2[0].isBefore(values.date1[1]) || changed) {
                message.error('活动开始时间不能早于活动报名结束时间');
                return;
            }
            const address = options.find(
                (item: any) => item.value === values.address
            );

            if (address) {
                values.lat = address.location.lat;
                values.lng = address.location.lng;
            } else if (!values.id || changed) {
                message.error('您输入的地址不正确，请通过下拉列表选择输入');
                return;
            } else {
                values.lat = account.lat;
                values.lng = account.lng;
            }
            // return;
            values.registerStartDate = values.date1[0].format('YYYY-MM-DD HH:mm');
            values.registerEndDate = values.date1[1].format('YYYY-MM-DD HH:mm');
        }
        values.startDate = values.date2[0].format('YYYY-MM-DD HH:mm');
        values.endDate = values.date2[1].format('YYYY-MM-DD HH:mm');

        values.covers = values.covers.map((item: any) => {
            let url = item.url;
            if (item.url.indexOf('http') !== -1) {
                const urlObj = new URL(url);
                url = urlObj.pathname;
            }
            return {id: item.id, fileName: item.fileName, url: url};
        });
        values.coverIds = values.covers.map((item: any) => item.id).join(',');
        values.content = values.content.toHTML
            ? values.content.toHTML()
            : values.content;
        if (values.content === '<p></p>') {
            message.error('内容不能为空！');
            return;
        }

        // values.signStartDate = values.signDate[0].format('YYYY-MM-DD HH:mm');
        // values.signEndDate = values.signDate[1].format('YYYY-MM-DD HH:mm');

        // return;
        if (id === '0') {
            create(values);
        } else {
            update(values);
        }
    };
    const create = (values: any) => {
        const {
            title,
            type,
            projectId,
            categoryId,
            credits,
            workPoints,
            duration,
        } = values;
        let data = {
            name: title,
            type,
            projectId,
            categoryId,
            credits,
            workPoints,
            duration,
            templateContent: JSON.stringify(values),
        };

        Fetch.postJSON(API.atl.template.create, data).then((data) => {
            message.success('添加成功');
            history.goBack();
        });
    };
    const update = (values: any) => {
        const {
            title,
            type,
            projectId,
            categoryId,
            credits,
            workPoints,
            duration,
        } = values;

        let data = {
            id,
            name: title,
            type,
            projectId,
            categoryId,
            credits,
            workPoints,
            duration,
            templateContent: JSON.stringify(values),
        };

        Fetch.put(API.atl.template.update, data).then((data) => {
            message.success('修改成功');
            history.goBack();
        });
    };
    // 切换模版类型
    const changeType = (e: any) => {
        setType(e.target.value);
    };
    // 搜索地址
    const onSearch = (searchText: String) => {
        changed = true;
        if (searchText && searchText.trim().length > 0) {
            Fetch.get(`${API.location}?keyword=${searchText.trim()}`).then((data) => {
                const result = JSON.parse(data);
                if (result.status === 0) {
                    const options = result.data.map((item: any) => {
                        return {
                            label: item.title + ' ' + item.address,
                            value: item.title + ' ' + item.address,
                            location: item.location,
                        };
                    });
                    setoptions(options);
                }
            });
        }
    };
    const [form] = Form.useForm();

    return (
        <>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>公益活动库</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a href="/#/atltemplate">模版</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {id === '0' ? '新建模版' : disableFlag ? '详情' : '修改模版'}
                </Breadcrumb.Item>
            </Breadcrumb>
            <div
                className="site-layout-background"
                style={{padding: 24, minHeight: 720}}
            >
                <Spin tip="Loading..." spinning={spinningFlag}>
                    <Form
                        initialValues={account}
                        onFinish={submit}
                        ref={formRef}
                        form={form}
                    >
                        <FormItem wrapperCol={{span: 6, offset: 18}} className="center">
                            <Button onClick={cancle} style={{marginRight: '15px'}}>
                                取 消
                            </Button>
                            {!disableFlag ? (
                                <Button type="primary" htmlType="submit">
                                    确 定
                                </Button>
                            ) : (
                                ''
                            )}
                        </FormItem>
                        <FormItem hidden noStyle name="id">
                            <Input/>
                        </FormItem>
                        <Row>
                            <Col span={8}>
                                <FormItem
                                    name="title"
                                    label="活动名称"
                                    rules={[{required: true, message: '必填'}]}
                                    labelCol={{span: 6}}
                                    wrapperCol={{span: 18}}
                                >
                                    <Input
                                        placeholder="在此输入活动名称"
                                        disabled={disableFlag}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    name="remember"
                                    valuePropName="checked"
                                    wrapperCol={{offset: 1, span: 16}}
                                >
                                    <Checkbox disabled={disableFlag}>
                                        允许组织创建活动时修改
                                    </Checkbox>
                                </FormItem>
                            </Col>
                        </Row>

                        <FormItem
                            name="type"
                            label="活动类型"
                            labelCol={{span: 2}}
                            wrapperCol={{span: 22}}
                        >
                            <Radio.Group onChange={changeType} disabled={disableFlag}>
                                <Space direction="vertical">
                                    {id === '0' ? (
                                        <>
                                            <Radio value={1}>公益活动</Radio>
                                            <Radio value={2}>
                                                <span>公益募捐</span>
                                                {itemType === 2 ? (
                                                    <Space>
                                                        <FormItem
                                                            style={{marginBottom: 0}}
                                                            name="number"
                                                            rules={[{required: true, message: '必填'}]}
                                                        >
                                                            <Input
                                                                placeholder="募捐编号必填"
                                                                disabled={disableFlag}
                                                            />
                                                        </FormItem>
                                                    </Space>
                                                ) : (
                                                    ''
                                                )}
                                            </Radio>
                                        </>
                                    ) : itemType === 1 ? (
                                        <Radio value={1}>公益活动</Radio>
                                    ) : (
                                        <Radio value={2}>
                                            <span>公益募捐 </span>

                                            <Space>
                                                <FormItem
                                                    style={{marginBottom: 0}}
                                                    name="number"
                                                    rules={[{required: true, message: '必填'}]}
                                                >
                                                    <Input
                                                        placeholder="募捐编号必填"
                                                        disabled={disableFlag}
                                                    />
                                                </FormItem>
                                            </Space>
                                        </Radio>
                                    )}
                                </Space>
                            </Radio.Group>
                        </FormItem>

                        {/************************** 活动/募捐 **************************/}
                        {itemType === 1 ? (
                            <Active
                                onSearch={onSearch}
                                disabledFlag={disableFlag}
                                options={options}
                                form={form}
                                // ref={activeRef}
                                projectList={projectList}
                                getSelect={getSelect}
                            />
                        ) : (
                            <Donation
                                // ref={donationRef}
                                projectList={projectList}
                                getSelect={getSelect}
                                onSearch={onSearch}
                                disabledFlag={disableFlag}
                                options={options}
                                form={form}
                            />
                        )}

                        <FormItem className="center">
                            <Button
                                onClick={cancle}
                                style={{marginRight: '15px'}}
                                size="large"
                            >
                                取 消
                            </Button>
                            {!disableFlag ? (
                                <Button type="primary" htmlType="submit" size="large">
                                    确 定
                                </Button>
                            ) : (
                                ''
                            )}
                        </FormItem>
                    </Form>
                </Spin>
            </div>
        </>
    );
}

export default function TemplateDetail() {
    return (
        <App selectedKeys={['atltemplate']} openKeys="atl">
            <Template/>
        </App>
    );
}
