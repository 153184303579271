import {FileTypeTypes} from "../../../../common/modal/ViewFileListTypes";

export type HospitalBillDetail = {
    billId: number | 0,
    startTime: number | 0,
    refreshData: BillDetailRefreshFileType
    refreshCount: number,
    spinning: boolean,
    data: {
        billDetail: {
            code: string | '',
            billMonth: string | '',
            hospitalId: number | 0,
            accountName: string | '',
            accountNum: string | '',
            billStatus: number | 0,
            numUser: number | 0,
            totalRevenue: number | 0,
            totalHealthInsurance: number | 0,
            totalOtherGrants: number | 0,
            totalSubsidy: number | 0,
            totalSelfPay: number | 0,
            hospitalName: string | ''
            receiptTime: string | null,
        },
        detailVos: [
            {
                billId: number | 0,
                hospitalId: number | 0,
                serviceId: number | 0,
                userName: string | '',
                gender: number | 0,
                economicSituation: string | '',
                timeOfAdmission: string | '',
                timeOfDischarge: string | '',
                eyeType: number | 0,
                totalPrice: number | 0,
                medicareReimbursement: number | 0,
                selfPayPrice: number | 0,
                otherGrants: number | 0,
                subsidy: number | 0,
            }
        ],
        files: [
            {
                fileId: number | 0,
                type: number | 0,
                name: string | '',
                url: string | ''
            }
        ],
        fileClassify: {
            hasFile: boolean | false,
            stampBill: FileTypeTypes[],
            proofReimbursement: FileTypeTypes[],
            receipt: FileTypeTypes[],
            billFile: FileTypeTypes[],
        },
        accountVo: {
            name: string | '',
            accountBank: string | '',
            accountNum: string | '',
            bankRnt: string | ''
        },
    }
}

export type BillDetailCommonType = {

    data: HospitalBillDetail["data"]
}

export type BillDetailRefreshFileType = {

    stampBill: FileTypeTypes[],
    proofReimbursement: FileTypeTypes[],
    receipt: FileTypeTypes[],
    billFile: FileTypeTypes[],
}


export function DefaultRefreshFile(): BillDetailRefreshFileType {
    return {
        stampBill: [],
        proofReimbursement: [],
        receipt: [],
        billFile: [],
    }
}
