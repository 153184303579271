module.exports = {
    //local
    // pathDomain: 'http://127.0.0.1:8099',
    // privCdn: 'https://spriv1.lelingzhiyuan.com',
    // pubCdn: 'https://spub1.lelingzhiyuan.com',
    // env: 'local',
    // oldCdn: 'https://appcdn.lelingzhiyuan.com',
    // privBucket: 'spriv1-1309224092',
    // pubBucket: 'spub1-1309224092',
    //sit
    pathDomain: 'https://test.lelingzhiyuan.com',
    privCdn: 'https://spriv1.lelingzhiyuan.com',
    pubCdn: 'https://spub1.lelingzhiyuan.com',
    oldCdn: 'https://appcdn.lelingzhiyuan.com',
    privBucket: 'spriv1-1309224092',
    pubBucket: 'spub1-1309224092',
    //prod
    // pathDomain: 'https://app.lelingzhiyuan.com',
    // cdnDomain: 'https://appcdn.lelingzhiyuan.com/',


    staticImg: 'https://appcdn.lelingzhiyuan.com/static'
}
