import React from 'react';
import {Button, Card, Col, Form, FormInstance, message, Modal, Row, Space, Spin} from 'antd';
import API from '../../../common/api';
import 'dayjs/locale/zh-cn';
import App from '../../../../App';
import {decryptData} from '../../../common/crypto';
import common from "../../../common/common";
import Fetch from "../../../common/FetchIt";
import {DefaultRefreshFile, HospitalBillDetail} from "../hospitalbilldetail/types/HospitalBillType";
import {aireBillRefreshData, billDetailDefault, showFile} from "../hospitalbilldetail/types/HospitalDetailCommon";
import BillInfo from "../hospitalbilldetail/BillInfo";
import BillDetailInfo from "../hospitalbilldetail/BillDetailInfo";
import FormItem from 'antd/lib/form/FormItem';
import FileUploader from "../../../common/uploader";
import {UploadOutlined} from '@ant-design/icons';
import BillFileInfo from "../hospitalbilldetail/BillFileInfo";
import BillAccountInfo from "../hospitalbilldetail/BillAccountInfo";
import BreadcrumbInfo from "../../../common/breadcrumb/BreadcrumbInfo";
import {getKey} from "../../../common/breadcrumb/BreadcrumbUtil";
import MediaCodes from "../../../common/uploader/MediaCodes";
import dayjs from "dayjs";
import ServiceUserDetailParentClass from "../hospitalbilldetail/ServiceUserBillParent";


const {confirm} = Modal;
type User = {};

class HospitalBillMaterialsClass extends ServiceUserDetailParentClass<HospitalBillDetail> {

    formRef = React.createRef<FormInstance>();
    state: HospitalBillDetail = {
        billId: decryptData(common.getQuery('billId')),
        data: billDetailDefault.data,
        startTime: dayjs().valueOf(),
        refreshData: DefaultRefreshFile(),
        refreshCount: 0,
        spinning: true
    };

    componentDidMount() {

        this.getData();
    }

    getData() {

        const {billId} = this.state;
        let url = `${API.service_user_bill.get_bill_detail}?billId=${billId}`;
        Fetch.get(url).then((data: any) => {
            showFile(data);
            const refreshData = aireBillRefreshData(data.files);
            this.setState({
                data: data,
                refreshData: refreshData,
                spinning: false
            });
            // @ts-ignore
            this.formRef.current.setFieldsValue({
                ...data.fileClassify
            })
        });
    }

    cancel = () => {
        window.history.go(-1);
    }
    back = () => {
        // @ts-ignore
        window.history.back(-1)
    }
    submit = (values: any) => {

        let that = this;
        confirm({
            title: `是否提交材料文件`,
            onOk() {
                that.create(values);
            }
        });
    };

    create = (values: any) => {

        const {billId} = this.state;
        this.buildFile(values);
        values.billId = billId;
        this.cleanField(values);
        Fetch.postJSON(API.service_user_bill.update_upload_material, values).then(() => {
            message.success('提交成功');
            window.history.go(-1);
        });
    };

    buildFile = (values: any) => {

        const files: any[] = [];
        this.addFiles(files, values.stampBill, 1);
        values.fileList = files;
    }

    addFiles = (files: any[], data: any, type: number) => {
        data.forEach((item: { id: any; }) => {
            files.push({
                type: type,
                fileId: item.id
            })
        })
    }

    cleanField = (values: any) => {
        delete values.stampBill;
    }

    render() {
        const {
            billId, data, spinning
        } = this.state;
        return (
            <>
                {/*<Breadcrumb style={{margin: '16px 0'}}>*/}
                {/*    <Breadcrumb.Item>*/}
                {/*        <Link to="/serviceUserBill/hos_query_page">*/}
                {/*            月度账单*/}
                {/*        </Link>*/}
                {/*    </Breadcrumb.Item>*/}
                {/*    <Breadcrumb.Item>上传盖章账单</Breadcrumb.Item>*/}
                {/*</Breadcrumb>*/}
                <Spin tip="加载中..." spinning={spinning}>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                        <BillInfo data={data}/>
                        <BillAccountInfo data={data}/>
                        <BillFileInfo data={data} onRefresh={this.refreshFile}/>
                        <Card size="small"
                              title={`上传材料`}
                        >
                            <Form
                                onFinish={this.submit}
                                ref={this.formRef}
                            >
                                <Row gutter={20}>
                                    <Col span={10}>
                                        <FormItem
                                            name="stampBill"
                                            valuePropName="fileList"
                                            label="盖章材料"
                                            labelCol={{span: 4}}
                                            wrapperCol={{span: 15}}
                                            rules={[{required: true, message: '必填'}]}
                                        >
                                            {/*已刷新*/}
                                            <FileUploader
                                                multiple
                                                listType="picture"
                                                className="upload-list-inline"
                                                accept='*'
                                                checkSize={false}
                                                noChekckSizeMal={true}
                                                icon={<UploadOutlined/>}
                                                typeCode={MediaCodes.aier_file.code}
                                                onRefresh={this.refreshFileOne}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Space>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                    >
                                        保存
                                    </Button>
                                    <Button
                                        // type="primary"
                                        size="large"
                                        onClick={this.cancel}
                                    >
                                        返回
                                    </Button>
                                </Space>
                            </Form>
                        </Card>
                        <BillDetailInfo billId={billId}/>
                    </Space>
                </div>
                </Spin>
            </>
        );
    }
}

export default function HospitalBillMaterials() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <HospitalBillMaterialsClass/>
        </App>
    );
}
