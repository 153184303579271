import {useEffect, useState} from 'react';
import {Breadcrumb, Button, Col, message, Modal, Row, Space, Table,} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';

import type {ColumnsType} from 'antd/es/table';
import Fetch from '../common/FetchIt';
import API from '../common/api';

import App from '../../App';
import MediaPath from "../common/uploader/MediaPath";

const {confirm} = Modal;

function GoodsList1() {
    const [page, setPage] = useState(0);
    const pageSize = 10;
    const [totalElements, setTotalElements] = useState();
    const [totalPage, setTotalPage] = useState();
    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(
        function () {
            loadData();
        },
        [page]
    );
    const Add = function () {
        window.location.href = '/#/goodsedit';
    };
    const Edit = function (row: any) {
        window.location.href = '/#/goodsedit?id=' + row.id;
    };
    const View = function (row: any) {
        window.location.href = '/#/goodsview?id=' + row.id;
    };
    const DelFn = function (row: any) {
        confirm({
            title: '您确定要删除下面的商品么?',
            icon: <ExclamationCircleOutlined/>,
            content: row.name,
            onOk() {
                Fetch.put(API.goods.goodsDel, {id: row.id}).then((data) => {
                    // setTotalTotalPages(data.totalPages),
                    message.success('删除成功！');
                    if (totalPage === page + 1 && content.length === 1 && page !== 0) {
                        setPage(page - 1);
                    } else {
                        loadData();
                    }
                });
            },
            onCancel() {
            },
        });
    };
    const loadData = function () {
        Fetch.get(`${API.goods.list}?page=${page}&size=${pageSize}`).then(
            (data) => {
                setContent(data.content);
                setTotalElements(data.totalElements);
                setLoading(false);
                setTotalPage(data.totalPages);
                // setTotalTotalPages(data.totalPages),
            }
        );
    };
    const changeCondition = function (condition: any, load = false) {
        setPage(condition.page);
        setLoading(true);
        // loadData();
    };

    //  表头
    interface DataType {
        code: string;
        name: string;
        createdDate: string;
        countProduct: string;
    }

    const columns: ColumnsType<DataType> = [
        {
            title: '封面图',
            dataIndex: 'coverUrl',
            align: `center`,
            render: (text: string) => {
                return (
                    <img
                        src={MediaPath.getFileUrl(text)}
                        alt="封面图"
                        style={{width: '50px', height: '30px'}}
                    />
                );
            },
        },
        {
            title: '商品名称',
            dataIndex: 'name',
            align: `center`,
        },
        {
            title: '兑换规则',
            dataIndex: 'convertibleDivide',
            align: `center`,
            render: (text: any, row: any) => {
                return row.convertibleType === 1 ? text + '工分' : text + '积分';
            },
        },
        {
            title: '商品价值',
            dataIndex: 'worth',
            align: `center`,
            render: (text: any) => {
                return text + '元';
            },
        },
        {
            title: '商品库存',
            dataIndex: 'stock',
            align: `center`,
        },
        {
            title: '供应商',
            dataIndex: 'pointsMallSupplierName',
            align: `center`,
        },
        {
            title: '商品编号',
            dataIndex: 'code',
            align: `center`,
        },
        {
            title: '创建时间',
            dataIndex: 'createdDate',
            align: `center`,
        },
        {
            title: '操作',
            align: `center`,
            render: (text: any, record: any) => {
                return (
                    <Space>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                View(record);
                            }}
                        >
                            查看
                        </Button>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                Edit(record);
                            }}
                        >
                            编辑
                        </Button>
                        <Button
                            type="link"
                            size="small"
                            danger
                            onClick={() => {
                                DelFn(record);
                            }}
                        >
                            删除
                        </Button>
                    </Space>
                );
            },
        },
    ];
    return (
        <>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>商品管理</Breadcrumb.Item>
            </Breadcrumb>
            <div
                className="site-layout-background"
                style={{padding: 24, minHeight: 720}}
            >
                <Row>
                    <Col span={6}></Col>
                    <Col span={18} style={{textAlign: 'right'}}>
                        <Button type="primary" onClick={Add}>
                            新 建
                        </Button>
                    </Col>
                </Row>

                <Table
                    className="mgTop15"
                    bordered
                    loading={loading}
                    rowKey={(record: any) => {
                        return record.code;
                    }}
                    columns={columns}
                    dataSource={content}
                    pagination={{
                        current: page + 1,
                        pageSize,
                        total: totalElements,
                        showTotal: (total) => `共${total}条`,
                        showSizeChanger: false,
                    }}
                    onChange={(pagination: any) =>
                        changeCondition(
                            {
                                page: pagination.current - 1,
                                pageSize: pagination.pageSize,
                            },
                            true
                        )
                    }
                />
            </div>
        </>
    );
}

export default function GoodsList() {
    return (
        <App selectedKeys={['goodsList']} openKeys="PMallMenu">
            <GoodsList1/>
        </App>
    );
}
