import React, {Component} from 'react';
import {Breadcrumb, Button, Drawer, Form, FormInstance, Input, message, Space,} from 'antd';
import {Link} from 'react-router-dom';
import Fetch from '../common/FetchIt';
import Editor from '../common/editor/Editor';
import API from '../common/api';
import styles from './project.module.css';
import {Project} from './types';
import FileUploader from '../common/uploader';
import App from '../../App';
import common from '../common/common';
import MediaPath from "../common/uploader/MediaPath";
import MediaCodes from "../common/uploader/MediaCodes";
import MediaUtils from "../common/uploader/MediaPath";

const FormItem = Form.Item;

class ProjectForm1 extends Component {
    formRef = React.createRef<FormInstance>();
    draft = 'PUBLISHED';
    loading = false;
    state = {
        id: 0,
        project: {},
        visible: false,
        value1: '',
        value2: '',
        date: '',
        value3: '',
    };

    componentDidMount() {
        const id = common.getQuery('id');
        if (id) {
            this.setState(
                {
                    id: common.getQuery('id'),
                },
                () => {
                    this.loadData();
                }
            );
        }
    }

    loadData = () => {
        Fetch.get(`${API.project.zsgc}?id=${this.state.id}`).then((data) => {
            let record = data;
            const coverIds = record.covers.map((item: any) => {
                return {
                    id: item.id,
                    uid: item.id,
                    status: 'done',
                    name: item.fileName,
                    url: MediaPath.getFileUrl(item.url),
                    filePath: MediaPath.getFileUrl(item.url),
                    mediaId: item.id,
                };
            });
            const project = {
                ...record,
                coverIds,
            };
            this.setState(
                {
                    project,
                },
                () => {
                    this.formRef.current?.setFieldsValue(project);
                }
            );
        });
    };
    sendWorkSubmit = () => {
        this.formRef.current?.validateFields().then((values) => {
            let value1 = values.title;
            let value2 = values.publisher;
            let value3 = values.content.toHTML
                ? values.content.toHTML()
                : values.content;
            // let value4 = values.coverIds.map((item: any) => item.thumbUrl).join(',');
            value3 = value3.replace(/( )/g, '&nbsp;');
            let date = this.format(new Date());
            this.setState(() => ({
                value1: value1,
                value2: value2,
                value3: value3,
                date,
                visible: true,
            }));
        });
    };
    format = (dat: any) => {
        //获取年月日，时间
        var year = dat.getFullYear();
        var mon =
            dat.getMonth() + 1 < 10 ? '0' + (dat.getMonth() + 1) : dat.getMonth() + 1;
        var data = dat.getDate() < 10 ? '0' + dat.getDate() : dat.getDate();
        var hour = dat.getHours() < 10 ? '0' + dat.getHours() : dat.getHours();
        var min = dat.getMinutes() < 10 ? '0' + dat.getMinutes() : dat.getMinutes();
        // var seon =
        //   dat.getSeconds() < 10 ? '0' + dat.getSeconds() : dat.getSeconds();

        var newDate = year + '-' + mon + '-' + data + ' ' + hour + ':' + min;
        return newDate;
    };
    saveDraft = () => {
        this.draft = 'DRAFT';
        this.formRef.current?.submit();
    };

    publish = () => {
        this.draft = 'PUBLISHED';
        this.formRef.current?.submit();
    };

    submit = (values: any) => {
        if (this.loading) {
            return;
        }
        values.coverIds = values.coverIds.map((item: any) => item.id).join(',');
        values.content = values.content.toHTML
            ? values.content.toHTML()
            : values.content;
        if (values.content == '<p></p>') {
            message.error('内容不能为空！');
            return;
        }
        this.loading = true;
        if (values.id) {
            this.update(values);
        } else {
            values.status = this.draft;
            this.create(values);
        }
    };

    create = (values: Project) => {
        let that = this;
        Fetch.postJSON(`${API.project.zsgc}`, values)
            .then(() => {
                that.loading = false;
                message.success('提交成功');
                window.history.back();
            })
            .catch((err) => {
                that.loading = false;
            });
    };

    update = (values: Project) => {
        Fetch.putJSON(`${API.project.zsgc}/${values.id}`, values).then(() => {
            this.loading = false;
            message.success('保存成功');
            // this.props.hide();
            // this.props.reload();
            window.history.back();
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    render() {
        // const id = this.props.project && this.props.project.id;
        const {id, project} = this.state;

        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>
                        <Link to="/project">公益资讯</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{id ? '修改资讯' : '新建资讯'}</Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <div className={styles.container}>
                        <Form
                            initialValues={project}
                            onFinish={this.submit}
                            ref={this.formRef}
                        >
                            <FormItem hidden noStyle name="id">
                                <Input/>
                            </FormItem>
                            <FormItem hidden noStyle name="status">
                                <Input/>
                            </FormItem>
                            <FormItem hidden noStyle name="clicks">
                                <Input/>
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="coverIds"
                                valuePropName="fileList"
                                label="封面图"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 20}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                {/*无需刷新*/}
                                <FileUploader
                                    multiple
                                    maxCount={1}
                                    listType="picture-card"
                                    checkSize={false}
                                    desc="支持扩展名：.jpg、.png; 图片大小不能超过2M; 建议尺寸 224 * 160; 最多6张"
                                    typeCode={MediaCodes.project.code}
                                    onRefresh={MediaUtils.upLoadRefresh}
                                />
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="title"
                                label="标题"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 20}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Input placeholder="标题"/>
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="publisher"
                                label="发布者"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 20}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Input placeholder=""/>
                            </FormItem>
                            <FormItem
                                className={styles.mylabel}
                                name="content"
                                label="内容"
                                labelCol={{span: 4}}
                                wrapperCol={{span: 20}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <Editor url={API.upload}/>
                            </FormItem>
                            <FormItem wrapperCol={{span: 12, offset: 6}} className="center">
                                <Button onClick={this.sendWorkSubmit} className={styles.mybtn}>
                                    预览
                                </Button>
                                <Space>
                                    {!id && (
                                        <Button onClick={this.saveDraft} className={styles.mybtn}>
                                            存草稿
                                        </Button>
                                    )}
                                    <Button
                                        type="primary"
                                        onClick={this.publish}
                                        className={styles.mybtn}
                                    >
                                        提交
                                    </Button>
                                </Space>
                            </FormItem>
                        </Form>
                        <div>
                            <Drawer
                                title="小程序预览"
                                width={360}
                                placement="right"
                                closable={false}
                                onClose={this.onClose}
                                open={this.state.visible}
                            >
                                <div className={styles.phonebox}>
                                    <div className={styles.phoneboxContent}>
                                        <div className={styles.phoneboxTitle}>
                                            {this.state.value1}
                                        </div>
                                        <p className={styles.dateC}>{this.state.date} 浏览量 0</p>
                                        <div
                                            dangerouslySetInnerHTML={{__html: this.state.value3}}
                                        ></div>
                                    </div>
                                </div>
                            </Drawer>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default function ProjectForm() {
    return (
        <App selectedKeys={['project']} openKeys="sub1">
            <ProjectForm1/>
        </App>
    );
}
