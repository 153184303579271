import React from 'react';
import {Button, Divider, Input, Select, Space, Spin, Table} from 'antd';
import API from '../../common/api';
import {ColumnsType} from 'antd/es/table';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import BaseListPage from "../../common/listpage/BaseListPage";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {BaseDhPageDate} from "../common/DhPageCommon";
import {dhPhoneTaskStatusArray, dhPhoneTaskTypeArray, phoneTaskStatus, phoneTaskType} from "../common/DhDictData";
import Fetch from "../../common/FetchIt";
import encryptData from "../../common/crypto";
import {addParams, NUMBER_STR, STRING_STR} from "../../cooperate/bill/page/ListCommon";
import {Link} from "react-router-dom";
import {BasePageType} from "../../common/listpage/BaseListPageType";


type User = {};
type PhoneTaskAllType = BasePageType & {
    searchData: {
        page: number | 0,
        name: string | undefined,
        phone: string | undefined,
        taskType: string | undefined,
        taskStatus: string | undefined,
    },
    salesStatus: number | 0
}

const DhPhoneTaskTypeAllData: PhoneTaskAllType = {
    ...BaseDhPageDate,
    searchData: {
        name: undefined,
        phone: undefined,
        taskType: undefined,
        taskStatus: undefined,
        page: 0
    },
    salesStatus: 0
}


class DhPhoneTaskAllClass extends BaseListPage {

    state: PhoneTaskAllType = {
        ...DhPhoneTaskTypeAllData,
        btnQ: {
            ROLE_AIER_M_DETAILS: 0
        },
        salesStatus: 0
    };

    componentDidMount() {

        super.componentDidMount();
        this.getSalesmanStatus();
    }

    loadData = () => {
        const {pageSize, searchData} = this.state;
        let url = `${API.dentistry.phone_task.admin_all_list}?page=${searchData.page}&size=${pageSize}`;
        url = url + addParams("name", searchData.name, STRING_STR);
        url = url + addParams("phone", searchData.phone, STRING_STR);
        url = url + addParams("taskType", searchData.taskType, NUMBER_STR);
        url = url + addParams("taskStatus", searchData.taskStatus, NUMBER_STR);
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                importLoading: false
            });
        })
    };


    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
    };

    reset = () => {
        super.reset(BaseDhPageDate.searchData);
    };

    handleKeyDown = (e: any) => {
        super.handleKeyDown(e);
    }

    closeModal = () => {
        this.loadData()
    }

    remove = (userId: number) => {

    }

    getSalesmanStatus = () => {
        Fetch.get(API.dentistry.dh_salesman.get_sales_status).then((data) => {
            this.setState({
                salesStatus: data
            })
        });
    }

    doHandle = (id: any) => {
        Fetch.putJSON(API.dentistry.phone_task_batch_detail.do_update_processing, {id: id}).then((data) => {
            window.location.href = '/#/dh/patient_application/add?taskId=' + encryptData(id + "")
        });
    }

    render() {
        const {
            pageSize, totalElements,
            searchData, content
        } = this.state;
        const columns: ColumnsType<User> = [

            {
                title: '姓名',
                dataIndex: 'userName',
                align: `center`,
                render: (text: any) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '手机号',
                dataIndex: 'phone',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '任务类型',
                dataIndex: 'taskType',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{phoneTaskType[value]}</span>
                    );
                },
            },
            {
                title: '任务状态',
                dataIndex: 'taskStatus',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{phoneTaskStatus[value]}</span>
                    );
                },
            },
            {
                title: '创建时间',
                dataIndex: 'createdDate',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '操作',
                align: `center`,
                dataIndex: 'status',
                render: (text: any, record: any) => {
                    return (
                        <Space split={<Divider type="vertical"/>}>
                            <Link
                                to={`/dh/phone_task/phone_task_detail?taskId=${encryptData(record.id + "")}`}>
                                查看详情
                            </Link>
                            {/*{record.taskType === 1 && (*/}
                            {/*    <>*/}

                            {/*    </>*/}
                            {/*)}*/}
                        </Space>
                    );
                },
            },
        ];

        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space onKeyDown={this.handleKeyDown}>
                            <Input
                                placeholder="姓名"
                                value={searchData.name}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, name: e.target.value})
                                }
                                }
                            />
                            <Input
                                placeholder="手机号"
                                value={searchData.phone}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, phone: e.target.value})
                                }
                                }
                            />
                            <Select
                                style={{width: 220}}
                                placeholder="任务类型"
                                optionFilterProp="children"
                                options={dhPhoneTaskTypeArray}
                                value={searchData.taskType}
                                allowClear={true}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, taskType: e})
                                }}
                            />
                            <Select
                                style={{width: 220}}
                                placeholder="任务状态"
                                optionFilterProp="children"
                                options={dhPhoneTaskStatusArray}
                                value={searchData.taskStatus}
                                allowClear={true}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, taskStatus: e})
                                }}
                            />
                            <Button onClick={this.loadData}>
                                搜索
                            </Button>
                            <Button onClick={() => this.reset()}>重置</Button>
                        </Space>
                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
            </>
        );
    }

}

export default function DhPhoneTaskAllList() {

    return (

        <App selectedKeys={
            ['DH_PHONE_TASK_ALL_LIST']
        }
             openKeys="ORAL_GUARD">
            <DhPhoneTaskAllClass/>
        </App>
    );
}
