import {Component} from 'react';
import 'dayjs/locale/zh-cn';
import {Breadcrumb, Button, message, Modal, Space, Table} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {ColumnsType} from 'antd/es/table';

import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import createModal from '../../common/createModal';

import App from '../../../App';
import {AccountType} from './types';
import FormContent from './form';

const confirm = Modal.confirm;

const FormModal = createModal(FormContent);
export default class Category extends Component {
    state = {
        page: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        content: [],
        account: null,
        title: '新建类别',
        loading: true,
    };
    private modal: any = null;

    componentDidMount() {
        this.getData();
    }

    // 获取数据
    getData = () => {
        const {page, pageSize} = this.state;
        Fetch.get(`${API.atl.category.list}?page=${page}&size=${pageSize}`).then(
            (data) => {
                this.setState({
                    content: data.content,
                    totalElements: data.totalElements,
                    totalPages: data.totalPages,
                    loading: false,
                });
            }
        );
    };
    // 编辑
    edit = (row: any) => {
        this.setState(
            {
                title: '编辑类别',
                account: row,
            },
            this.modal.show
        );
    };
    // 删除
    del = (row: any) => {
        let that = this;
        confirm({
            title: `您是否确认要删除项目： “${row.name}”`,
            onOk() {
                Fetch.put(API.atl.category.deleted, {id: row.id}).then(() => {
                    message.success('删除成功');
                    that.getData();
                });
            },
        });
    };
    //  禁用
    close = (id: number, type: number) => {
        Fetch.put(API.atl.category.active, {id: id}).then(() => {
            if (type) {
                message.success('启用成功');
            } else {
                message.success('禁用成功');
            }
            this.getData();
        });
    };
    creat = () => {
        this.setState(
            {
                title: '新建类别',
                account: null,
            },
            this.modal.show
        );
    };
    changeInput = (val: String, type: any) => {
        this.setState({
            [type]: val,
        });
    };
    // 下一页
    changeCondition = (condition: any, load = false) => {
        this.setState(
            {
                ...condition,
            },
            () => {
                if (load) {
                    this.getData();
                }
            }
        );
    };

    render() {
        const {page, pageSize, totalElements, content, title} = this.state;
        const columns: ColumnsType<AccountType> = [
            {
                title: '类别',
                dataIndex: 'name',
                align: `center`,
            },
            {
                title: '已关联项目',
                dataIndex: 'projectCount',
                align: `center`,
            },
            {
                title: '创建日期',
                dataIndex: 'createdDate',
                align: `center`,
            },
            {
                title: '状态',
                dataIndex: 'active',
                render: (text: any, record: any) => {
                    if (text === true) {
                        return '启用中';
                    } else if (text === false) {
                        return '已禁用';
                    } else {
                        return '';
                    }
                },
                align: `center`,
            },

            {
                title: '操作',
                align: `center`,
                render: (text: any, record: any) => {
                    return (
                        <Space>
                            {record.active ? (
                                <Button
                                    danger
                                    type="text"
                                    onClick={() => this.close(record.id, 0)}
                                >
                                    禁用
                                </Button>
                            ) : (
                                <Button type="link" onClick={() => this.close(record.id, 1)}>
                                    启用
                                </Button>
                            )}
                            <Button type="link" onClick={() => this.edit(record)}>
                                编辑
                            </Button>
                            <Button type="text" danger onClick={() => this.del(record)}>
                                删除
                            </Button>
                        </Space>
                    );
                },
            },
        ];
        return (
            <App selectedKeys={['category']} openKeys="atl">
                <>
                    <Breadcrumb style={{margin: '16px 0'}}>
                        <Breadcrumb.Item>公益活动库 / 类别</Breadcrumb.Item>
                    </Breadcrumb>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <div
                            style={{
                                float: 'right',
                                marginLeft: 'auto',
                                marginBottom: '15px',
                            }}
                        >
                            <Button
                                onClick={this.creat}
                                type="primary"
                                icon={<PlusOutlined/>}
                            >
                                新建
                            </Button>
                        </div>

                        <Table
                            loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey={(record: any) => {
                                return record.id;
                            }}
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.changeCondition(
                                    {
                                        page: pagination.current - 1,
                                        pageSize: pagination.pageSize,
                                    },
                                    true
                                )
                            }
                        />
                    </div>
                    <FormModal
                        width={500}
                        title={title}
                        reload={this.getData}
                        account={this.state.account}
                        flag={title}
                        ref={(c) => (this.modal = c)}
                    ></FormModal>
                </>
            </App>
        );
    }
}
