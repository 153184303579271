import MediaCodes from "./MediaCodes";
import {message} from "antd";
import envConfig from "../../../envConfig";
import API from "../api";


let MediaUtils = {

    getBucket(code) {
        const result = MediaCodes[code]
        if (!result) {
            message.error("code未配置")
        }
        return result;
    },
    getFileUrl(url) {

        if (url) {
            let result;
            if (this.checkOleUrl(url)) {
                return this.buildUrl(envConfig.oldCdn, url);
            } else if (url.indexOf(API.fileSignKey) >= 0) {
                result = this.buildUrl(envConfig.privCdn, url)
            } else {
                result = this.buildUrl(envConfig.pubCdn, url)
            }
            return result;
        }
    },
    getNoSignUrl(url) {

        return url.split(API.fileSignKey)[0];
    },
    checkOleUrl(url, envConfig) {
        const oldYear = 2024;
        const oldMonth = 9;
        const oldDay = 25;
        const regex = new RegExp('\/(\\d{4})\/(\\d{1,2})(?:\/(\\d{1,2}))?\/');
        const match = url.match(regex);
        if (match) {
            const year = parseInt(match[1]);
            const month = parseInt(match[2]);
            const day = parseInt(match[3]) || 0;
            return year < oldYear || (year === oldYear && (month < oldMonth || (month === oldMonth && day < oldDay)));
        } else {
            return true;
        }
    },
    buildUrl(domain, url) {

        if (url.startsWith("/")) {
            return domain + url;
        } else {
            return domain + "/" + url;
        }
    },
    //无意义只为标记
    upLoadRefresh(type, mediaId) {
        return new Promise((resolve, reject) => {

        })
    },
    splitUrlDomain(url) {

        if (url.indexOf("http") >= 0) {
            const urlObj = new URL(url);
            return urlObj.pathname;
        } else {
            const domainTemp = "https://www.lelingzhiyuan.com"
            if (url.startsWith("/")) {
                url = domainTemp + url;
            } else {
                url = domainTemp + "/" + url;
            }
            const urlObj = new URL(url);
            return urlObj.pathname;
        }
    }
}

export default MediaUtils;