import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Checkbox, Col, Form, FormInstance, Input, message, Row, Space} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import FormItem from 'antd/lib/form/FormItem';
import type {CheckboxChangeEvent} from 'antd/es/checkbox';
import styles from "../message/message.module.css";

interface ModalType {
    provinceId: number,
    id: number,
    hospitalId: number,
    hide: any,
    isHide: boolean,
}

const WeekRadioModelClass: React.FC<ModalType> = (props) => {

    const [selectHospital, setSelectHospital] = useState<[{ value: number, label: string }]>([{value: 0, label: ''}]);
    const [viewRole, setViewRole] = useState<number[]>([]);
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [allViewIds, setAllViewIds] = useState<number[]>([]);
    const formRef = useRef<FormInstance>(null);

    useEffect(
        function () {

            Fetch.get(`${API.dict.list}?type=week_status`).then((data) => {
                const allViewIds: number[] = data.map((item: { id: any }) => item.id);
                setSelectHospital(data);
                setAllViewIds(allViewIds);

            });
        },
        [props.provinceId]
    );

    useEffect(
        function () {
            Fetch.get(`${API.dhHospitalTimeConfig.details}?hospitalId=${props.hospitalId}`).then((data) => {
                if (formRef.current) {

                    let splice = [];
                    let dataValue = {
                        amVisitors: '',
                        'bookingDay': '',
                        'id': '',
                        'pmVisitors': '',
                        'weeksId': ''
                    }
                    if (data && data.weeksId) {
                        splice = data.weeksId.split(',');
                        for (let i = 0; i < splice.length; i++) {
                            splice[i] = Number(splice[i])
                        }
                    }
                    if (data) {
                        dataValue = {
                            'amVisitors': data.amVisitors,
                            'bookingDay': data.bookingDay,
                            'id': data.id,
                            'pmVisitors': data.pmVisitors,
                            'weeksId': splice ? splice : []
                        }
                    }
                    const viewData = dataValue
                    formRef.current.setFieldsValue({
                        ...viewData,
                    });
                    onChangeCheckbox();
                }
            });
        },
        [selectHospital]
    );

    useEffect(
        function () {
            editCheckAll();
        },
        [viewRole]
    );

    const submit = (values: any) => {
        values.hospitalId = props.hospitalId;
        create(values);
    };

    const create = (values: any) => {
        values.weeksIds = values.weeksId
        values.weeksId = String(values.weeksId)
        if (!values.id) {
            Fetch.postJSON(API.dhHospitalTimeConfig.create, values).then(() => {
                message.success('提交成功');
                props.hide();
            });
        } else {
            Fetch.putJSON(API.dhHospitalTimeConfig.update, values).then(() => {
                message.success('修改成功');
                props.hide();
            });
        }

    }

    const cancel = () => {

        props.hide();
    }

    const editCheckAll = () => {

        setCheckAll(selectHospital.length === viewRole.length)
    }

    const onChangeCheckbox = () => {

        const fieldValue = formRef.current?.getFieldValue("weeksId");
        setViewRole(fieldValue)
    };

    const onCheckAllChange = (e: CheckboxChangeEvent) => {

        const items = e.target.checked ? allViewIds : [];
        setViewRole(items)
        formRef.current?.setFieldsValue({
            ...{weeksId: items},
        });
    };
    const indeterminate = viewRole != null && viewRole.length > 0 && viewRole.length < selectHospital.length;


    return (
        <>
            <div
                className="site-layout-background"
                style={{minHeight: 360}}
            >
                <div>
                    <Form
                        onFinish={submit}
                        ref={formRef}
                    >
                        <Card title="数据权限" size="small">
                            <Space direction="vertical" style={{display: 'flex'}}>
                                <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange}
                                          checked={checkAll}>
                                    全选
                                </Checkbox>
                                <FormItem
                                    name="weeksId"
                                    label="当前时间"
                                    labelCol={{span: 4}}
                                    wrapperCol={{span: 15}}
                                    rules={[{required: true, message: '必填'}]}
                                >
                                    <Checkbox.Group style={{width: '100%', padding: '5px'}}
                                                    onChange={onChangeCheckbox}>
                                        <Row gutter={[16, 16]}>
                                            {selectHospital.map((item: any, index: number) =>
                                                <Col span={8} key={index}>
                                                    <Checkbox value={item.id}>{item.name}</Checkbox>
                                                </Col>
                                            )}
                                        </Row>
                                    </Checkbox.Group>
                                </FormItem>

                                <FormItem className={styles.mylabel} name="id" hidden>
                                    <Input/>
                                </FormItem>

                                <FormItem className={styles.mylabel} name="amVisitors" label="上午预约人数"
                                          labelCol={{span: 4}}
                                          wrapperCol={{span: 20}} rules={[{required: true, message: '必填'}]}>
                                    <Input

                                        placeholder="上午预约人数"
                                    />
                                </FormItem>

                                <FormItem className={styles.mylabel} name="pmVisitors" label="下午预约人数"
                                          labelCol={{span: 4}}
                                          wrapperCol={{span: 20}} rules={[{required: true, message: '必填'}]}>
                                    <Input

                                        placeholder="下午预约人数"
                                    />
                                </FormItem>

                                <FormItem className={styles.mylabel} name="bookingDay" label="预约天数"
                                          labelCol={{span: 4}}
                                          wrapperCol={{span: 20}} rules={[{required: true, message: '必填'}]}>
                                    <Input

                                        placeholder="预约天数"
                                    />
                                </FormItem>
                                <Space align="center" style={{display: "flex", justifyContent: "right"}}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                    >
                                        保存
                                    </Button>
                                    <Button
                                        size="large"
                                        style={{marginRight: '15px'}}
                                        onClick={cancel}
                                    >
                                        取 消
                                    </Button>
                                </Space>
                            </Space>
                        </Card>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default WeekRadioModelClass;
