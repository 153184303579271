import React, {useEffect, useState} from 'react';
import {Button, Card, Descriptions, Modal, Space} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import styles from "../../cooperate/add/ServiceUser.module.css";
import {QuesType} from "../ExamCommonData";
import ReactJson from 'react-json-view';
import MediaPath from "../../common/uploader/MediaPath";


const confirm = Modal.confirm;
type ModalType = {
    quesId: number,
    hide: any,
    reload: any
}
type OptionType = {
    optionCode: string,
    optionName: string,
    optionTitle: string
}
type DataType = {
    quesTitle: string,
    quesType: number,
    quesScore: number,
    optionsVos: OptionType[],
    answerStr: string,
    parse: string,
    fileUrl: string
}
const defData = {
    quesTitle: '',
    quesType: 0,
    quesScore: 0,
    optionsVos: [],
    answerStr: '',
    parse: '',
    fileUrl: '',
}
/**
 * 报销失败弹窗
 * @param props
 * @constructor
 */
export default function ExamQuesDetailModal(props: ModalType) {

    const [data, setData] = useState<DataType>(defData);
    const [jsonData, setJsonData] = useState<object>();
    const cancel = () => {

        props.hide();
    }
    const ok = () => {

        props.hide();
        props.reload();
    }


    useEffect(() => {
        getData();
    }, []);

    const getData = () => {

        const url = `${API.exam.exam_ques.get_detail}?quesId=${props.quesId}`
        Fetch.get(url).then((data) => {
            data.answerStr = getAnswerStr(data);
            if (data.fileUrl) {
                readFile(data.fileUrl, (callback: object) => {
                    setJsonData(callback)
                    setData(data)
                })
            } else {
                setData(data)
            }
        });
    }

    const getAnswerStr = (data: any) => {
        const mapData = new Map(data.optionsVos.map((item: OptionType) => [item.optionCode, item]));
        let result = "";
        data.answers.forEach((item: string) => {
            const newVar = mapData.get(item) as OptionType;
            result += newVar.optionName;
        })
        return result;
    }

    const readFile = (url: string, callback: any) => {
        if (url) {
            url = MediaPath.getFileUrl(url);
            fetch(`${url}`)
                .then(response => response.json())
                .then(data => {
                    // 在这里处理获取的数据
                    callback && callback(data);
                })
                .catch(error => {
                    // 处理错误
                });
        }
    }

    return <>
        <Space direction={"vertical"}>
            <Card size="small" className={styles.mycard} style={{minHeight: '500px'}}>
                <Space style={{display: 'flex', alignItems: 'flex-start'}}>
                    <Space direction={"vertical"}>

                        <Descriptions>
                            <Descriptions.Item label="试题">
                                {data.quesTitle}
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions>
                            <Descriptions.Item label="试题类型">
                                {QuesType[data.quesType]}
                            </Descriptions.Item>
                            <Descriptions.Item label="分数">
                                {data.quesScore}
                            </Descriptions.Item>
                        </Descriptions>
                        {data.optionsVos.map((child: OptionType) => {
                            return (
                                <Descriptions>
                                    <Descriptions.Item label={child.optionName}>
                                        {child.optionTitle}
                                    </Descriptions.Item>
                                </Descriptions>
                            );
                        })}
                        <Descriptions>
                            <Descriptions.Item label="答案">
                                {data.answerStr}
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions>
                            <Descriptions.Item label="解析">
                                {data.parse}
                            </Descriptions.Item>
                        </Descriptions>
                    </Space>
                    <>
                        {jsonData !== undefined && (
                            <Descriptions>
                                <Descriptions.Item label="文件内容">
                                    <ReactJson src={jsonData} enableClipboard={false}/>
                                </Descriptions.Item>
                            </Descriptions>
                        )}
                    </>
                </Space>
            </Card>
            <Button
                size='middle'
                onClick={cancel}
            >
                取消
            </Button>
        </Space>
    </>;
}
