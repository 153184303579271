import React, {Component} from 'react';
import {Button, Card, Descriptions, Space, Spin,} from 'antd';
import App from '../../App';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import common from '../common/common';
import BreadcrumbInfo from "../common/breadcrumb/BreadcrumbInfo";
import encryptData, {decryptData} from "../common/crypto";
import {getKey} from "../common/breadcrumb/BreadcrumbUtil";
import {TaxesDetailType} from "./TaxesType";

type TaxesDetail = {
    id: string | '',
    data: TaxesDetailType | undefined,
    importLoading: boolean | true
}

class TaxesDetailClass extends Component<{}, TaxesDetail> {

    state: TaxesDetail = {
        id: decryptData(common.getQuery('id')),
        data: undefined,
        importLoading: true
    };

    componentDidMount() {
        this.getData()
    };

    getData() {

        const {id} = this.state;
        let url = `${API.taxes_user.getDetail}?taxesUserId=${id}`;
        Fetch.get(url).then((data: any) => {
            this.setState({
                data: data,
                importLoading: false
            })
        });
    }

    cancel = () => {
        window.history.go(-1);
    }

    goServiceUserDetail = (serviceId: number, activityId: number) => {

        if (activityId === 1) {
            window.location.href = `/#/serviceUserDetail/detail?id=${encryptData(serviceId + "")}`
        } else if (activityId === 2) {
            window.location.href = `/#/dh/patient_application/detail?id=${encryptData(serviceId + "")}`
        }
    }

    render() {
        const {data} = this.state;
        return (
            <div>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Spin tip={'加载中'} spinning={this.state.importLoading}>
                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                            {data ? (
                                <>
                                    <Card>
                                        <Descriptions>
                                            <Descriptions.Item label="姓名">
                                                {data.userDetail.userName}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="身份证号">
                                                {data.userDetail.idNumber}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="手机号">
                                                {data.userDetail.phone}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Descriptions>
                                            <Descriptions.Item label="总金额">
                                                {data.userDetail.totalAmount}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="打款次数">
                                                {data.userDetail.payoutNum}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="最后报税时间">
                                                {data.userDetail.maxTaxes}
                                            </Descriptions.Item>
                                        </Descriptions>

                                        {data.logDetails.map((item, index) => (
                                            <>
                                                <Card size="small" key={index}
                                                      title={
                                                          "活动名称：" + item.activityName +
                                                          ", 打款金额：" + item.account +
                                                          ", 打款时间：" + item.releaseTime
                                                      }
                                                      extra={
                                                          <Button type="primary"
                                                                  onClick={() => this.goServiceUserDetail(item.serviceId, item.activityId)}>
                                                              患者姓名:{item.serviceUserName}
                                                          </Button>
                                                      }
                                                >
                                                    {item.records && (
                                                        <>
                                                            {item.records.map((item, index) => (
                                                            <Descriptions key={index}>
                                                                <Descriptions.Item label="报税时间">
                                                                    {item.taxes}
                                                                </Descriptions.Item>
                                                                <Descriptions.Item label="报税金额">
                                                                    {item.account}
                                                                </Descriptions.Item>
                                                            </Descriptions>

                                                            ))
                                                            }
                                                        </>
                                                    )}

                                                </Card>
                                            </>
                                        ))}
                                    </Card>
                                </>
                            ) : ('')}
                            <Card>
                                <Space>
                                    <Button
                                        // type="primary"
                                        size="large"
                                        onClick={this.cancel}
                                    >
                                        返回
                                    </Button>
                                </Space>
                            </Card>
                        </Space>
                    </Spin>
                </div>
            </div>
        );
    }
}

export default function TaxesDetail() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <TaxesDetailClass/>
        </App>
    );
}
