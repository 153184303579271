import {DefaultRefreshFile, HospitalBillDetail} from "./HospitalBillType";
import MediaPath from "../../../../common/uploader/MediaPath";
import {FileTypeTypes} from "../../../../common/modal/ViewFileListTypes";
import dayjs from "dayjs";


export const billDetailDefault: HospitalBillDetail = {
    billId: 0,
    startTime: dayjs().valueOf(),
    refreshData: DefaultRefreshFile(),
    refreshCount: 0,
    spinning: true,
    data: {
        billDetail: {
            code: '',
            billMonth: '',
            hospitalId: 0,
            accountName: '',
            accountNum: '',
            billStatus: 0,
            numUser: 0,
            totalRevenue: 0,
            totalHealthInsurance: 0,
            totalOtherGrants: 0,
            totalSubsidy: 0,
            totalSelfPay: 0,
            hospitalName: '',
            receiptTime: null
        },
        detailVos: [
            {
                billId: 0,
                hospitalId: 0,
                serviceId: 0,
                userName: '',
                gender: 0,
                economicSituation: '',
                timeOfAdmission: '',
                timeOfDischarge: '',
                eyeType: 0,
                totalPrice: 0,
                medicareReimbursement: 0,
                selfPayPrice: 0,
                otherGrants: 0,
                subsidy: 0,
            }
        ],
        files: [
            {
                fileId: 0,
                type: 0,
                name: '',
                url: ''
            }
        ],
        fileClassify: {
            hasFile: false,
            stampBill: [],
            proofReimbursement: [],
            receipt: [],
            billFile: []
        },
        accountVo: {
            name: '',
            accountBank: '',
            accountNum: '',
            bankRnt: ''
        },
    }
}

export function showFile(data: HospitalBillDetail['data']) {

    data.fileClassify = {
        hasFile: false,
        stampBill: [],
        proofReimbursement: [],
        receipt: [],
        billFile: []
    };
    if (data.files && data.files.length > 0) {
        data.fileClassify.hasFile = true;
        data.files.forEach((item: { url: string; type: any; name: string, fileId: number }) => {
            const obj: FileTypeTypes = {
                id: item.fileId,
                uid: item.fileId + "",
                fileId: item.fileId,
                name: item.name,
                url: MediaPath.getFileUrl(item.url),
                status: 'done',
                fileType: '',
                filePath: MediaPath.getFileUrl(item.url),
                mediaId: item.fileId
            };
            if (item.type === 1) {
                obj.fileType = 'stampBill';
                data.fileClassify.stampBill.push(obj);
            } else if (item.type === 2) {
                obj.fileType = 'proofReimbursement';
                data.fileClassify.proofReimbursement.push(obj);
            } else if (item.type === 3) {
                obj.fileType = 'receipt';
                data.fileClassify.receipt.push(obj);
            } else if (item.type === 4) {
                obj.fileType = 'billFile';
                data.fileClassify.billFile.push(obj);
            }
        });
    }
}

export function aireBillRefreshData(data: any) {

    let result = DefaultRefreshFile();
    if (data && data.length > 0) {
        data.forEach((item: { url: string; type: any; name: string, fileId: number }) => {
            const obj: FileTypeTypes = {
                id: item.fileId,
                uid: item.fileId + "",
                fileId: item.fileId,
                name: item.name,
                url: MediaPath.getFileUrl(item.url),
                status: 'done',
                fileType: '',
                filePath: MediaPath.getFileUrl(item.url),
                mediaId: item.fileId
            };
            if (item.type === 1) {
                obj.fileType = 'stampBill';
                result.stampBill.push(obj);
            } else if (item.type === 2) {
                obj.fileType = 'proofReimbursement';
                result.proofReimbursement.push(obj);
            } else if (item.type === 3) {
                obj.fileType = 'receipt';
                result.receipt.push(obj);
            } else if (item.type === 4) {
                obj.fileType = 'billFile';
                result.billFile.push(obj);
            }
        });
    }
    return result;
}
