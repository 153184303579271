import React from 'react';
import 'dayjs/locale/zh-cn';
import {Card, Col, Row} from "antd";

import styles from './donationActivity.module.css';
import {Progress} from "./types";
import MediaPath from "../common/uploader/MediaPath";

function ProgressDetail({progress}: { progress: Progress }) {

    if (!progress) {
        return null;
    }

    return (
        <>
            <div className="site-layout-background" style={{padding: 24, minHeight: 720}}>
                <Card title="封面图">
                    <Row>
                        {
                            progress.covers && progress.covers.length === 0 && <span>暂无</span>
                        }
                        {
                            progress.covers && progress.covers.map((file: any) => {
                                return (
                                    <Col
                                        key={file.id}
                                        span={8}
                                    >
                                        <div><img src={MediaPath.getFileUrl(file.url)} width={100}/></div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Card>
                <Card className={styles.mycard}>
                    <Row justify="space-around">
                        <Col span={4}>
                            <div className={styles.detailLabel}>发布者：</div>
                        </Col>
                        <Col span={20}>
                            <div>{progress.publisher}</div>
                        </Col>
                    </Row>
                    <Row justify="space-around">
                        <Col span={4}>
                            <div className={styles.detailLabel}>简介：</div>
                        </Col>
                        <Col span={20}>
                            <div>{progress.description}</div>
                        </Col>
                    </Row>
                    <Row justify="space-around">
                        <Col span={4}>
                            <div className={styles.detailLabel}>捐款描述：</div>
                        </Col>
                        <Col span={20}>
                            <div dangerouslySetInnerHTML={{__html: progress.content}}/>
                        </Col>
                    </Row>
                    <Row justify="space-around">
                        <Col span={4}>
                            <div className={styles.detailLabel}>发布时间：</div>
                        </Col>
                        <Col span={20}>
                            <div>{progress.createdDate}</div>
                        </Col>
                    </Row>
                </Card>
            </div>
        </>
    );
}

export default ProgressDetail;
