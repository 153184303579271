import {Card, Descriptions, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import {DhDetailDataType} from "../common/DhDetailType";
import styles from "../../cooperate/add/ServiceUser.module.css";
import ViewFileListClass from "../../common/modal/ViewFileList";
import {FormTitle, getTitle, Veteran} from "../common/IdentityData";

type DhWarVeteranType = {

    serviceUserFee: DhDetailDataType["data"]["serviceUserFee"]
    showFile?: boolean | true,
    warVeteran?: DhDetailDataType["data"]["warVeteran"],
    onRefresh: (type: string) => Promise<any>;
}

const ServiceUserFeeInfo: React.FC<DhWarVeteranType> = (props) => {

    const [showFile, setShowFile] = useState<boolean>();
    const [titleObj, setTitleObj] = useState<FormTitle>(Veteran);


    useEffect(() => {
        setTitleObj(getTitle(props.warVeteran?.serviceIdentity));
    }, [props])

    useEffect(() => {
        if (props.showFile === null || props.showFile === undefined) {
            setShowFile(true)
        } else {
            setShowFile(props.showFile)
        }
    }, [])

    return (
        <>
            {(props.serviceUserFee && props.serviceUserFee.surgeryStart) && (
                <Card size="small" className={styles.mycard}
                          title={`费用信息`}>
                        <Descriptions>
                            <Descriptions.Item label="手术时间">
                                {props.serviceUserFee.surgeryStart}
                            </Descriptions.Item>
                            {/*<Descriptions.Item label="出院时间">*/}
                            {/*    {props.serviceUserFee.surgeryEnd}*/}
                            {/*</Descriptions.Item>*/}
                        </Descriptions>
                        <Descriptions>
                            <Descriptions.Item label="牙齿数量">
                                {props.serviceUserFee.teethNum}
                            </Descriptions.Item>
                            <Descriptions.Item label="牙齿类型">
                                {props.serviceUserFee.teethName}
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions>
                            <Descriptions.Item label="总金额">
                                {props.serviceUserFee.totalPrice}
                            </Descriptions.Item>
                            <Descriptions.Item label="报销前自付费用">
                                {props.serviceUserFee.selfPayPrice}
                            </Descriptions.Item>
                            <Descriptions.Item label="本项目资助">
                                {props.serviceUserFee.subsidy}
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions>
                            <Descriptions.Item label="报销后自付费用">
                                {props.serviceUserFee.afterSelfPayPrice}
                            </Descriptions.Item>
                            <Descriptions.Item label="备注">
                                {props.serviceUserFee.remark}
                            </Descriptions.Item>
                        </Descriptions>
                        {showFile && (
                            <Space size={[8, 16]} wrap align="start">
                                <Card style={{width: '100%'}}>
                                    <ViewFileListClass
                                        title="知情同意书"
                                        fileList={props.serviceUserFee.informed}
                                        onRefresh={props.onRefresh}
                                    />
                                </Card>
                                <Card style={{width: '100%'}}>
                                    <ViewFileListClass
                                        title="手术/救治同意书"
                                        fileList={props.serviceUserFee.consentForms}
                                        onRefresh={props.onRefresh}
                                    />
                                </Card>
                                <Card style={{width: '100%'}}>
                                    <ViewFileListClass
                                        title="病例"
                                        fileList={props.serviceUserFee.hospitalizationRecords}
                                        onRefresh={props.onRefresh}
                                    />
                                </Card>
                                <Card style={{width: '100%'}}>
                                    <ViewFileListClass
                                        title="收费凭证"
                                        fileList={props.serviceUserFee.invoices}
                                        onRefresh={props.onRefresh}
                                    />
                                </Card>
                                {(props.serviceUserFee.proofIdentity
                                        && props.serviceUserFee.proofIdentity.length > 0)
                                    && (
                                        <Card style={{width: '100%'}}>
                                            <ViewFileListClass
                                                title={titleObj.proofOfIdentity}
                                                fileList={props.serviceUserFee.proofIdentity}
                                                onRefresh={props.onRefresh}
                                            />
                                        </Card>
                                    )}
                                {(props.serviceUserFee.identityPromise
                                        && props.serviceUserFee.identityPromise.length > 0)
                                    && (
                                        <Card style={{width: '100%'}}>
                                            <ViewFileListClass
                                                title="身份承诺书"
                                                fileList={props.serviceUserFee.identityPromise}
                                                onRefresh={props.onRefresh}
                                            />
                                        </Card>
                                    )}
                            </Space>
                        )}
                </Card>
            )
            }
        </>
    );
};

export default ServiceUserFeeInfo;
