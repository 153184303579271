import envConfig from "../../../envConfig";


const MediaCodes = {
    aier_file: {code: 'aier_file', bucket: envConfig.privBucket},
    volunteer_file: {code: 'volunteer_file', bucket: envConfig.privBucket},
    dental_file: {code: 'dental_file', bucket: envConfig.privBucket},
    blue_photo: {code: 'blue_photo', bucket: envConfig.privBucket},
    user_avatar: {code: 'user_avatar', bucket: envConfig.pubBucket},
    donate_share: {code: 'donate_share', bucket: envConfig.pubBucket},
    exam_share: {code: 'exam_share', bucket: envConfig.pubBucket},
    mall: {code: 'mall'},
    donation_pub: {code: 'mall'},
    gongyi_pub: {code: 'mall'},
    template_pub: {code: 'mall'},
    ad_pub: {code: 'mall'},
    institution: {code: "institution"},
    invoice: {code: "invoice"},
    project: {code: "project"},
    user_setting: {code: "user_setting"},
    editor: {code: "editor"},
}

export default MediaCodes;