import {Component} from 'react';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import {decryptData} from "../../common/crypto";
import dayjs from "dayjs";
import {FileTypeTypes} from "../../common/modal/ViewFileListTypes";
import {DhDefaultRefreshFile, DhDetailDataType, DhDetailRefreshFileType} from "../common/DhDetailType";
import {dhRefreshFile} from "../common/edit/DhBackfillDataConfig";


class DhServiceUserParentClass<S = {}> extends Component {

    state: DhDetailDataType = {

        id: decryptData(common.getQuery('id')),
        data: {},
        startTime: dayjs().valueOf(),
        refreshData: DhDefaultRefreshFile(),
        refreshCount: 0,
        spinning: true,
        snap: '',
        bId: 0,
        booking: false
    };

    componentDidMount() {

    };

    refreshFile = (type: string) => {

        return new Promise((resolve, reject) => {
            this.refreshData().then((data: DhDetailRefreshFileType) => {

                resolve(data[type])
            })
        })
    }

    refreshFileOne = (type: string, mediaId: number) => {

        return new Promise((resolve, reject) => {
            this.refreshData().then((data: DhDetailRefreshFileType) => {
                const datum = data[type];
                datum.forEach((item: FileTypeTypes) => {
                    if (item.mediaId === mediaId) {
                        resolve(item.filePath);
                    }
                })
            })
        })
    }

    private refreshData = () => {

        const that = this;
        return new Promise<DhDetailRefreshFileType>((resolve, reject) => {
            const {id, snap, bId, startTime, refreshData, refreshCount, booking} = that.state;
            //开启时间
            const turnOnTime = (dayjs().valueOf() - startTime) / 1000;
            //刷新覆盖时间
            const refresh = refreshCount * API.fileMaxTime;
            if (turnOnTime - refresh > API.fileMaxTime) {
                let url = `${API.dentistry.service_user.refresh_file}?id=${id}`;
                if (booking) {
                    url = `${API.dentistry.dh_service_user_booking.refresh_file}?id=${id}`;
                }
                if (snap === 'true') {
                    url = `${API.dentistry.nbsp_service_user.refresh_file}?serviceId=${id}&batchId=${bId}`;
                }
                that.setState({spinning: true}, () => {
                    Fetch.get(url).then((data) => {
                        console.log("刷新！！！！")
                        const promise = dhRefreshFile(data);
                        const count = Math.floor(turnOnTime / API.fileMaxTime); // 输出 31
                        this.setState({
                            refreshData: promise,
                            refreshCount: count,
                            spinning: false
                        }, () => {
                            resolve(promise);
                        })
                    });
                })
            } else {
                resolve(refreshData);
            }
        })
    }
}

export default DhServiceUserParentClass;
