import {Card, Space} from 'antd';
import React from 'react';
import styles from "../../add/ServiceUser.module.css";
import ViewFileListClass from "../../../common/modal/ViewFileList";
import {BillDetailCommonType, HospitalBillDetail} from "./types/HospitalBillType";


export type BillDetailFileType = {

    data: HospitalBillDetail["data"],
    onRefresh: (type: string) => Promise<any>;
}

const BillFileInfo: React.FC<BillDetailFileType> = (props) => {

    const {data} = props;
    return (
        <>
            {!data.fileClassify || data.fileClassify.hasFile ? (
                <Card size="small" className={styles.mycard}
                      title={`文件信息`}>
                    <Space size={[8, 16]} wrap align="start">
                        {data.fileClassify.billFile.length > 0 && (
                            <Card style={{width: '100%'}}>
                                {/*已刷新*/}
                                <ViewFileListClass
                                    title="账单文件"
                                    fileList={data.fileClassify.billFile}
                                    onRefresh={props.onRefresh}
                                />
                            </Card>
                        )}
                        {data.fileClassify.stampBill.length > 0 && (
                            <Card style={{width: '100%'}}>
                                {/*已刷新*/}
                                <ViewFileListClass
                                    title="盖章账单"
                                    fileList={data.fileClassify.stampBill}
                                    onRefresh={props.onRefresh}
                                />
                            </Card>
                        )}
                        {data.fileClassify.proofReimbursement.length > 0 && (
                            <Card style={{width: '100%'}}>
                                {/*已刷新*/}
                                <ViewFileListClass
                                    title="报销凭证"
                                    fileList={data.fileClassify.proofReimbursement}
                                    onRefresh={props.onRefresh}
                                />
                            </Card>
                        )}
                        {data.fileClassify.receipt.length > 0 && (
                            <Card style={{width: '100%'}}>
                                {/*已刷新*/}
                                <ViewFileListClass
                                    title="收据"
                                    fileList={data.fileClassify.receipt}
                                    onRefresh={props.onRefresh}
                                />
                            </Card>
                        )}
                    </Space>
                </Card>
            ) : ('')}
        </>
    );
};

export default BillFileInfo;
