import React, {Component} from 'react';
import {Breadcrumb, Button, Input, Select, Space, Table} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import {ColumnsType} from 'antd/es/table';
import {Volunteer} from './types';
import 'dayjs/locale/zh-cn';
import App from '../../App';

const Option = Select.Option;

type VolunteerState = {
    page: number;
    pageSize: number;
    totalElements: number;
    totalPages: number;
    content: Volunteer[];
    username: string;
    date: [];
    volunteer: Volunteer | null;
    loading: boolean;
    volunteerType: number | '';
};

class VolunteerVerifyList extends Component<{}, VolunteerState> {
    state: any = {
        page: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        content: [],
        username: '',
        date: [],
        volunteer: null,
        loading: true,
        loginAddress: '',
        volunteerType: '',
    };
    private modal: any = null;

    componentDidMount() {
        this.loadData();
    }

    // 移除组织
    removeFn(row: any) {
        Fetch.del(`${API.volunteer.del}/${row.id}`).then(() => {
            this.loadData();
        });
    }

    // volunteerType;
    changeCondition = (condition: any, load = false) => {
        this.setState(
            {
                ...condition,
            },
            () => {
                if (load) {
                    this.loadData();
                }
            }
        );
    };
    // 获取表格数据;
    loadData = () => {
        const {page, pageSize, username, volunteerType} = this.state;
        Fetch.get(
            `${API.volunteer.audit_List}?page=${page}&size=${pageSize}&userName=${username}&volunteerType=${volunteerType}`
        ).then((data) => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                loading: false,
            });
        });
    };

    create = () => {
        this.setState(
            {
                volunteer: null,
            },
            this.modal.show
        );
    };
    goDetail = (row: any) => {
        window.location.href = `/#/volunteerDetail/1/${row.volunteerId}/${row.userId}`;
    };
    // 拒绝
    refuse = (id: number) => {
        Fetch.put(`${API.volunteer.refuse}/${id}`).then(() => {
            this.loadData();
        });
    };
    // 同意
    agree = (id: number) => {
        Fetch.put(`${API.volunteer.pass}/${id}`).then(() => {
            this.loadData();
        });
    };
    // 重制
    reset = () => {
        this.setState(
            {
                username: '',
                volunteerType: '',
                page: 0,
            },
            this.loadData
        );
    };

    typeChange = (e: any) => {
        this.setState({
            volunteerType: e,
        });
    };
    changeInpt = (val: string) => {
        this.setState({
            username: val,
        });
    };

    render() {
        const {page, pageSize, totalElements, content, volunteerType} =
            this.state;
        const columns: ColumnsType<Volunteer> = [
            {
                title: '用户名',
                dataIndex: 'name',
                align: `center`,
            },
            {
                title: '手机号',
                dataIndex: 'phone',
                align: `center`,
                render: (text: any, record: any) => {
                    return record.phone ? record.phone : record.wxPhone;
                },
            },
            {
                title: '性别',
                dataIndex: 'gender',
                align: `center`,
                render: (text: any, record: any) => {
                    return text == 1 ? '男' : '女';
                },
            },
            {
                title: '年龄',
                dataIndex: 'age',
                align: `center`,
            },
            {
                title: '志愿者类型',
                dataIndex: 'volunteerType',
                align: `center`,
                render: (text: any, record: any) => {
                    return text == 1 ? '乐龄志愿者' : '敬老志愿者';
                },
            },
            {
                title: '所在地',
                dataIndex: 'loginAddress',
                align: `center`,
            },
            {
                title: '注册时间',
                dataIndex: 'createdDate',
                align: `center`,
            },
            {
                title: '状态',
                dataIndex: 'status',
                align: `center`,
                render: (text: any) => {
                    if (text == 1) {
                        return '审核中';
                    } else if (text == 2) {
                        return '审核通过';
                    } else if (text == 3) {
                        return '已拒绝';
                    } else if (text == 4) {
                        return '用户退出';
                    } else if (text == 5) {
                        return '管理员移除';
                    } else {
                        return '-';
                    }
                },
            },
            {
                title: '操作',
                align: `center`,
                render: (text: any, row: any) => {
                    return (
                        <>
                            <Button
                                type="link"
                                onClick={() => {
                                    this.goDetail(row);
                                }}
                            >
                                详情
                            </Button>
                            {row.status == 1 ? (
                                <>
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            this.agree(row.id);
                                        }}
                                    >
                                        同意
                                    </Button>
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            this.refuse(row.id);
                                        }}
                                    >
                                        拒绝
                                    </Button>
                                </>
                            ) : (
                                ''
                            )}
                        </>
                    );
                },
            },
        ];

        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>志愿者管理</Breadcrumb.Item>
                    <Breadcrumb.Item>审核列表</Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Space>
                        志愿者类型：
                        <Select
                            placeholder="请选择分类"
                            defaultValue={volunteerType}
                            onChange={this.typeChange}
                        >
                            <Option value="">全部志愿者</Option>
                            <Option value={1}>乐龄志愿者</Option>
                            <Option value={2}>敬老志愿者</Option>
                        </Select>
                        用户名:
                        <Input
                            placeholder="请输入用户名"
                            value={this.state.username}
                            onChange={(e) => this.changeInpt(e.target.value)}
                        />
                        <Button onClick={this.loadData} type="primary">
                            搜索
                        </Button>
                        <Button onClick={this.reset}>重置</Button>
                    </Space>
                    <Table
                        loading={this.state.loading}
                        className="mgTop15"
                        bordered
                        rowKey={(record: any) => {
                            return record.id;
                        }}
                        columns={columns}
                        dataSource={content}
                        pagination={{
                            current: page + 1,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) =>
                            this.changeCondition(
                                {
                                    page: pagination.current - 1,
                                    pageSize: pagination.pageSize,
                                },
                                true
                            )
                        }
                    />
                </div>
            </>
        );
    }
}

export default function VolunteerVerify() {
    return (
        <App selectedKeys={['volunteerverify']} openKeys="vol">
            <VolunteerVerifyList/>
        </App>
    );
}
