import React from 'react';
import {Button, Card, Space, Spin,} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {DhDefaultRefreshFile, DhDetailDataType} from "../common/DhDetailType";
import {decryptData} from "../../common/crypto";
import PatientInfo from "./PatientInfo";
import BookingInfo from "./BookingInfo";
import DhAccountInfo from "./DhAccountInfo";
import DhWarVeteranInfo from "./DhWarVeteranInfo";
import DiagnosisReportInfo from "./DiagnosisReportInfo";
import ServiceUserFeeInfo from "./ServiceUserFeeInfo";
import {dhRefreshFile, dhShowFile} from "../common/edit/DhBackfillDataConfig";
import DhVoucher from "./DhVoucher";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import dayjs from "dayjs";
import DhServiceUserParentClass from "./DhServiceUserParent";

type ServiceUserDetail = {
    snap: string,
    bId: number
}

class ServiceUserDetailClass extends DhServiceUserParentClass<DhDetailDataType & ServiceUserDetail> {

    state: DhDetailDataType & ServiceUserDetail = {
        id: decryptData(common.getQuery('id')),
        snap: decryptData(common.getQuery('snap')),
        bId: decryptData(common.getQuery('bId')),
        data: {},
        startTime: dayjs().valueOf(),
        refreshData: DhDefaultRefreshFile(),
        refreshCount: 0,
        spinning: true,
        booking: false,
    };

    componentDidMount() {
        this.getData()
    };

    getData() {
        const {id, snap, bId} = this.state;
        let url = `${API.dentistry.service_user.do_get_patient_detail}?id=${id}`;
        if (snap === 'true') {
            url = `${API.dentistry.nbsp_service_user.do_get_detail}?serviceId=${id}&batchId=${bId}`;
        }
        Fetch.get(url).then((data) => {
            dhShowFile(data).then(() => {
                const promise = dhRefreshFile(data.files);
                this.setState({
                    data: data,
                    importLoading: false,
                    refreshData: promise,
                    spinning: false
                });
            })
        });
    }

    cancel = () => {
        window.history.go(-1);
    }
    back = () => {
        // @ts-ignore
        window.history.back(-1)
    }

    render() {
        const {data} = this.state;
        return (
            <div>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Spin tip={'加载中'} spinning={this.state.spinning}>
                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                            {data ? (
                                <>
                                    <PatientInfo userDetail={data.userDetail} addressDetail={data.addressDetail}
                                                 warVeteran={data.warVeteran}/>
                                    <DhWarVeteranInfo warVeteran={data.warVeteran}/>
                                    <DhAccountInfo account={data.account}/>
                                    <DiagnosisReportInfo report={data.report} onRefresh={this.refreshFile}/>
                                    <ServiceUserFeeInfo serviceUserFee={data.serviceUserFee}
                                                        onRefresh={this.refreshFile}/>
                                    <BookingInfo bookingVos={data.bookingVos}/>
                                    <DhVoucher userDetail={data.userDetail} onRefresh={this.refreshFile}/>
                                </>
                            ) : ('')}
                            <Card>
                                <Space>
                                    <Button
                                        // type="primary"
                                        size="large"
                                        onClick={this.cancel}
                                    >
                                        返回
                                    </Button>
                                </Space>
                            </Card>
                        </Space>
                    </Spin>
                </div>
            </div>
        );
    }
}

export default function ServiceUserDetail() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <ServiceUserDetailClass/>
        </App>
    );
}
