import {Card, Descriptions, Space} from 'antd';
import React from 'react';
import {DhDetailDataType} from "../common/DhDetailType";
import ViewFileListClass from "../../common/modal/ViewFileList";

type DhWarVeteranType = {

    report: DhDetailDataType["data"]["report"]
    onRefresh: (type: string) => Promise<any>;
}

const DiagnosisReportInfo: React.FC<DhWarVeteranType> = (props) => {

    return (
        <>
            {props.report && props.report.surgery && (
                <Card size="small"
                      title={`义诊报告`}
                >
                    <Descriptions>
                        {props.report.surgeryStr === "接受手术" && (
                            <Descriptions.Item label="是否接受手术">
                                {props.report.surgeryStr}
                            </Descriptions.Item>
                        )}
                        {props.report.surgeryStr === "不接受手术" && (
                            <Descriptions.Item label="不接受手术原因">
                                {props.report.noSurgeryReasonStr}
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                    {/*{props.report.surgeryStr === "接受手术" && (*/}
                    <Space size={[8, 16]} wrap align="start">
                        {props.report.report?.length > 0 && (
                            <Card style={{width: '100%'}}>
                                <ViewFileListClass
                                    title="义诊报告"
                                    fileList={props.report.report}
                                    onRefresh={props.onRefresh}
                                />
                            </Card>
                        )}
                    </Space>
                    {/*)}*/}
                </Card>
            )}
        </>
    );
};

export default DiagnosisReportInfo;
