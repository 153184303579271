import React, {Component} from 'react';
import 'dayjs/locale/zh-cn';
import {Breadcrumb, Button, Input, message, Select, Space, Table, Typography,} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import {ColumnsType} from 'antd/es/table';
import createModal from '../common/createModal';
import MessageForm from './MessageForm';
import {Message} from './types';
import App from '../../App';

const {Paragraph} = Typography;

type MessageState = {
    page: number;
    pageSize: number;
    totalElements: number;
    totalPages: number;
    content: Message[];
    name: string;
    type: string;
    date: [];
    message: Message | null;
    loading: boolean;
};

const FormModal = createModal(MessageForm);

class MessageList extends Component<{}, MessageState> {
    state: any = {
        page: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        content: [],
        name: '',
        type: '',
        date: [],
        message: null,
        loading: true,
    };
    private modal: any = null;

    componentDidMount() {
        this.loadData();
    }

    changeCondition = (condition: any, load = false) => {
        this.setState(
            {
                ...condition,
            },
            () => {
                if (load) {
                    this.loadData();
                }
            }
        );
    };

    loadData = () => {
        const {page, pageSize, type, name} = this.state;
        Fetch.get(
            `${API.message.list}?page=${page}&size=${pageSize}&status=${type}&title=${name}`
        ).then((data) => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                loading: false,
            });
        });
    };

    create = () => {
        this.setState(
            {
                message: null,
            },
            this.modal.show
        );
    };

    edit = (record: Message) => {
        this.setState(
            {
                message: record,
            },
            this.modal.show
        );
    };

    del = (id: number) => {
        Fetch.del(`${API.message.zsgc}/${id}`).then(() => {
            message.success('删除成功！');
            this.loadData();
        });
    };

    changeStatus = (id: number, status: string) => {
        Fetch.put(`${API.message.status}`, {id, status}).then(() => {
            message.success('操作成功！');
            this.loadData();
        });
    };

    reset = () => {
        this.setState(
            {
                name: '',
                type: '',
                date: [],
                page: 0,
            },
            this.loadData
        );
    };

    handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            this.loadData();
        }
    };

    render() {
        const {page, pageSize, totalElements, content} = this.state;
        const columns: ColumnsType<Message> = [
            {
                title: '标题',
                dataIndex: 'title',
                align: `center`,
            },
            {
                title: '内容',
                dataIndex: 'detail',
                align: `center`,
                render: (text: any) => {
                    return (
                        <Paragraph ellipsis={{rows: 3, expandable: true, symbol: '展开'}}>
                            {text}
                        </Paragraph>
                    );
                },
            },
            {
                title: '状态',
                dataIndex: 'status',
                render: (text: any, record: any) => {
                    if (text === 'SENT') {
                        return '已发送';
                    } else if (text === 'CLOSED') {
                        return '已关闭';
                    } else {
                        return '草稿件';
                    }
                },
                align: `center`,
                width: 80,
            },
            {
                title: '接收对象',
                dataIndex: 'role',
                render: (text: any) => {
                    return text ? text : '全部';
                },
                align: `center`,
                width: 90,
            },
            {
                title: '创建时间',
                dataIndex: 'createdDate',
                align: `center`,
                width: 190,
            },
            {
                title: '操作',
                align: `center`,
                fixed: 'right',
                width: 250,
                render: (text: any, record: any) => {
                    let btn;
                    if (record.status === 'SENT') {
                        btn = (
                            <Button onClick={() => this.changeStatus(record.id, 'CLOSED')}>
                                关闭
                            </Button>
                        );
                    } else if (record.status === 'CLOSED') {
                        btn = (
                            <Button onClick={() => this.changeStatus(record.id, 'SENT')}>
                                启用
                            </Button>
                        );
                    } else {
                        btn = (
                            <Button onClick={() => this.changeStatus(record.id, 'SENT')}>
                                发送
                            </Button>
                        );
                    }
                    return (
                        <Space>
                            <Button onClick={() => this.edit(record)}>编辑</Button>
                            <Button onClick={() => this.del(record.id)}>删除</Button>
                            {btn}
                        </Space>
                    );
                },
            },
        ];

        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>公告管理</Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Space size="small" onKeyDown={this.handleKeyDown}>
                        标题：
                        <Input
                            placeholder="请输入标题"
                            value={this.state.name}
                            onChange={(e) =>
                                this.changeCondition({page: 0, name: e.target.value})
                            }
                        />
                        状态：
                        <Select
                            placeholder="请选择状态"
                            value={this.state.type}
                            onChange={(e) => this.changeCondition({page: 0, type: e})}
                        >
                            <Select.Option value="">全部</Select.Option>
                            <Select.Option value="SENT">已发送</Select.Option>
                            <Select.Option value="DRAFT">草稿件</Select.Option>
                            <Select.Option value="CLOSED">已关闭</Select.Option>
                        </Select>
                        <Button onClick={this.loadData}>搜索</Button>
                        <Button onClick={this.reset}>重置</Button>
                    </Space>
                    <div className="mgTop15">
                        <Button onClick={this.create} type="primary">
                            新增
                        </Button>
                    </div>
                    <Table
                        loading={this.state.loading}
                        className="mgTop15"
                        bordered
                        rowKey={(record: any) => {
                            return record.id;
                        }}
                        columns={columns}
                        dataSource={content}
                        scroll={{x: 1300}}
                        pagination={{
                            current: page + 1,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) =>
                            this.changeCondition(
                                {
                                    page: pagination.current - 1,
                                    pageSize: pagination.pageSize,
                                },
                                true
                            )
                        }
                    />
                </div>
                <FormModal
                    title={this.state.message === null ? '创建公告' : '编辑公告'}
                    reload={this.loadData}
                    message={this.state.message}
                    ref={(c) => (this.modal = c)}
                />
            </>
        );
    }
}

export default function MessageIndex() {
    return (
        <App selectedKeys={['message']}>
            <MessageList/>
        </App>
    );
}
