import React, {Component} from 'react';
import {Breadcrumb, Button, Col, message, Modal, Row, Spin, Table,} from 'antd';
import {Link} from 'react-router-dom';
import App from '../../App';
import styles from '../goods/supplier.module.css';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import common from '../common/common';
import {ColumnsType} from 'antd/es/table';
import FileUploader from '../common/uploader/FileUploader';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import MediaPath from "../common/uploader/MediaPath";
import MediaCodes from "../common/uploader/MediaCodes";
import {FileTypeTypes} from "../common/modal/ViewFileListTypes";
import dayjs from "dayjs";

const {confirm} = Modal;

class InvoiceDetail1 extends Component {
    state = {
        id: common.getQuery('id'),
        billing: {},
        header: {},
        contents: [],
        tickets: [],
        billingUser: {},
        loading: false,

        btnSendQ: 0,
        refreshCount: 0,
        startTime: dayjs().valueOf(),
        spinning: true
    };
    refd = React.createRef<FileUploader>();

    componentDidMount() {
        this.getData();
        let btn = sessionStorage.getItem('buttons');
        if (btn) {
            let bts = JSON.parse(btn)['buttons'];
            //       ROLE_BILLING_TICKET_SEND_EMAIL
            // 发票管理-上传发票文件 ROLE_BILLING_TICKET_SAVE_TICKET
            // 发票管理-删除发票文件 ROLE_BILLING_TICKET_DELETE_TICKET
            this.setState({
                btnSendQ: bts['ROLE_BILLING_TICKET_SEND_EMAIL'],
            });
        }
    }

    onChange = (e: any, info: any) => {
        if (info.file.status === 'removed') {
            // 删除此文件
            this.delFile(info.file.ticketId);
        } else {
            if (info.file.status === 'done') {
                this.uploadFn(info.file.id, info.file.url);
            }
        }
    };

    delFile(ids: number) {
        const {id} = this.state;
        // del;
        Fetch.post(API.invoice.del, {ticketId: ids, billingId: id}).then(
            (data) => {
                message.success('删除成功');
            }
        );
    }

    sendEamil = () => {
        let that = this;
        confirm({
            title: '确认发送电子发票吗?',
            icon: <ExclamationCircleOutlined/>,
            cancelText: '取消',
            okText: '确认',
            content: '点击“确认”后，电子发票将会发送到对方邮箱。',
            onOk() {
                that.sendFn();
            },
            onCancel() {
            },
        });
    };
    sendFn = () => {
        // send_email;
        const {id, billing} = this.state;
        let that = this;
        Fetch.post(API.invoice.send_email, {billingId: id}).then((data) => {
            message.success('发送成功');
            that.setState({
                billing: {...billing, status: 2},
            });
        });
    };
    uploadFn = (fileid: number, url: string) => {

        const {id, billing} = this.state;
        let ref = this.refd.current;
        let list = ref ? ref.state.fileList : [];
        // upload;
        const splitUrlDomain = MediaPath.splitUrlDomain(url);
        Fetch.post(API.invoice.upload, {billingId: id, url: splitUrlDomain}).then((data) => {
            list.forEach((item: any) => {
                item.status = 'done'
            })
            this.setState({
                billing: {...billing, status: 5},
                tickets: list,
            });
        });
    };

    getData() {
        const {id} = this.state;
        Fetch.get(`${API.invoice.detail}?id=${id}`).then((data) => {
            const {tickets} = data;
            this.buildTickets(tickets);
            this.setState({
                ...data,
                tickets: tickets,
                spinning: false
            });
        });
    }

    refreshFileOne = (type: string, mediaId: number) => {

        return new Promise((resolve, reject) => {
            this.refreshData().then((data: any[]) => {
                data.forEach((item: FileTypeTypes) => {
                    if (item.mediaId === mediaId) {
                        resolve(item.filePath);
                    }
                })
            })
        })
    }

    private refreshData = () => {

        return new Promise<any[]>((resolve, reject) => {
            const {id, tickets, refreshCount, startTime} = this.state;
            //开启时间
            const turnOnTime = (dayjs().valueOf() - startTime) / 1000;
            //刷新覆盖时间
            const refresh = refreshCount * API.fileMaxTime;
            if (turnOnTime - refresh > API.fileMaxTime) {
                let url = `${API.invoice.refresh_file}?id=${id}`;
                this.setState({spinning: true}, () => {
                    Fetch.get(url).then((data) => {
                        console.log("刷新！！！！")
                        const count = Math.floor(turnOnTime / API.fileMaxTime); // 输出 31
                        const tickets = this.buildTickets(data);
                        this.setState({
                            tickets: tickets,
                            refreshCount: count,
                            spinning: false
                        }, () => {
                            resolve(data);
                        })
                    });
                })
            } else {
                resolve(tickets);
            }
        })
    }

    buildTickets = (tickets: any[]) => {

        tickets.map((item: any) => {
            const fullUrl = MediaPath.getFileUrl(item.url);
            item.turl = item.url;
            item.url = fullUrl;
            item.filePath = fullUrl;
            item.mediaId = item.ticketId;
        });
        return tickets;
    }

    render() {
        const {billing, header, contents, tickets, billingUser} = this.state;
        type User = {};
        const columns: ColumnsType<User> = [
            {
                title: '活动名称',
                dataIndex: 'activityName',
                align: `center`,
            },
            {
                title: '捐赠金额',
                dataIndex: 'amount',
                align: `center`,
            },
            {
                title: '捐赠时间',
                dataIndex: 'createdDate',
                align: `center`,
            },
            {
                title: '活动发起方',
                dataIndex: 'institutionName',
                align: `center`,
            },
        ];

        return (
            <div>
                <Spin tip={"加载中"} spinning={this.state.spinning}>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>
                        <Link to="invoicelist">发票管理</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>发票详情</Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Row>
                        <Col span={12}>
                            <div className={styles.detailTitle}>发票信息</div>
                            <div className={styles.detailDetail}>
                                发票金额：{billing['totalMoney']}
                            </div>
                            <div className={styles.detailDetail}>
                                发票类型：
                                {header['invoiceType'] == 1 ? '普通电子发票' : '专用电子发票'}
                            </div>
                            <div className={styles.detailDetail}>
                                发票抬头：{header['title']}
                            </div>
                            <div className={styles.detailDetail}>
                                抬头类型：{header['type'] == 1 ? '个人' : '单位'}
                            </div>
                            {header['type'] == 0 ? (
                                <div className={styles.detailDetail}>
                                    单位税号：{header['taxNumber']}
                                </div>
                            ) : (
                                ''
                            )}
                            <div className={styles.detailDetail}>
                                邮箱地址：{header['email']}
                            </div>
                            {header['type'] === 0 ? (
                                <>
                                    <div className={styles.detailDetail}>
                                        注册地址：
                                        {header['companyAddress'] ? header['companyAddress'] : '--'}
                                    </div>
                                    <div className={styles.detailDetail}>
                                        注册电话：{header['telephone'] ? header['telephone'] : '--'}
                                    </div>
                                    <div className={styles.detailDetail}>
                                        开户银行：{header['bankName'] ? header['bankName'] : '--'}
                                    </div>
                                    <div className={styles.detailDetail}>
                                        银行账号：
                                        {header['bankAccount'] ? header['bankAccount'] : '--'}
                                    </div>
                                </>
                            ) : (
                                ''
                            )}
                            <div className={styles.detailDetail}>
                                发票内容：{billing['content']}
                            </div>
                            <div className={styles.detailDetail}>
                                申请时间：{billing['createdDate']}
                            </div>
                            <div className={styles.detailDetail}>
                                系统编号：{billing['code']}
                            </div>
                            <div className={styles.detailTitle}>申请人信息</div>
                            <div className={styles.detailDetail}>
                                微信昵称：{billingUser['wxNickname']}
                            </div>
                            <div className={styles.detailDetail}>
                                手机号码：{billingUser['phone']}
                            </div>
                            <div className={styles.detailTitle}>关联订单</div>
                            <Table
                                loading={this.state.loading}
                                className="mgTop15"
                                bordered
                                rowKey="createdDate"
                                columns={columns}
                                dataSource={contents}
                                pagination={false}
                            ></Table>
                        </Col>
                        <Col span={8}>
                            <div className={styles.detailTitle}>发票文件</div>
                            <div style={{marginTop: '15px'}}>
                                {/*TODO 待修改*/}
                                <FileUploader
                                    multiple
                                    listType="text"
                                    fileList={tickets}
                                    text="上传发票"
                                    accept=".pdf"
                                    onChange={this.onChange}
                                    ref={this.refd}
                                    disabled={
                                        !(billing['status'] === 5 ||
                                            billing['status'] === 1 ||
                                            billing['status'] === 4)
                                    }
                                    typeCode={MediaCodes.invoice.code}
                                    onRefresh={this.refreshFileOne}
                                />
                            </div>
                        </Col>
                        {this.state.btnSendQ == 1 ? (
                            <Col span={4}>
                                {/* //已上传 public static final Integer UPLOADED = 5; //已开票 public
              static final Integer INVOICED = 2; */}
                                {billing['status'] === 5 ? (
                                    <Button
                                        style={{marginTop: '44px'}}
                                        onClick={this.sendEamil}
                                    >
                                        发送电子发票
                                    </Button>
                                ) : billing['status'] === 2 ? (
                                    <Button style={{marginTop: '44px'}} disabled>
                                        已发送电子发票
                                    </Button>
                                ) : (
                                    <Button style={{marginTop: '44px'}} disabled>
                                        发送电子发票
                                    </Button>
                                )}
                            </Col>
                        ) : (
                            ''
                        )}
                    </Row>
                </div>
                </Spin>
            </div>
        );
    }
}

export default function InvoiceDetail() {
    return (
        <App selectedKeys={['invoicelist']}>
            <InvoiceDetail1></InvoiceDetail1>
        </App>
    );
}
