import React, {useEffect, useState} from 'react';
import 'dayjs/locale/zh-cn';
import {Breadcrumb, Button, Col, Form, FormInstance, Input, message, Radio, Row, Space, Spin,} from 'antd';
import common from '../../common/common';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import {useHistory, useParams} from 'react-router-dom';
import App from '../../../App';
import Active from './active';
import Donation from './donation';
import dayjs from "dayjs";
import MediaPath from "../../common/uploader/MediaPath";

const FormItem = Form.Item;
const formRef = React.createRef<FormInstance<any>>();
const activeDom = React.createRef<any>();
const donationDom = React.createRef<any>();

//-----------组件-------
function Template() {
    const [options, setoptions] = useState<any>([]);
    const [itemType, setType] = useState<any>(1);
    const [spinningFlag, setSpinning] = useState<boolean>(false);
    // 禁用表单
    const [disableFlag, setDisable] = useState<boolean>(false);
    // 文章id
    const {id} = useParams<{ id: string }>();
    // 模版id
    const {tmpid} = useParams<{ tmpid: string }>();
    const [account, setAccount] = useState<any>({
        name: '',
        type: 1,
        remember: false,
        id: null,
        needSign: true,
        signRule: 1,
        needDonation: false,
    });

    const {detail} = useParams<{ detail: string }>();
    const history = useHistory();
    let changed = false;

    // const [componentDisabled, setComponentDisabled] = useState<boolean>(true);
    // 生命周期
    useEffect(
        function () {
            // onSearch('', setoptions);
            // if (id === '0') {
            //   return;
            // }
            if (detail === '1') {
                setDisable(true);
            }
            setSpinning(true);
            // 获取模版信息
            Fetch.get(`${API.atl.template.get_detail}?id=${tmpid}`).then((data) => {
                let content = JSON.parse(data.content);

                setType(content.type);
                setSpinning(false);
                if (id !== '0' && content.type === 1) {
                    Fetch.get(`${API.gongyiActivity.zsgc}?id=${id}`).then((data) => {
                        setcontent(content, data);
                    });
                } else if (id !== '0' && content.type === 2) {
                    Fetch.get(`${API.donationActivity.zsgc}?id=${id}`).then((data) => {
                        setcontent(content, data);
                    });
                } else {
                    if (content.type === 1) {
                        content = {...content, signRule: 1, signRadius: 1000};
                    }
                    setcontent(content, {});
                    setAccount(content);
                }
            });
        },
        [id, detail]
    );
    const setcontent = (content: any, data: any) => {
        content = {...content, ...data};
        const covers = content.covers.map((item: any) => {
            return {
                id: item.id,
                uid: item.id,
                status: 'done',
                name: item.fileName,
                url: MediaPath.getFileUrl(item.url),
                filePath: MediaPath.getFileUrl(item.url),
                mediaId: item.id,
            };
        });
        content = {
            ...content,
            id: id,
            date1: [
                dayjs(content.registerStartDate),
                dayjs(content.registerEndDate),
            ],
            date2: [dayjs(content.startDate), dayjs(content.endDate)],
            covers,
        };
        if (content.signStartDate != null) {
            content.signDate = [
                dayjs(content.signStartDate),
                dayjs(content.signEndDate),
            ];
        } else {
            content.signDate = [];
        }

        setAccount(content);
        if (content.type === 1) {
            activeDom.current.changeNeedDonation(content.needDonation);
            // activeDom.current.changeNeedSign(content.needSign);
            activeDom.current.changeSignRule(content.signRule);
            if (content.categoryId) {
                activeDom.current.getSelect(content.categoryId);
            }
        } else {
            if (content.categoryId) {
                donationDom.current.getSelect(content.categoryId);
            }
        }

        form.setFieldsValue(content);
    };

    // 获取活动详情
    //-----------取消-------
    const cancle = () => {
        history.goBack();
    };
    // ------------提交-----------
    const submit = (values: any) => {
        if (values.type === 1) {
            const needDiff = form.getFieldValue("minDuration");
            const start = dayjs(values.date2[0]);
            const end = dayjs(values.date2[1]);
            const diff = end.diff(start, 'hour');
            if (diff < needDiff) {
                message.error('活动时间小于最小活动时长请调整');
                return;
            }

            if (values.date2[0].isBefore(values.date1[1]) || changed) {
                message.error('活动开始时间不能早于活动报名结束时间');
                return;
            }
            const address = options.find(
                (item: any) => item.value === values.address
            );
            if (address) {
                values.lat = address.location.lat;
                values.lng = address.location.lng;
            } else if (!values.id || changed) {
                message.error('您输入的地址不正确，请通过下拉列表选择输入');
                return;
            } else {
                values.lat = account.lat;
                values.lng = account.lng;
            }
            values.registerStartDate = values.date1[0].format('YYYY-MM-DD HH:mm');
            values.registerEndDate = values.date1[1].format('YYYY-MM-DD HH:mm');

            if (values.signInTime) {
                const startDate = dayjs(values.date2[0]).subtract(values.signInTime, 'minute');
                values.signStartDate = startDate.format('YYYY-MM-DD HH:mm');
                values.signEndDate = values.date2[0].format('YYYY-MM-DD HH:mm');
                if (startDate.isBefore(values.date1[1]) || changed) {
                    message.error('签到时间与报名时间重叠');
                    return;
                }
            } else {
                message.error('请选择活动开始前多久可以签到');
                return;
            }
            // if (values.signDate) {
            //     if (values.date2[0].isBefore(values.signDate[0]) || changed) {
            //         message.error('活动签到开始时间不能晚于活动开始时间');
            //         return;
            //     }
            //     if (values.date2[1].isBefore(values.signDate[1]) || changed) {
            //         message.error('活动签到结束时间不能晚于活动结束时间');
            //         return;
            //     }
            //     values.signStartDate = values.signDate[0].format('YYYY-MM-DD HH:mm');
            //     values.signEndDate = values.signDate[1].format('YYYY-MM-DD HH:mm');
            // }
        }
        values.startDate = values.date2[0].format('YYYY-MM-DD HH:mm');
        values.endDate = values.date2[1].format('YYYY-MM-DD HH:mm');
        values.covers = values.covers.map((item: any) => {
            // let url = item.url;
            // if (item.url.indexOf('http') !== -1) {
            //     let Arr = item.url.split(API.domain);
            //     url = Arr[Arr.length - 1];
            // }
            // return {id: item.id, fileName: item.fileName, url: url};
            return {id: item.id, fileName: item.fileName};
        });
        values.coverIds = values.covers.map((item: any) => item.id).join(',');
        values.coverId = values.covers[0].id;
        values.content = values.content.toHTML
            ? values.content.toHTML()
            : values.content;
        if (values.content === '<p></p>') {
            message.error('内容不能为空！');
            return;
        }

        delete values.date1;
        delete values.date2;
        values.templateId = tmpid;
        values.needSign = true;
        if (values.type === 1) {
            if (id === '0') {
                create(values);
            } else {
                update(values);
            }
        } else {
            if (id === '0') {
                donationCreate(values);
            } else {
                donationUpdate(values);
            }
        }
    };
    // 活动创建
    const create = (values: any) => {
        delete values.id;
        Fetch.postJSON(`${API.gongyiActivity.zsgc}`, values).then(() => {
            window.location.href = '/#/gongyiActivity';
            message.success('保存成功');
        });
    };
    // 活动编辑
    const update = (values: any) => {
        // values.status = 'PUBLISHED';
        Fetch.putJSON(`${API.gongyiActivity.zsgc}/${values.id}`, values).then(
            () => {
                message.success('修改成功');
                window.location.href = '/#/gongyiActivity';
            }
        );
    };
    // 募捐创建
    const donationCreate = (values: any) => {
        // /api/adimn / donationActivity / 7;
        Fetch.postJSON(`${API.donationActivity.zsgc}`, values).then(() => {
            message.success('保存成功');
            window.location.href = '/#/donationActivity';
        });
    };
    // 募捐编辑
    const donationUpdate = (values: any) => {
        // /api/adimn / donationActivity / 7;
        values.status = 'PUBLISHED';
        Fetch.putJSON(`${API.donationActivity.zsgc}/${values.id}`, values).then(
            () => {
                message.success('修改成功');
                window.location.href = '/#/donationActivity';
            }
        );
    };
    // 切换模版类型
    const changeType = (e: any) => {
        setType(e.target.value);
    };
    // 搜索地址
    const onSearch = (searchText: String) => {
        changed = true;
        if (searchText && searchText.trim().length > 0) {
            Fetch.get(`${API.location}?keyword=${searchText.trim()}`).then((data) => {
                const result = JSON.parse(data);
                if (result.status === 0) {
                    const options = result.data.map((item: any) => {
                        return {
                            label: item.title + ' ' + item.address,
                            value: item.title + ' ' + item.address,
                            location: item.location,
                        };
                    });
                    setoptions(options);
                }
            });
        }
    };

    const [form] = Form.useForm();

    return (
        <>
            <Breadcrumb style={{margin: '16px 0'}}>
                {account.type === 1 ? (
                    <Breadcrumb.Item>
                        <a href="/#/gongyiActivity">公益活动</a>
                    </Breadcrumb.Item>
                ) : (
                    <Breadcrumb.Item>
                        <a href="/#/donationActivity">公益募捐</a>
                    </Breadcrumb.Item>
                )}

                {id === '0' ? (
                    <>
                        <Breadcrumb.Item>
                            <a href={'/#/selectTemplate?type=' + account.type}>选择模版</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {tmpid === '0' ? '新建模版' : disableFlag ? '详情' : '完善模版'}
                        </Breadcrumb.Item>
                    </>
                ) : (
                    <Breadcrumb.Item> 编辑</Breadcrumb.Item>
                )}
            </Breadcrumb>
            <div
                className="site-layout-background"
                style={{padding: 24, minHeight: 720}}
            >
                <Spin tip="Loading..." spinning={spinningFlag}>
                    <Form
                        initialValues={account}
                        onFinish={submit}
                        ref={formRef}
                        form={form}
                    >
                        <FormItem wrapperCol={{span: 6, offset: 18}} className="center">
                            <Button onClick={cancle} style={{marginRight: '15px'}}>
                                取 消
                            </Button>
                            {!disableFlag ? (
                                <Button type="primary" htmlType="submit">
                                    确 定
                                </Button>
                            ) : (
                                ''
                            )}
                        </FormItem>
                        <FormItem hidden noStyle name="id">
                            <Input/>
                        </FormItem>
                        <Row>
                            <Col span={8}>
                                <FormItem
                                    name="title"
                                    label="活动名称"
                                    rules={[{required: true, message: '必填'}]}
                                    labelCol={{span: 9}}
                                    wrapperCol={{span: 15}}
                                >
                                    <Input
                                        placeholder="在此输入活动名称"
                                        disabled={disableFlag || !account.remember}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <FormItem
                            label="活动类型"
                            labelCol={{span: 3}}
                            wrapperCol={{span: 21}}
                        >
                            {itemType === 1
                                ? '公益活动'
                                : '公益募捐 募捐编号:' + account.number}
                        </FormItem>
                        <FormItem
                            name="type"
                            label="活动类型"
                            labelCol={{span: 3}}
                            wrapperCol={{span: 21}}
                            hidden
                            noStyle
                        >
                            <Radio.Group onChange={changeType}>
                                <Space direction="vertical">
                                    {itemType === 1 ? (
                                        <Radio value={1}>公益活动</Radio>
                                    ) : (
                                        <>
                                            <Radio value={2}>
                                                <span>公益募捐1 </span>


                                            </Radio>
                                            <Space>
                                                <FormItem
                                                    style={{marginBottom: 0}}
                                                    name="number"
                                                    rules={[{required: true, message: '必填'}]}
                                                >
                                                    <Input placeholder="募捐编号必填" disabled={true}/>
                                                </FormItem>
                                            </Space>
                                        </>
                                    )}
                                </Space>
                            </Radio.Group>
                        </FormItem>

                        {/************************** 活动/募捐 **************************/}
                        {itemType === 1 ? (
                            <Active
                                onSearch={onSearch}
                                disabledFlag={disableFlag}
                                options={options}
                                account={account}
                                form={form}
                                ref={activeDom}
                            />
                        ) : (
                            <Donation
                                ref={donationDom}
                                onSearch={onSearch}
                                disabledFlag={disableFlag}
                                options={options}
                                account={account}
                                form={form}
                            />
                        )}

                        <FormItem className="center">
                            <Button
                                onClick={cancle}
                                style={{marginRight: '15px'}}
                                size="large"
                            >
                                取 消
                            </Button>
                            {!disableFlag ? (
                                <Button type="primary" htmlType="submit" size="large">
                                    确 定
                                </Button>
                            ) : (
                                ''
                            )}
                        </FormItem>
                    </Form>
                </Spin>
            </div>
        </>
    );
}

export default function TemplateDetailS() {
    return (
        <>
            {common.getQuery('type') === '1' ? (
                <App selectedKeys={['gongyi']} openKeys="sub1">
                    <Template/>
                </App>
            ) : (
                <App selectedKeys={['donation']} openKeys="sub1">
                    <Template/>
                </App>
            )}
        </>
    );
}
