import React from 'react';
import {Button, Card, Form, FormInstance, message, Space, Spin,} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {defaultDhDetailData, DhDefaultRefreshFile, DhDetailDataType} from "../common/DhDetailType";
import {decryptData} from "../../common/crypto";
import PatientInfo from "../patientDetail/PatientInfo";
import VisitTaskInfo from "../patientDetail/VisitTaskInfo";
import HandleReturnVisits from "./handle/HandleReturnVisits";
import DhTaskCallLog from "../patientDetail/DhTaskCallLog";
import DhWarVeteranInfo from "../patientDetail/DhWarVeteranInfo";
import DhAccountInfo from "../patientDetail/DhAccountInfo";
import DiagnosisReportInfo from "../patientDetail/DiagnosisReportInfo";
import {dhRefreshFile, dhShowFile} from "../common/edit/DhBackfillDataConfig";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import dayjs from "dayjs";
import DhServiceUserParentClass from "../patientDetail/DhServiceUserParent";

type ReturnVisitDispose = {
    cancelLoading: boolean,
    subLoading: boolean
}

class DhPhoneTaskDetailClass extends DhServiceUserParentClass<DhDetailDataType & ReturnVisitDispose> {

    formRef = React.createRef<FormInstance>();
    state: DhDetailDataType & ReturnVisitDispose = {
        id: decryptData(common.getQuery('taskId')),
        data: defaultDhDetailData.data,
        cancelLoading: false,
        subLoading: false,
        startTime: dayjs().valueOf(),
        refreshData: DhDefaultRefreshFile(),
        refreshCount: 0,
        spinning: true,
        snap: '',
        bId: 0,
        booking: false,
    };

    componentDidMount() {
        this.getData()
    };

    getData() {

        const {id} = this.state;
        let url = `${API.dentistry.phone_task.do_get_detail_by_id}?id=${id}`;
        Fetch.get(url).then((data) => {
            dhShowFile(data).then(() => {
                const promise = dhRefreshFile(data.files);
                data.tasksDetail = data.visitDetail ? data.visitDetail : data.obligateDetail;
                this.setState({
                    data: data,
                    refreshData: promise,
                    spinning: false
                });
            })
        });
    }

    cancel = () => {
        this.setState({
            cancelLoading: true,
        });
        window.history.go(-1);
    }
    back = () => {
        // @ts-ignore
        window.history.back(-1)
    }

    submit = (values: any) => {
        //TODO 提示是否
        const {id} = this.state;
        values.taskId = id;
        Fetch.postJSON(API.atl.return_visit.do_handle_return_visits, values).then(() => {
            message.success('处理成功');
            window.history.back()
        });
    }

    render() {
        const {data, cancelLoading, subLoading, id} = this.state;
        return (
            <div>
                <Spin tip={'加载中'} spinning={this.state.spinning}>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Form
                        onFinish={this.submit}
                        ref={this.formRef}
                    >
                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                            <VisitTaskInfo tasksDetail={data.tasksDetail} taskDetailId={id} showCallPhone={false}/>

                            <PatientInfo userDetail={data.userDetail} addressDetail={data.addressDetail}
                                         warVeteran={data.warVeteran}/>
                            <DhWarVeteranInfo warVeteran={data.warVeteran}/>
                            <DhAccountInfo account={data.account}/>
                            <DiagnosisReportInfo report={data.report} onRefresh={this.refreshFile}/>
                            <DhTaskCallLog taskId={id}/>
                            {data.tasksDetail?.taskType === 1 && (
                                <HandleReturnVisits form={this.formRef}/>
                            )}
                        </Space>

                        <Card>
                            <Space>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    loading={subLoading}
                                >
                                    保存
                                </Button>
                                <Button
                                    // type="primary"
                                    size="large"
                                    loading={cancelLoading}
                                    onClick={this.cancel}
                                >
                                    返回
                                </Button>
                            </Space>
                        </Card>
                    </Form>
                </div>
                </Spin>
            </div>
        );
    }
}

export default function DhPhoneTaskDetail() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <DhPhoneTaskDetailClass/>
        </App>
    );
}
